import React from 'react';
import styled from 'styled-components';
import Modal from '@material-ui/core/Modal';

function getModalStyle() {
    const top = 0;
    const left = 25;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(${top}%, ${left}%)`,
        display: 'inline-block'
    };
}


const Wrapper = styled.div`
font-family: "sourceHanSansCN-light",Helvetica,Arial,sans-serif;
text-align:left;
line-height:200%;
color: gray;
white-space: pre-line;
`

const Images = styled.div`
margin: 20px 0 0 0;

img{
    width: 100%;
    max-height: 220px;
    height: 220px;
}
`
const Img = styled.img`
width:100%
`

const SeparaterA = styled.div`
height: 50px;
`

const SeparaterB = styled.div`
height: 20px;
`
const Body = styled.div`
padding:1em;
`

const Subtitle = styled.div`
font-size: 20pt;
padding: 10px 0;
`
const Content = styled.div`
padding: 10px 0
font-size: 14px;
line-height: 200%;
`


class Art extends React.Component {
    state = {
        open1: false,
        open2: false,
        open3: false,
        open4: false,
    };

    handleOpen1 = () => {
        this.setState({ open1: true });
    };
    handleOpen2 = () => {
        this.setState({ open2: true });
    };
    handleOpen3 = () => {
        this.setState({ open3: true });
    };
    handleOpen4 = () => {
        this.setState({ open4: true });
    };
    handleClose1 = () => {
        this.setState({ open1: false });
    };
    handleClose2 = () => {
        this.setState({ open2: false });
    };
    handleClose3 = () => {
        this.setState({ open3: false });
    };
    handleClose4 = () => {
        this.setState({ open4: false });
    }
    render() {
        return (
            <Wrapper >
                <div className="row">
                    <div className="col-xs-1 col-lg-1 col-md-1"></div>
                    <div className="col-xs-10 col-lg-10 col-md-10">
                        <Images>
                            <img className="col-xs-3 col-lg-3 col-md-3" onClick={this.handleOpen1} id="artImage1" src="./images/about/architektur_b1a_ger.jpg" alt=""></img>
                            <img className="col-xs-3 col-lg-3 col-md-3" onClick={this.handleOpen2} id="artImage1" src="./images/about/architektur_b2a_ger.jpg" alt=""></img>
                            <img className="col-xs-3 col-lg-3 col-md-3" onClick={this.handleOpen3} id="artImage1" src="./images/about/architektur_b3a_ger.jpg" alt=""></img>
                            <img className="col-xs-3 col-lg-3 col-md-3" onClick={this.handleOpen4} id="artImage1" src="./images/about/architektur_b4a_ger.jpg" alt=""></img>
                        </Images>
                        <Modal open={this.state.open1} onClose={this.handleClose1}                >
                            <div style={getModalStyle()}>
                                <Img id="artImage1" src="./images/about/architektur_b1a_ger.jpg" alt=""/>
                            </div>
                        </Modal>
                        <Modal open={this.state.open2} onClose={this.handleClose2}                >
                            <div style={getModalStyle()}>
                                <Img id="artImage1" src="./images/about/architektur_b2_ger.jpg" alt=""/>
                            </div>
                        </Modal>
                        <Modal open={this.state.open3} onClose={this.handleClose3}                >
                            <div style={getModalStyle()}>
                                <Img id="artImage1" src="./images/about/architektur_b3_ger.jpg" alt=""/>
                            </div>
                        </Modal>
                        <Modal open={this.state.open4} onClose={this.handleClose4}                >
                            <div style={getModalStyle()}>
                                <Img id="artImage1" src="./images/about/architektur_b4_ger.jpg" alt=""/>
                            </div>
                        </Modal>
                        <SeparaterA></SeparaterA>
                        <Body>
                            <Subtitle>启发心思的艺术：LAMY 艺术廊与雕塑园</Subtitle>
                                <Content>艺术让人从新旧不同的角度看世界，激发情绪之馀，更能启发创作灵感。正因如此，LAMY与艺术世界结下了不解之缘。LAMY艺术廊内的一片广阔的空间和满室的阳光，尽诉公司忠爱艺术的中心理念。艺术廊位於技术发展中心和注模工场之间，因此也成为日常生产过程中的一个重要部份，它既可用作员工会议室，也是参观展览的场地，在海德堡的文化生活上享有一定地位。此外，绿意盎然的生产工场屋顶上建有一个雕塑园，园内的立体艺术展品踪迹处处， 员工可以在这里欣赏着名艺术家的雕塑作品，为设计工作注新层面的思索，启发无穷无尽的创意思维。</Content>                            
                                <SeparaterB></SeparaterB>
                                <Subtitle>非一般的格局：时代感设计的惊艳魅力</Subtitle>
                                <Content>清晰，往往胜於浮夸。故此，LAMY的店铺陈设都爱采用简洁的线条和开明的结构，集中展示数项出众的元素。到专卖店及百货公司逛的热爱设计人士，都会强烈感受到其产品的尊贵特色及地位。此外，销售店都用上饶富特色的产品展示与精心设计的陈列设计，目标顾客都必定会对新产品及陈设留下深刻的印象。一些零售夥伴更在商店橱窗内为LAMY新颖独特的书写工具和主题布置提供陈设的舞台，一次又一次地招揽路人注目。「简练与自信」既为品牌的设计精髓，无论在世界哪一个角落，那管是海德堡的专卖店或是令人悸动的东京银座，我们都对此原则贯彻始终。</Content>
                                <SeparaterB></SeparaterB>
                                <Subtitle>广告设计：品牌定位</Subtitle>
                                <Content>LAMY标志着品质、创意与时尚的设计，秉持LAMY品牌的一贯承诺：传递书写文化。LAMY书写工具拥有非凡的魅力，全赖其卓越技术与时尚品味。在LAMY的广告中，公司拥有人诉说出LAMY对他们各自的意义。他们并非泛泛之辈，而是LAMY的灵魂，反映着LAMY所坚持的理念。他们都是独立的时代男女，都有自己的一个传奇故事。他们都从LAMY处为自己找到了贴心的书写工具，找到了完全属於自己的。</Content>                            
                        </Body>
                    </div>
                    <div className="col-xs-1 col-lg-1 col-md-1"></div>
                </div>
            </Wrapper>
        )
    }

}

export default Art