import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { CompanyFooter } from '../Common';

import config from '../../config';

const Wrapper = styled.div`
background-color :#fff
text-align:left;
p{
    font-size:14px;
    line-height:200%;
color: gray;
white-space: pre-line;
}
`
const Body = styled.div`
padding: 20px 0 30px 0;
p{
    padding: 30px 10px 0 10px;
}
`

const Title = styled.div`
padding: 30px 10px 0 10px;
color: gray;
`

class PressDetail extends React.Component {

    state = {
        data: null
    }

    getPageData = async (id) => {
        const fetchUrl = `${config.serverEndPoint}/pressPage/${id}`
        try {
            const result = await axios.get(fetchUrl);

            return result.data
        }
        catch (err) {
            console.log(err);
        }

    }


    async componentDidMount() {
        const { match } = this.props;
        const data = await this.getPageData(match.params.id)
        this.setState({data:data})
    }

    render() {
        const data = this.state.data

        if(this.state.data !=null){
                return (
                <Wrapper>
                    <div className="row">
                        <div className="col-xs-1 col-lg-1 col-md-1"></div>
                        <div className="col-xs-10 col-lg-10 col-md-10">
                            <Body>
                                <Title>{data.title}</Title>
                                <p>{data.description2}</p>
                            </Body>
                                <CompanyFooter />
                        </div>
                        <div className="col-xs-1 col-lg-1 col-md-1"></div>
                    </div>
                </Wrapper>
                
            )
        }else{
            return(<div/>)
        }
    }
}

export default PressDetail