import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import lodashUniqBy from 'lodash/uniqBy';

import config from '../../config';

const SCROLL_DISTANCE = 500;

const Wrapper = styled.section`

`;

const YearContainer = styled.div`
	display: flex;
	flex-direction: column;
	overflow: hidden;
`;

const YearList = styled.ul`
	height: 30px;
	display: flex;
	margin: 12px 10px 5px;
	transition: transform 0.4s ease 0s;

	transform: ${props => `translate3d(${props.yearShiftPos}px, 0px, 0px)`};
`;

const YearItem = styled.li`
	cursor: pointer;
	display: flex;
	font-size: 13px;
	color: #999;
	text-decoration: none;
	border-right: 1px solid #d9d9d9;
	color: ${props => props.selected ? '#000' : '#999'};
`;

const YearItemSpearator = styled.div`
	width: 25px;
	height: 11px;
	background: url('/images/about/bg_nav_history_l.gif') left -20px no-repeat;

	background-position: ${props => props.selected ? 'left 1px' : ''};
`;

const YearArrowContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;

const YearNextArrow = styled.span`
	cursor: pointer;
	margin: 0px 10px;
	background: url('/images/about/btn_next.gif') no-repeat scroll 0 0;
	width: 30px;
	height: 30px;

	&:hover {
		background-position: 0 -30px;
	}
`;

const YearPrevArrow = styled.span`
	cursor: pointer;
	margin: 0 15px;
	background: url('/images/about/btn_prev.gif') no-repeat scroll 0 0;
	width: 30px;
	height: 30px;

	&:hover {
		background-position: 0 -30px;
	}
`;

const HistoryContainer = styled.div`
	display: flex;
	overflow: hidden;
	margin: 50px 112px;
`;

const HistoryList = styled.ul`
	text-align: left;
	display: flex;
	transition: transform 0.4s ease 0s;

	transform: ${props => `translate3d(${props.historyShiftPos}px, 0px, 0px)`};
`;

const HistoryItem = styled.li`
	flex: 1 0 236px;
	border-left: 1px solid #D9D9D9;
	height: 500px;
	display: block;
	padding: 10px;
	font-size: 12px;
`;

const HistoryHeader = styled.h3`
	color: #999;
	height: 34px;
	font-size: 36px;
	margin-top: 20px;
	margin-bottom: 15px;
`;

const HistoryImage = styled.img`
	margin-bottom: 10px;
`;

class History extends React.Component {
	state = {
		list: [],
		yearShiftPos: 0,
		historyShiftPos: 0,
		yearMaxPos: 0,
		selectedYear: 0,
	}

	async componentDidMount() {
		const list = await this.getPageData();

		const dedupedList = lodashUniqBy(list, year => year.date1).map((year) => year.date1);
		const yearMaxPos = this.calcMaxScollablePos(dedupedList);

		this.setState({ list, dedupedList, yearMaxPos });
	}

	getPageData = async () => {
		const fetchUrl = `${config.serverEndPoint}/historyPage`;

		try {
			const result = await axios.get(fetchUrl);

			return result.data;
		} catch (err) {
			console.log(err);
		}
	}

	calcMaxScollablePos(list) {
		// 80px per item
		return list.length * 80;
	}

	onPrevClick = () => {
		const { list, yearShiftPos } = this.state;
		if (!list || list.length === 0) {
			return;
		}

		if ((yearShiftPos + SCROLL_DISTANCE) < 0) {
			this.setState({ yearShiftPos: this.state.yearShiftPos + SCROLL_DISTANCE });
		} else {
			this.setState({ yearShiftPos: 0 });
		}
	}

	onNextClick = () => {
		const { list, yearShiftPos, yearMaxPos } = this.state;
		if (!list || list.length === 0) {
			return;
		}

		if (Math.abs(yearShiftPos - SCROLL_DISTANCE) < yearMaxPos) {
			this.setState({ yearShiftPos: this.state.yearShiftPos - SCROLL_DISTANCE });
		} else {
			this.setState({ yearShiftPos: -yearMaxPos });
		}
	}

	onYearClicked = (year) => {
		const { list } = this.state;

		const firstYearItem = list.find((item) => item.date1 === year);
		const firstYearItemPos = list.indexOf(firstYearItem);

		this.setState({ selectedYear: year, historyShiftPos: -firstYearItemPos * 236 })
	}

	render () {
		const { list, yearShiftPos, dedupedList, selectedYear, historyShiftPos } = this.state;

		return (
			<Wrapper>
				<YearContainer>
					<YearList yearShiftPos={yearShiftPos}>
						{ dedupedList && dedupedList.map((year) => {
							const selected = selectedYear === year;
							return (
								<YearItem
									key={year}
									selected={selected}
									onClick={() => this.onYearClicked(year)}
								>
									<YearItemSpearator selected={selected} />
									{ year }
									<YearItemSpearator selected={selected} />
								</YearItem>
							)
						})}
					</YearList>
					<YearArrowContainer>
						<YearPrevArrow onClick={this.onPrevClick} />
						<YearNextArrow onClick={this.onNextClick} />
					</YearArrowContainer>
				</YearContainer>
				<HistoryContainer>
					<HistoryList historyShiftPos={historyShiftPos}>
						{ list && list.map((item, idx) => {
							const shdShowYear = idx === 0 || list[idx - 1].date1 !== item.date1;
							return (
								<HistoryItem>
									<HistoryHeader>{shdShowYear ? item.date1 : ' '}</HistoryHeader>
									{ item.image1 && <HistoryImage src={`${config.imagePrefix}/abouthistory/${item.image1}`} alt="" /> }
									<div>{item.description1}</div>
								</HistoryItem>
							)
						})}
					</HistoryList>
				</HistoryContainer>
			</Wrapper>
		);
	}
}
export default History;