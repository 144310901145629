import React from 'react';
import styled from 'styled-components';

import config from '../../../../config';
import { media, TabletAndDesktop, MobileOnly } from '../../../../styles/breakpoints';
import { formatPrice } from '../../../../utils';

const Wrapper = styled.div`
  display: flex;
  padding: 30px 0;
  border: solid 1px #e2e2e2;
  border-top: none;
  justify-content: center;
  align-items: center;

  text-align: center;

  ${media.phone`
    padding: 3px 0;
    border: none;
    border-bottom: solid 1px #e2e2e2;
  `};
`;

const ProductImage = styled.div`
  flex: 1;
  padding: 0 30px;

  img {
    width: 100%;
  }

  ${media.phone`
    flex: 0 0 100px;
    margin: 0 20px 0 0;
    border: solid 1px #ccc;
    border-radius: 7px;

    height: 100px;
    padding: 0;
    display: flex;
    align-items: center;
  `};
`;

const ProductDetail = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  text-align: left;
  font-size: 14px;

  img {
    width: 130px;
  }

  span {
    margin-top: 10px;
  }

  ${media.phone`
    flex: 0 0 100px;
    margin-right: 20px;
  `};
`;

const Quantity = styled.div`
  flex: 1;

  ${media.phone`
    margin-top: 10px;
  `};
`;

const Price = styled.div`
  flex: 1;
`;

const ContentRow = styled.div`
  display: flex;
  padding: 50px 35px 50px 0;
  align-items: center;

  ${media.tablet`
    padding: 50px 10px 50px 0;
  `};

  ${media.phone`
    padding: 5px 20px;
  `};
`;

const InfoSection = styled.div`
  img {
    display: block;
    padding-bottom: 5px;
  }

  ${media.phone`
    text-align: left;
    font-size: 14px;
    flex: 1 0;
  `};
`;

const ProductItem = ({ product, hidePrice }) => {
  const { image, name, price, quantity, seriesImage, colorName } = product;

  return (
    <Wrapper>
      <TabletAndDesktop>
        <ProductDetail>
          { seriesImage && <img src={`${config.imagePrefix}/productseries/${seriesImage}`} alt="" /> }
          <span>
            { colorName } { name }
          </span>
        </ProductDetail>
        <ProductImage>
          { image && <img src={`${config.imagePrefix}/productbanner/${image}`} alt="" /> }
        </ProductImage>
        <Quantity>
          {quantity}
        </Quantity>
        { !hidePrice &&
          <Price>
            ￥{formatPrice(price * quantity)}
          </Price>
        }
      </TabletAndDesktop>
      <MobileOnly>
        <ContentRow>
          <ProductImage>
            { image && <img src={`${config.imagePrefix}/productbanner/${image}`} alt="" /> }
          </ProductImage>
          <InfoSection>
            { seriesImage && <img src={`${config.imagePrefix}/productseries/${seriesImage}`} alt="" /> }
            { colorName } { name }
            <Quantity>
              <div>{`單價: ￥${formatPrice(price)}`}</div>
              <div>{`数量: ${quantity}`}</div>
            </Quantity>
          </InfoSection>
        </ContentRow>
      </MobileOnly>
    </Wrapper>
  )
}

export default React.memo(ProductItem);