import React from 'react';
import styled from 'styled-components';

import { CaptchaValidateForm, PageContainer, TextInput } from '../../Common';

const MainContaineWrapper = styled.div`
  background-color: #f2f2f2;
`;

const MainContainer = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f2f2f2;
`;

const Button = styled.button`
  width: 100%;
  margin-top: 50px;
  font-weight: 600;
  border: solid 1px #dadada;
  padding: 10px;
  background-color: #ebecf0;
  cursor: pointer;
`;

const RegisterPageComponent = ({ editPassword }) => {
  const [oldPw, setOldPw] = React.useState('');
  const [newPw, setNewPw] = React.useState('');
  const [newPw2, setNewPw2] = React.useState('');
  const [newPwErrMsg, setNewPwErrMsg] = React.useState(null);

  const onOldPwChange = React.useCallback((event) => {
    setOldPw(event.target.value);
  }, [setOldPw]);

  const onNewPwChange = React.useCallback((event) => {
    setNewPw(event.target.value);
    setNewPwErrMsg(null);
    }, [setNewPw, setNewPwErrMsg])

  const onNewPw2Change = React.useCallback((event) => {
    setNewPw2(event.target.value);
    setNewPwErrMsg(null);
    }, [setNewPw2, setNewPwErrMsg])

  const onSubmit = React.useCallback(e => {
    e.preventDefault();

    if (newPw !== newPw2) {
      setNewPwErrMsg("两次输入的新密码不一致");
    } else if (newPw.length < 6) {
      setNewPwErrMsg("密码不能少于6个字符");
    } else {
      editPassword(oldPw, newPw);
    }
  }, [setNewPwErrMsg, oldPw, newPw, newPw2])

  return (
    <PageContainer title="个人资料" backBtnLink="/user">
      <MainContaineWrapper>
        <CaptchaValidateForm authHeader="修改密码" formHeader="修改密码">
          {({ pin }) => (
            <MainContainer>
              <TextInput
                type="password"
                header="输入旧密码"
                name="oldPw"
                value={oldPw}
                onChange={onOldPwChange} />
              <TextInput
                type="password"
                header="输入新密码"
                name="newPw"
                value={newPw}
                onChange={onNewPwChange}
                hasErr={newPwErrMsg}
                errMsg={newPwErrMsg} />
              <TextInput
                type="password"
                header="再次输入新密码"
                name="newPw2"
                value={newPw2}
                onChange={onNewPw2Change} />

              <Button onClick={onSubmit}>确认修改</Button>
            </MainContainer>
          )}
        </CaptchaValidateForm>
      </MainContaineWrapper>
    </PageContainer>
  )
};

export default RegisterPageComponent;