import React from 'react';
import axios from 'axios';

import { getIntQueryStringByKey, withScrollToTop } from '../../../utils';
import config from '../../../config';
import ProductSeriesPageComponent from './ProductSeriesPageComponent';
import { withCart } from '../../../context';

class ProductSeriesListPageContainer extends React.Component {
  state = {
    pageTitle: '',
    pageDesc: '',
    seriesLogo: '',
    bannerItems: [],
    productItems: [],
  }

  async componentDidMount() {
    this.loadPageData();
  }

  loadPageData() {
    const { location } = this.props;
    const seriesId = getIntQueryStringByKey(location.search, 'id', 1);

    const fetchUrl = `${config.serverEndPoint}/productSeriesPage?id=${seriesId}`;

    return axios.get(fetchUrl)
      .then(result => result.data)
      .then(result => {
        const { pageTitle, pageDesc, bannerItems, productItems, seriesLogo } = result ? result : { pageTitle: '', seriesLogo: '', bannerItems: [], productItems: [] };

        this.setState({ pageTitle, pageDesc, seriesLogo, bannerItems, productItems });
      })
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    const { pageTitle, pageDesc, seriesLogo, bannerItems, productItems } = this.state;
    const { location, cart } = this.props;
    const seriesId = getIntQueryStringByKey(location.search, 'id', 1);
    
    return (
      <ProductSeriesPageComponent
        seriesId={seriesId}
        seriesLogo={seriesLogo}
        pageTitle={pageTitle}
        pageDesc={pageDesc}
        bannerItems={bannerItems}
        productItems={productItems}
        addItemToCart={cart.addItemToCart} />
    )
  }
};

export default withScrollToTop(withCart(ProductSeriesListPageContainer), ['id']);

