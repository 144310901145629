import React from 'react';
import styled from 'styled-components';
import qs from 'query-string';
import { Link } from 'react-router-dom';

import Art from './Art'
import Press from './Press'
import NewsFeed from './NewsFeed'
import History from './History'
import HistoryMobile from './HistoryMobile'
import { media, TabletAndDesktop, MobileOnly } from '../../styles/breakpoints';
import { CompanyFooter } from '../Common';
import { getIntQueryStringByKey } from '../../utils';

const TabWrapper = styled.div`
  display: flex;

  ${media.phone`
  `};
`;

const Footer = styled.div`
  width:100%
`

const Tab = styled.div`
  width: 160px;
  height: 40px;
  text-align: center;
  font-size: 18px;
  line-height: 40px;
  cursor: pointer;
  
  
  background-color: ${props => props.selected ? '#f2f0f1' : '#cccacb' };
  
  :hover {
    color: #000;
    background-color: #f2f0f1;
  }

  
  ${media.tablet`
    display: none;
    width: 150px;
    height: 40px;
  `};

  ${media.phone`
    display: none;
    width: 60px;
    height: 30px;
    line-height: 30px;
    font-size: 16px;
  `};
`;

const Wrapper = styled.section`
background-color: #fff;
.row{
  margin:0px
}
`;

const Content = styled.section`
background-color: #fff;
`;


class AboutComponent extends React.Component {

  state = {
    value: parseInt(this.props.location.search.split('=')[1]),
    detail: 1
  };

  componentDidUpdate(prevProps) {
    const prevId = getIntQueryStringByKey(prevProps.location.search, 'id', 1);
    const currId = getIntQueryStringByKey(this.props.location.search, 'id', 1);
    const prevDetailId = getIntQueryStringByKey(prevProps.location.search, 'detail', 1);
    const currDetailId = getIntQueryStringByKey(this.props.location.search, 'detail', 1);

      if (prevId !== currId ||prevDetailId !==  currDetailId){
      this.loadPageData();
    }
  }

  async loadPageData() {
    // Reset State
    this.setState({
      value: getIntQueryStringByKey(this.props.location.search, 'id', 1),
      detail:getIntQueryStringByKey(this.props.location.search, 'detail', 1)
    });
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleQ1Click = () => {
    this.setState(state => ({ Q1: !state.Q1 }));
  }

  render() {
    const { value } = this.state;
    const parsed = qs.parse(this.props.location.search) || {};
    const id = parsed.id || 1;
    const selectedIdx = parsed.id ? parseInt(parsed.id) : 0;
    const typeList = ["最新动态","新闻","公司沿革","建筑与艺术"]
    const detail = parsed.detail || 1;
    return (
      <div>
        <TabWrapper>
            { typeList.map((type, idx) => (
              <Link key={idx} to={`${this.props.location.pathname}?id=${idx}`}>
                <Tab selected={idx === selectedIdx}>
                  { type }
                </Tab>
              </Link>
            ))}
          </TabWrapper>
        <Wrapper>
          <Content>
            {value === 0 && <NewsFeed />}
            {value === 1 && <Press detail={detail} />}
            {value === 2 && (
              <React.Fragment>
                <MobileOnly>
                  <HistoryMobile />
                </MobileOnly>
                <TabletAndDesktop>
                  <History />
                </TabletAndDesktop>
              </React.Fragment>
            )}
            {value === 3 && <Art />}
          </Content>
          <Footer className="row">
            <div className="col-xs-1 col-lg-1 col-md-1"></div>
            <div className="col-xs-10 col-lg-10 col-md-10">
              <CompanyFooter />
            </div>
            <div className="col-xs-1 col-lg-1 col-md-1"></div>
          </Footer>
         </Wrapper>
      </div>    
    );
  }
}


export default AboutComponent;
