import React, { Component } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: #fff;
  padding: 20px;

  img {
    width: 100%;
  }
`;

class RecruitmentPage extends Component {
  render() {
    return (
      <Wrapper>
        <img src="/images/index/recruitment.jpg" alt="" />
      </Wrapper>
    );
  }
}

export default RecruitmentPage;
