import React from 'react';
import styled from 'styled-components';
import { media } from '../../styles/breakpoints';
import { getIntQueryStringByKey } from '../../utils';

import qs from 'query-string';
import { Link } from 'react-router-dom';

import Quality from './Quality';
import Design from './Design';
import Germany from './Germany';
import { CompanyFooter } from '../Common';

const TabWrapper = styled.div`
  display: flex;

  ${media.phone`
  `};
`;

const Tab = styled.div`
  width: 160px;
  height: 40px;
  text-align: center;
  font-size: 18px;
  line-height: 40px;
  cursor: pointer;
  
  background-color: ${props => props.selected ? '#f2f0f1' : '#cccacb' };
  
  :hover {
    color: #000;
    background-color: #f2f0f1;
  }

  
  ${media.tablet`
    display: none;
    width: 150px;
    height: 40px;
  `};

  ${media.phone`
    width: 60px;
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    display: none;
  `};
`;

const Wrapper = styled.section`
background-color: #fff;
.row{
  margin:0px
}
`;

const Content = styled.section`
padding:10px;
`;



styled(CompanyFooter)`
padding:50px;
`

class BrandComponent extends React.Component {
  state = {
    value: parseInt(this.props.location.search.split('=')[1]),
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  componentDidUpdate(prevProps) {
    const prevId = getIntQueryStringByKey(prevProps.location.search, 'id', 1);
    const currId = getIntQueryStringByKey(this.props.location.search, 'id', 1);
    // const prevColorId = getIntQueryStringByKey(prevProps.location.search, 'colorId', 1);
    // const currColorId = getIntQueryStringByKey(this.props.location.search, 'colorId', 1);

    // if (prevId !== currId || prevColorId !== currColorId) {
      if (prevId !== currId ){
      this.loadPageData();
    }
  }

  async loadPageData() {
    // Reset State
    this.setState({
      value: getIntQueryStringByKey(this.props.location.search, 'id', 1)
    });
  }

  render() {
    const { value } = this.state;
    const parsed = qs.parse(this.props.location.search) || {};
    const selectedIdx = parsed.id ? parseInt(parsed.id) : 0;
    const typeList = ["质量","设计","德国制造"]

    return (
      <div>
        <TabWrapper>
            { typeList.map((type, idx) => (
              <Link key={idx} to={`${this.props.location.pathname}?id=${idx}`}>
                <Tab selected={idx === selectedIdx}>
                  { type }
                </Tab>
              </Link>
            ))}
          </TabWrapper>
        <Wrapper>
          <Content>
              {value === 0 && <Quality />}
              {value === 1 && <Design />}
              {value === 2 && <Germany />}
          </Content>
          <div className="row">
            <div className="col-xs-1 col-lg-1 col-md-1"></div>
            <div className="col-xs-10 col-lg-10 col-md-10">
              <CompanyFooter />
            </div>
            <div className="col-xs-1 col-lg-1 col-md-1"></div>
          </div>
        </Wrapper>
      </div>

    );
  }
}



export default BrandComponent;