import React from 'react';
import styled from 'styled-components';
import lodashGet from 'lodash/get';

import { PageContainer, InputWithButton } from '../../Common';
import axios from 'axios';
import config from '../../../config';
import { withPopup } from '../../../context';
import { media } from '../../../styles/breakpoints';

const MainContaineWrapper = styled.div`
  background-color: #f2f2f2;
`;

const Wrapper = styled.div`
  margin: auto;
  width: 70%;
  padding: 150px 100px;

  ${media.tablet`
    padding: 100px 30px;
  `};

  ${media.phone`
    width: 100%;
    padding: 30px 10px;
  `};
`;

const MainContainer = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f2f2f2;
`;

const InputHeader = styled.div`
  width: 100%;
  margin-bottom: 5px;
  text-align: left;
  color: #808080;

  span {
    color: red;
  }
`;

const Input = styled.input`
  margin-bottom: 15px;
  border-radius: 0px !important;
`;

const InputArea = styled.textarea`
  margin-bottom: 15px;
  border-radius: 0px !important;
`;

const Button = styled.button`
  width: 100%;
  margin-top: 50px;
  font-weight: 600;
  border: solid 1px #dadada;
  padding: 10px;
  background-color: #ebecf0;
  cursor: pointer;
`;

const RegisterPageComponent = ({ createAddress, togglePopup }) => {
  const [name, setName] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [captcha, setCaptcha] = React.useState('');
  const [address, setAddress] = React.useState('');

  const onNameChange = React.useCallback((event) => {
    setName(event.target.value);
  }, [setName]);

  const onPhoneChange = React.useCallback((event) => {
    setPhone(event.target.value);
  }, [setPhone])

  const onCaptchaChange = React.useCallback((event) => {
    setCaptcha(event.target.value);
  }, [setCaptcha])

  const onAddressChange = React.useCallback((event) => {
    setAddress(event.target.value);
  }, [setAddress])

  const onSubmit = React.useCallback(async (e) => {
    e.preventDefault();

    createAddress({
      name, address, phone, pin: captcha
    })
  }, [createAddress, name, address, phone, captcha]);

  const onGetPin = React.useCallback(async () => {
    try {
      const generatePinUrl = `${config.serverEndPoint}/userAddress/validate-phone`;
      const result = await axios.put(generatePinUrl, { phone }).then(result => result.data);

      if (result.error) {
        console.log(result.error);
        if (result.error === "REGISTERED") {
          result.message = "此电话号码已注册";
        }
        togglePopup({
          type: 'info',
          message: result.message,
        })
      }
    } catch (err) {
      console.log(err);
      const responseMsg = lodashGet(err, 'response.data.error', '更新失败');
      togglePopup({
        type: 'info',
        message: responseMsg,
      })
    }
  }, [phone]);

  return (
    <PageContainer title="个人资料" backBtnLink="/user">
      <MainContaineWrapper>
        <Wrapper>
          <MainContainer>
            <InputHeader>收货人 <span>*收件人必须为实名, 否则不会发货</span></InputHeader>
            <Input className="form-control" name="name" value={name} onChange={onNameChange} />

            <InputHeader>收货人联系号码 <span>*必須是收件人電話</span></InputHeader>
            <InputWithButton
              name="phone"
              value={phone}
              onBtnClicked={onGetPin}
              onChange={onPhoneChange}
              btnText="获取验证码" />
            <InputHeader>输入验证码</InputHeader>
            <Input className="form-control" name="captcha" type="password" value={captcha} onChange={onCaptchaChange} />

            <InputHeader>收货地址</InputHeader>
            <InputArea className="form-control" name="address" value={address} onChange={onAddressChange} />

            <Button onClick={onSubmit}>添加</Button>
          </MainContainer>
        </Wrapper>
      </MainContaineWrapper>
    </PageContainer>
  )
};

export default withPopup(RegisterPageComponent);