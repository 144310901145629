export default [
    {
      header: '关于凌美',
      items: [
        {
          name: '最新动态',
          to: '/about?id=0'
        },
        {
          name: '新闻',
          to: '/about?id=1'
        },
        {
          name: '公司沿革',
          to: '/about?id=2'
        },
        {
          name: '建筑与艺术',
          to: '/about?id=3'
        },
      ]
    },
    {
      header: '设计',
      items: [
        {
          name: '设计亮点',
          to: '/design?id=0'
        },
        {
          name: '设计师',
          to: '/design?id=1'
        },
        {
          name: '国际奖项',
          to: '/design?id=2'
        },
      ]
    },
    {
      header: '品牌',
      items: [
        {
          name: '质量2',
          to: '/brand?id=0'
        },
        {
          name: '设计',
          to: '/brand?id=1'
        },
        {
          name: '德国制造',
          to: '/brand?id=2'
        }
      ]
    },
  ]