import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { media } from '../../../../styles/breakpoints';
import config from '../../../../config';

const Wrapper = styled.div`
  width: 28%;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 15px 10px;

  ${media.phone`
    width: 80%;
  `};
`;

const HeaderWrapper = styled.div`
  font-size: 13px;
  color: gray;
  text-align: left;
  padding-right: 20px;
  margin-bottom: 20px;
`;

const ProductImage = styled.div`
  cursor: pointer;
  min-height: 140px;

  img {
    width: 100%;
  }
`;

const AccessoryItem = ({ accessory }) => {
  return (
    <Wrapper>
      <HeaderWrapper>
        {accessory.name}
      </HeaderWrapper>

      <ProductImage>
        <Link to={`/product?id=${accessory.id}&colorId=${accessory.color[0].colorId}`}>
          <img src={`${config.imagePrefix}/productbanner/${accessory.color[0].image}`} alt="product" />
        </Link>
      </ProductImage>

    </Wrapper>
  );
}

export default React.memo(AccessoryItem);