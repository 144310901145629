import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';

import config from '../../config';
import RegisterPageComponent from './RegisterPageComponent';
import { withPopup, withAuth } from '../../context';
import { getQueryStringByKey } from '../../utils';

class RegisterPageContainer extends React.Component {
  onRegister = async (payload) => {
    const { history, togglePopup, location, auth } = this.props;
    const isThirdParty = getQueryStringByKey(location.search, 'thirdparty');

    try {
      const result = await auth.register(payload, !!isThirdParty);
      if (!result) {
        throw new Error('Register failed');
      }

      if (result.error) {
        togglePopup({
          type: 'info',
          message: result.message,
          confirmHandler: () => {
            if (result.error === 'PIN_EXPIRE') {
              history.push('/login');
            }
          }
        });

        return;
      }

      togglePopup({
        type: 'info',
        message: '注册成功',
        confirmHandler: () => history.push('/user')
      })
    } catch (e) {
      console.log(e);
      togglePopup({
        type: 'info',
        message: '注册失败, 请检查你的资料'
      })
    }
  }

  render() {
    return (
      <RegisterPageComponent onRegister={this.onRegister} />
    )
  }
};

export default withRouter(
  withPopup(
    withAuth(RegisterPageContainer)
  )
);
