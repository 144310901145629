import React from 'react';
import styled from 'styled-components';

import { CaptchaValidateForm, PageContainer } from '../../Common';

const wechatUrl = `https://open.weixin.qq.com/connect/qrconnect?appid=wxbd13eaf888a070fb&scope=snsapi_login&state=lamy&self_redirect=default&style=white&href=https://csdnimg.cn/release/passport/history/css/replace-wx-style.css`;
const redirectUrl = `${window.location.origin}/user/third-party-binding`;

const MainContaineWrapper = styled.div`
  background-color: #f2f2f2;
`;

const MainContainer = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f2f2f2;
`;

const RegisterPageComponent = ({ type }) => {
  console.log(`${wechatUrl}&redirect_uri=${redirectUrl}/wechat`)
  return (
    <PageContainer title="个人资料" backBtnLink="/user">
      <MainContaineWrapper>
        <CaptchaValidateForm authHeader={`绑定${type === 'wechat' ? '微信' : 'QQ'}`} formHeader={`绑定${type === 'wechat' ? '微信' : 'QQ'}`}>
          {({ pin }) => (
            <MainContainer>
              <div className="code-content code-login">
                <div className="code-pic">
                  <iframe
                    title="WeChatLogin"
                    src={`${wechatUrl}&redirect_uri=${redirectUrl}/wechat`}
                    frameBorder="0"
                    scrolling="no"
                    width="100%"
                    height="300px" />
                </div>
              </div>
            </MainContainer>
          )}
        </CaptchaValidateForm>
      </MainContaineWrapper>
    </PageContainer>
  )
};

export default RegisterPageComponent;