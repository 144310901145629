import React from 'react';
import config from '../../config';
import axios from 'axios';
import jwtDecode from 'jwt-decode';

const { Consumer, Provider } = React.createContext();

class AuthProvider extends React.Component {
  token = null;
  tokenObj = null;
  openid = null;
  tempToken = null;

  state = {
    isVip: false,
  }

  constructor(props) {
    super(props);

    this.loadToken();

    this.setAxiosHeader();

    this.fetchVipStatus();
  }

  login = async (username, password, isSwiped) =>  {
    const loginUrl = `${config.serverEndPoint}/login`;
    return await axios.post(loginUrl, { username, password, isSwiped })
      .then(result => result.data)
      .then(result => {
        this.storeToken(result.token);

        this.fetchVipStatus();
        return { success: true };
      })
      .catch(err => {
        console.log(err);
        const response = err && err.response && err.response.data ? err.response.data : {};

        return { success: false, ...response };
      });
  }

  /**
   * Return an object to indicate the login status
   * success - login successed
   * createUser - account not exist, shd redirect to login page
   * fail - login failed
   */
  thirdPartyLogin = async (type, code) => {
    const loginUrl = `${config.serverEndPoint}/third-party-login/${type}`;

    return await axios
      .put(loginUrl, { code })
      .then(result => result.data)
      .then(result => {
        if (result.isRegistered) {
          this.storeToken(result.token);

          this.setState({ isVip: result.isVip });
          return 'success';
        } else {
          this.storeTempToken(result.openid, result.token);
          
          return 'createUser';
        }
      })
      .catch(err => {
        console.log(err);
        return 'fail';
      })
  }

  register = async (payload, isThirdParty) => {
    const registerUrl = `${config.serverEndPoint}/register`;

    if (isThirdParty &&  this.openid && this.tempToken) {
      payload = Object.assign(payload, {
        openid: this.openid,
        thirdPartyToken: this.tempToken
      });
    }

    return await axios
      .put(registerUrl, payload)
      .then(result => result.data)
      .then(result => {
        if (result.error) {
          return result;
        }

        this.storeToken(result.token);

        if (this.openid && this.tempToken) {
          this.openid = null;
          this.tempToken = null;
        }

        return true;
      })
      .catch(err => {
        console.log(err);
        return false;
      })
  }

  forceUpdateUserData = async (formData) => {
    try {
      const updateUrl = `${config.serverEndPoint}/user/force-update`;
      const result = await axios.put(updateUrl, formData)
        .then(result => result.data);

      this.storeToken(result.token);

      return;
    } catch (err) {
      throw err;
    }
  }

  logout = () => {
    this.clearToken();
    this.setState({ isVip: false });
  }

  isAuthed = () => {
    try {
      if (!this.token || !this.tokenObj) {
        return false;
      }

      if ((this.tokenObj.role === 'admin' || this.tokenObj.role === 'user')) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  shouldUpdateData = () => {
    try {
      if (!this.token || !this.tokenObj) {
        return false;
      }

      return !!this.tokenObj.shdUpdateData;
    } catch (e) {
      return false;
    }
  }

  getToken() {
    return this.token;
  }

  loadToken() {
    let token = document.cookie.match('(^|;) ?jwt=([^;]*)(;|$)');
    token = token ? token[2] : null;

    this.token = token;
    
    if (token) {
      this.tokenObj = jwtDecode(token);
    }
  }

  storeToken(token) {
    document.cookie = `jwt=${token}; path=/`;
    this.token = token;
    this.tokenObj = jwtDecode(token);
  }

  clearToken() {
    document.cookie = `jwt=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    this.token = null;
    this.tokenObj = null;
  }

  storeTempToken(openid, token) {
    this.openid = openid;
    this.tempToken = token;
  }

  setAxiosHeader() {
    axios.interceptors.request.use(config => {
      if (this.token) {
        config.headers.Authorization = `Bearer ${this.token}`;
      }
      return config;
    });
  }

  fetchVipStatus = async () => {
    if (!this.token) {
      return null;
    }

    try {
      const getVipStatusUrl = `${config.serverEndPoint}/user/vip`;
      const result = await axios.get(getVipStatusUrl)
        .then(result => result.data);

      this.setState({ isVip:  result && result.isVip });
    } catch (e) {
      console.log(e);
    }
  }

  getPhoneNumber = () => {
    if (!this.token || !this.tokenObj) {
      return null;
    }

    return this.tokenObj.sub;
  }

  getUser = () => {
    if (!this.token || !this.tokenObj) {
      return null;
    }
    return this.tokenObj;
  }

  render() {
    const { isVip } = this.state;

    return (
      <Provider value={{
        isVip: isVip,
        login: this.login,
        thirdPartyLogin: this.thirdPartyLogin,
        register: this.register,
        forceUpdateUserData: this.forceUpdateUserData,
        logout: this.logout,
        isAuthed: this.isAuthed,
        shouldUpdateData: this.shouldUpdateData,
        getPhoneNumber: this.getPhoneNumber,
        getUser: this.getUser
      }}>
        {this.props.children}
      </Provider>
    )
  }
}

const withAuth = (Component) => (props) => (
  <Consumer>
    { auth => <Component auth={auth} {...props} /> }
  </Consumer>
)

export {
  withAuth,
  AuthProvider
};