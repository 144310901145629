import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { withRouter } from 'react-router-dom';

import config from '../../../config';
import { PageContainer } from '../../Common';
import { media } from '../../../styles/breakpoints';
import { getQueryStringByKey } from '../../../utils';
import { withAuth, withPopup } from '../../../context';

const Wrapper = styled.div`
  margin: auto;
  width: 70%;
  padding: 150px 100px;

  ${media.tablet`
    padding: 100px 30px;
  `};

  ${media.phone`
    width: 100%;
    padding: 30px 10px;
  `};
`;

const MainContaineWrapper = styled.div`
  background-color: #f2f2f2;
`;

class LoginRedirectPage extends React.PureComponent {
  async componentDidMount() {
    const { match, location, history, togglePopup, auth } = this.props;
    const code = getQueryStringByKey(location.search, 'code')

    if (!code) {
      togglePopup({
        type: 'info',
        message: '登入失败',
      });
      history.push('/');

      return;
    }

    try {
      const loginResult = await auth.thirdPartyLogin(match.params.type, code);

      if (loginResult === 'success') {
        history.push('/');
      } else if (loginResult === 'createUser') {
        togglePopup({
          type: 'info',
          message: '未有绑定的帐户, 转至注册口页面',
        })
        history.push('/register?thirdparty=true');
      } else {
        throw new Error(`Unexpected loginResult ${loginResult}`);
      }
    } catch (e) {
      togglePopup({
        type: 'info',
        message: '登入失败',
      })
      history.push('/');
    }
  }


  render() {
    return (
      <PageContainer title="LAMY帐户">
        <Wrapper>
          <MainContaineWrapper>

          </MainContaineWrapper>
        </Wrapper>
      </PageContainer>
    )
  }
}

export default withRouter(
  withPopup(
    withAuth(LoginRedirectPage)
  )
);