import React from 'react';
import axios from 'axios';

import config from '../../config';
import CategoryPageComponent from './CategoryPageComponent';
import { categoryList, serviceList } from './configs';

class CategoryPageContainer extends React.Component {
  state = {
    bannerItems: [],
    seriesItems: []
  }

  async componentDidMount() {
    const result = await this.getPageData();
    let { bannerItems, seriesItems } = result ? result : { bannerItems: [], seriesItems: [] };

    bannerItems.forEach(banner => {
      banner.image = `${config.imagePrefix}/categorybanner/${banner.image}`;
    });

    this.setState({
      bannerItems, seriesItems
    })
  }

  getPageData() {
    const fetchUrl = `${config.serverEndPoint}/categoryPage`;

    return axios.get(fetchUrl)
      .then(result => result.data)
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    const { bannerItems, seriesItems } = this.state;
    return (
      <CategoryPageComponent
        bannerItems={bannerItems}
        categoryList={categoryList}
        seriesItems={seriesItems}
        serviceList={serviceList} />
    )
  }
};

export default CategoryPageContainer;

