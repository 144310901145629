import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Carousel } from '../Common';
import { media, MobileOnly, TabletAndDesktop } from '../../styles/breakpoints';

import axios from 'axios';
import config from '../../config';

const Wrapper = styled.div`
  text-align:left;
`
const Body = styled.div`
  width: 100%;
  overflow: hidden;
`

const Container = styled.div`

`

const Text = styled.div`
  overflow: hidden;
  position: relative;
  width: 662px;
  background-color: #F5F5F5;

  ${media.tablet`
    
  `};

  ${media.phone`
    flex: 1;
    height: 200px;
  `};  
`
// Content part
const Img = styled.img`
  max-width: 100%;
  height: auto;
  ${media.tablet`
    max-width: none;
    max-height: 100%;
  `};

  ${media.phone`
    height: 200px;
    max-width: none;
    max-height: 100%;
  `};
`


const Content = styled.div`
  padding: 50px;
  ${media.tablet`
    padding: 5px;
  `};

  ${media.phone`
    padding: 5px;
  `};
`

const Image = styled.div`
  overflow: hidden;
  ${media.tablet`
      
  `};

  ${media.phone`
    flex: 1;
    height: 200px;
  `};
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`
const Year = styled.div`
  text-align: right;

  ${media.tablet`
    margin-top: 1px;
  `};

  ${media.phone`
    order: 2;
    margin: 5px;
  `};
`
const Logo = styled.div`
  padding : 5px;

  ${media.phone`
    width: 40%;
  `};

  img {
    width: 100%;
  }
`

const Title = styled.div`
  margin-top: 5px;
  ${media.tablet`
    padding: 5px;
  `};

  ${media.phone`
    padding: 5px;
  `};
`

const Description = styled.p`
  padding-top: 30px;
  ${media.tablet`
    padding: 5px;
  `};

  ${media.phone`
    font-size: 14px;
    padding: 5px;
  `};
`

const ContentLink = styled.div`
  width: 100%;
  position: absolute;
  bottom: 5px;
  right: 15px;
  text-align: right;
  padding: 0 5%;
  ${media.tablet`
    padding: 5px;
  `};

  ${media.phone`
    padding: 5px;
  `};
`
const Row = styled.div`
  overflow: auto;
  display: flex;
  margin-top: 30px;

  ${media.phone`
    height: 200px;
  `};  
  
  &:nth-child(odd) {
    & ${Text} {
      order: 2;
    }
  }

  &:nth-child(even) {
    order: 1;
  }
`

class NewsFeed extends React.Component {
  state = {
    bannerItems: [],
    mobileBannerItems: [],
    list: []
  }

  async componentDidMount() {
    const { news, bannerItems } = await this.getPageData();
    const mobileBannerItems = [];
    
    news.sort((a, b) => {
      return b.id - a.id
    });

    if (bannerItems) {
      bannerItems.forEach(banner => {
        banner.image = `${config.imagePrefix}/news/${banner.image}`;
        const mobileBannerItem = { ...banner };
        mobileBannerItem.image = `${config.imagePrefix}/news/${banner.mobileImage}`;
        mobileBannerItems.push(mobileBannerItem);
      });
    }

    this.setState({ list: news, bannerItems, mobileBannerItems })
  }

  getPageData = async () => {
    const fetchUrl = `${config.serverEndPoint}/newsPage`

    try {
      const result = await axios.get(fetchUrl);

      return result.data;
    }
    catch (err) {
      console.log(err);
    }
  }

  render() {
    const { bannerItems, mobileBannerItems, list } = this.state;
    return (
      <Wrapper>
        <MobileOnly>
          <Carousel items={mobileBannerItems} />
        </MobileOnly>
        <TabletAndDesktop>
          <Carousel items={bannerItems} />
        </TabletAndDesktop>
        <br />
        <Body className="row">
          <div className="col-xs-1 col-lg-1 col-md-1"></div>
          <div className="col-xs-10 col-lg-10 col-md-10">
            <Container className="container">
              { list && list.map((ele, idx) => {
                return ([
                  <Row>
                    <Text>
                      <Content>
                        <Header>
                          <Logo><img src="./images/product/desktop_banner_logo.png" alt="" /></Logo>
                          <Year>{ele.date1}</Year>
                        </Header>
                        <Title>{ele.title}</Title>
                        <Description>{ele.description1}</Description>
                      </Content>
                      <ContentLink><Link to={`/about/detail/${ele.id}`}>了解更多</Link></ContentLink>
                    </Text>

                    <Image>
                      <Link to={`/about/detail/${ele.id}`}>
                        <MobileOnly>
                          <Img src={`${config.imagePrefix}/news/${ele.image5}`} alt="" />
                        </MobileOnly>
                        <TabletAndDesktop>
                          <Img src={`${config.imagePrefix}/news/${ele.image2}`} alt="" />
                        </TabletAndDesktop>
                      </Link>
                    </Image>
                  </Row>
                ])
              })}
            </Container>
          </div>
          <div className="col-xs-1 col-lg-1 col-md-1"></div>
        </Body>
      </Wrapper>
    )
  }
}

export default NewsFeed