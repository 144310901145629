import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom'

const Wrapper = styled.div`
  position: absolute;
  height: 45px;
  right: 0px;
  top: 0px;
  cursor: pointer;
  transition: all 0.8s ease 0s;

  width: ${props => !!props.active ? '100%' : '45px'};
  background: ${props => !!props.active ? 'rgba(0, 0, 0, 0.4);' : 'transparent'};
`;

const IconWrapper = styled.div`
  position: absolute;
  width: 45px;
  height: 45px;
  right: 0;
  font-size: 25px;
  
  :hover {
    background-color: rgba(0,0,0,0.5);
    color: #fff;
  }

  i {
    padding-top: 10px;
  }
  
  i.fa-search {
    right: 10px;
    display: ${props => (!!props.active ? 'none' : 'inline-block')};
  }
  
  i.fa-times {
    right: 10px;
    display: ${props => (!!props.active ? 'inline-block' : 'none')};
  }
`;

const SearchWrapper = styled.div`
  padding: 8px 50px 8px 8px;
  flex-wrap: wrap;

  display: ${props => (!!props.active ? 'flex' : 'none')};
`;

const SearchInput = styled.input`
  width: calc(100% - 80px);
  height: 30px;
  line-height: 30px;
  margin-bottom: 0px;
  padding: 0 6px;
`;

const SearchButton = styled.div`
  height: 30px;
  background-color: #cecece;
  cursor: pointer;
  padding: 0px 12px;
  line-height: 30px;
`;

class HeaderSearch extends React.Component {
  state = {
    active: false,
    searchValue: ''
  }

  onIconClick = () => {
    this.setState(prevState => ({
      active: !prevState.active
    }));
  }

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      this.onSearch();
    }
  }

  onSearch = () => {
    const { history } = this.props;
    const { searchValue } = this.state;

    if (searchValue === '') {
      return;
    }

    history.push(`/search?t=${searchValue}`);
  }

  onSearchChange = (event) => {
    this.setState({
      searchValue: event.target.value
    });
  }
  
  render() {
    const { active, searchValue } = this.state;

    return (
      <Wrapper active={active}>
        <IconWrapper onClick={this.onIconClick} active={active}>
          <i className="fa fa-search"></i>
          <i className="fa fa-times"></i>
        </IconWrapper>
        <SearchWrapper active={active}>
          <SearchInput value={searchValue} onChange={this.onSearchChange} onKeyPress={this.handleKeyPress}/>
          <SearchButton onClick={this.onSearch}>
            <span>搜索</span>
          </SearchButton>
        </SearchWrapper>
      </Wrapper>
    )
  }
}


export default withRouter(HeaderSearch);