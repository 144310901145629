import React from 'react';
import { Map, Marker, NavigationControl, InfoWindow} from 'react-bmap'

import config from '../../config';

const renderInfoWindow = (lng, lat, selectedRetail, retail) => {
  return retail && selectedRetail === retail.id ? (
    <InfoWindow
      position={{ lng, lat }}
      text={`
            <div style="display: flex; align-items: center; width: 400px; height: 120px;">
              <div width="100px" style="flex-basis: 100px;">
                <img src=${config.imagePrefix}/shop/${retail.image} width="100%" />
              </div>
              <div style="margin-left: 10px;">
                <b>${retail.name}</b><br/>
                <div style="font-size: 14px;">${retail.address}</div>
                <div style="font-size: 14px;">营业时间: ${retail.openinghour}</div>
                <div style="font-size: 14px;">电话: ${retail.phone}</div>
              </div>
            </div>
          `}
      />
  ) : null;
}

const icon = new window.BMap.Icon('/images/map/markerGray.png' , new window.BMap.Size(42 / 2, 66 / 2), {
  imageOffset: new window.BMap.Size(0,0),
  anchor: new window.BMap.Size(21 / 2, 33 / 2),
  imageSize: new window.BMap.Size(21, 33)
});

const BaiduMap = ({ setMapRef, retailList, idx, onClick, selectedRetail, searchList }) => {
  const mapList = searchList ? searchList : retailList;

  return (
    <Map ref={setMapRef} center={{ lng: retailList[idx].longitude, lat: retailList[idx].latitude }} zoom="12" style={{height: '400px'}}>
      <NavigationControl />
      { mapList.map((retail) => {
        const lat = retail.latitude;
        const lng = retail.longitude;

        return ([
          renderInfoWindow(lng, lat, selectedRetail, retail),
          <Marker
            icon={icon}
            position={{ lng, lat }}
            events={{
              click: () => onClick(retail.id)
            }}
          />
        ]);
      })}
    </Map>
  )
}

export default BaiduMap;