export const categoryList = [
  {
    name: '墨水笔',
    imageSrc: './images/category/category_a1.jpg',
    to: '/productlist?id=1',
  },
  {
    name: '儿童画笔',
    imageSrc: './images/category/category_a2.jpg',
    to: '/productlist?id=8'
  },
  {
    name: '宝珠笔',
    imageSrc: './images/category/category_a3.jpg',
    to: '/productlist?id=2'
  },
  {
    name: '礼盒套装',
    imageSrc: './images/category/category_a4.jpg',
    to: '/productlist?id=9'
  },
  {
    name: '自动铅笔',
    imageSrc: './images/category/category_a5.jpg',
    to: '/productlist?id=4'
  },
  {
    name: '墨水/笔芯',
    imageSrc: './images/category/category_a6.jpg',
    to: '/productlist?id=7'
  },
  {
    name: '原珠笔',
    imageSrc: './images/category/category_a7.jpg',
    to: '/productlist?id=3'
  },
  {
    name: '配件',
    imageSrc: './images/category/category_a8.jpg',
    to: '/productlist?id=6'
  },
];

export const serviceList = [
  {
    name: '常见问题',
    to: '/customer-service?id=0',
  },
  {
    name: '企业选购',
    to: '/customer-service?id=1',
  },
  {
    name: '保养须知',
    to: '/customer-service?id=2',
  },
  {
    name: '刻字服务',
    to: '/customer-service?id=3',
  },
  {
    name: '维修服务',
    to: '/customer-service?id=4',
  },
  {
    name: '发货及配送',
    to: '/customer-service?id=5',
  },
];