import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import { media, MobileOnly, TabletAndDesktop } from '../../../../styles/breakpoints';
import ProductItem from '../ProductItem';
import { getFormattedDate } from '../../../../utils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
`;

const Header = styled.div`
  text-align: center;
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 20px;

  ${media.phone`
    font-size: 20px;
    padding-top: 30px;
    margin-bottom: 0px;
    border-top: solid 1px #ddd;
  `};
`;

const CloseBtn = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 10px 20px;
  cursor: pointer;
`;

const ContentWrapper = styled.div`
  margin: 30px 70px;

  ${media.phone`
    margin: 20px 0;
  `};
`;

const InforWrapper = styled.div`
  border: solid 1px #ccc;

  ${media.phone`
    border: none;
    border-bottom: solid 1px #ddd;
  `};
`;

const InfoHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  align-items: center;

  ${media.phone`
    flex-direction: column;
    text-align: left;
    align-items: unset;
  `};
`;

const OrderTime = styled.div`
  span {
    margin-left: 10px;
  }

  ${media.phone`
    margin-top: 10px;
  `};
`;

const SectionContainer = styled.div`
  margin-top: 30px;

  ${media.phone`
    margin-top: 20px;
  `};
`;

const OrderNum = styled.div`
  span {
    margin-left: 20px;
  }

  ${media.phone`
    span {
      margin-left: 30px;
    }
  `};
`;

const ProductWrapper = styled.ul`
  text-align: center;
  border: solid 1px #ccc;
  border-top: solid 3px #000;

  ${media.phone`
    border: none;
    border-bottom: solid 1px #ddd;
  `};
`;

const ProductHeader = styled.ul`
  display: flex;
  align-items: center;
  padding: 20px 0;
  border-bottom: solid 1px #ccc;

  ${media.phone`
    display: none;
  `};
`;

const ProductHeaderItem = styled.li`
  flex: 1;
  text-align: center;

  padding: ${props => props.padding ? props.padding : 'none'};
`;

const OrderDetailWrapper = styled.div`
  padding: 15px 20px;
  border-left: solid 1px #ccc;
  border-right: solid 1px #ccc;
  text-align: left;
  
  ${media.phone`
    padding: 15px 20px;
    border: none;
    border-bottom: solid 1px #ddd;
  `};
`;

const OrderDetailItem = styled.div`
  margin: 10px 0;
  display: flex;
`;

const OrderDetailTitle = styled.span`
  flex: 0 0 150px;
`;

const RemarkWrapper = styled.div`
  display: flex;
  flex-direction: column;

  font-size: 15px;

  ${media.phone`
    text-align: left;
    flex: 0 0 100px;
    padding-bottom: 20px;
    border-bottom: solid 1px #ddd;
  `};
`;

const Remark = styled.div`
  display: flex;
  margin-bottom: 5px;

  span {
    background-color: #efefef;
    padding: 10px 25px;
  }
  
  ${media.phone`
    span {
      background-color: unset;
      padding: 0;
    }
  `};
`;

const RemarkTitle = styled.span`
  text-align: center;
  flex: 0 0 140px;
  margin-right: 3px;
  
  ${media.phone`
    flex: 0 0 100px;
  `};
`;

const RemarkContent = styled.span`
  flex: 1;
`;

const PaymentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PaymentItem = styled.div`
  display: flex;
  justify-content: flex-end;
  text-align: left;
  padding-bottom: 10px;
`;

const PaymentDetail = styled.div`
  flex: 0 0 250px;
  margin-left: 30px;

  ${media.phone`
    flex: 0 0 80px;
    margin-left: 20px;
  `};
`;

const Footer = styled.div`
  margin-top: 30px;
`;

const Receipt = ({ orderDetail, onClose }) => {
  const {
    id, products, lastName, firstName, phone,
    address, createDate, totalPrice, remark1, transactionId
  } = orderDetail;

  return (
    <Wrapper>
      <CloseBtn onClick={onClose}>X</CloseBtn>
      <Header>付款收据</Header>
      <ContentWrapper>
        <InforWrapper>
          <InfoHeader>
            <OrderNum>
              订单号
              <span>{id}</span>
            </OrderNum>
            <OrderTime>
              下单时间 
              <span>{createDate ? getFormattedDate(createDate) : ''}</span>
            </OrderTime>
            <MobileOnly>
              <OrderTime>
                交易编号
                <span>{transactionId}</span>
              </OrderTime>
            </MobileOnly>
          </InfoHeader>
        </InforWrapper>
        <OrderDetailWrapper>
          <OrderDetailItem>
            <OrderDetailTitle>收件人姓名</OrderDetailTitle>
            <span>{lastName}{firstName}</span>
          </OrderDetailItem>
          <OrderDetailItem>
            <OrderDetailTitle>收件人联系电话</OrderDetailTitle>
            <span>{phone}</span>
          </OrderDetailItem>
          <OrderDetailItem>
            <OrderDetailTitle>收货人地址</OrderDetailTitle>
            <span>{address}</span>
          </OrderDetailItem>
        </OrderDetailWrapper>

        <ProductWrapper>
          <ProductHeader>
            <ProductHeaderItem padding="0 30px">商品名称</ProductHeaderItem>
            <ProductHeaderItem padding="0 30px">商品</ProductHeaderItem>
            <ProductHeaderItem>数量</ProductHeaderItem>
          </ProductHeader>

          { orderDetail && products && products.map((product, idx) => (
            <ProductItem key={idx} product={product} hidePrice />
          ))}

        </ProductWrapper>
        
        <SectionContainer>
          <RemarkWrapper>
            <Remark>
              <RemarkTitle>留言</RemarkTitle>
              <RemarkContent>{remark1}</RemarkContent>
            </Remark>
          </RemarkWrapper>
        </SectionContainer>

        <SectionContainer>
          <PaymentWrapper>
            <TabletAndDesktop>
              <PaymentItem>
                <span>交易编号</span>
                <PaymentDetail>{transactionId}</PaymentDetail>
              </PaymentItem>
            </TabletAndDesktop>
            <PaymentItem>
              <span>订单金额</span>
              <PaymentDetail>￥{totalPrice}</PaymentDetail>
            </PaymentItem>
          </PaymentWrapper>
        </SectionContainer>

        <Footer>如有任何查询, 请联络在线客服</Footer>
      </ContentWrapper>
    </Wrapper>
  )
};

export default withRouter(React.memo(Receipt));