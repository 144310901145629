import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import config from '../../../../config';
import { media } from '../../../../styles/breakpoints';

const Wrapper = styled.div`
  border-top: 1px solid #DDD;
  padding-top: 15px;
  margin-bottom: 20px;
`;

const Header = styled.div`
  text-align: left;
  font-size: 18px;
`;

const ProductWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;

const ProductItem = styled.li`
  margin-top: 20px;
  width: 33.333333%;

  ${media.phone`
    margin-top: 10px;
    width: 100%;
    border-top: solid 1px #ddd;
  `};
`;

const ProductImg = styled.img`
  width: 100%;
`;

const ProductName = styled.div`
  margin-top: 30px;
  text-align: left;
  font-size: 13px;
  color: #666;

  ${media.phone`
    margin-top: 10px;
  `};
`;

const RelatedSection = ({ relatedProducts }) => {
  return (
    <Wrapper>
      <Header>相关产品</Header>

      <ProductWrapper>
        { relatedProducts && relatedProducts.map((relatedProduct) => (
          <ProductItem>
            <Link to={`/product?id=${relatedProduct.productId}&colorId=${relatedProduct.colorId}`}>
              <ProductName>{relatedProduct.name}</ProductName>

              <ProductImg src={`${config.imagePrefix}/productbanner/${relatedProduct.image}`} />
            </Link>
          </ProductItem>
        ))}
      </ProductWrapper>
    </Wrapper>
  )  
};

export default React.memo(RelatedSection);