import React from 'react';
import styled from 'styled-components';
import { Link, withRouter } from 'react-router-dom';
import { Prompt } from 'react-router'

import { PageContainer, InputWithButton } from '../Common';
import { media, MobileOnly } from '../../styles/breakpoints';
import { withPopup, withAuth } from '../../context';
import { NAME_REGEX } from '../../constants';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 50px;
  background-color: #f2f2f2;

  ${media.phone`
    padding: 30px 10px;
  `};
`;

const SectionContainer = styled.div`
  margin-bottom: 40px !important;
`;

const AddressWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  min-height: 272px;
`;

const AddressGrid = styled.li`
  position: relative;
  flex: 0 0 calc(33.33333% - 4px);
  max-width: calc(33.33333% - 4px);
  height: 150px;
  padding: 15px;
  margin-bottom: 6px;

  cursor: pointer;
  background: #fff;
  border: ${props => `solid 1px ${props.selected ? '#446de9' : 'transparent'}`};

  &:nth-child(3n + 2) {
    margin-left: 6px;
    margin-right: 6px;
  }

  ${media.phone`
    flex: 0 0 calc(50% - 6px);
    max-width: calc(50% - 6px);
    margin-left: 0px !important;
    margin-right: 0px !important;

    &:nth-child(2n) {
      margin-left: 10px !important;
    }
  `};
`;

const AddressReceiver = styled.div`
  margin-bottom: 10px;
  font-size: 18px;

  overflow: hidden;
  text-overflow: ellipsis;
`;

const Address = styled.div`
  height: 40px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
`;

const Phone = styled.div`
  margin-bottom: 10px;
  font-size: 14px;
`;

const DefaultAddress = styled.div`
  position: absolute;
  bottom: 2px;
  right: 2px;
  font-size: 12px;
  padding: 2px 10px;
  color: #fff;
  background-color: ${props => props.selected ? '#446de9' : '#777'};
`;

const AddAddress = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const SocialWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const SocialGrid = styled.div`
  margin-bottom: 10px;
  flex: 0 0 100%;

  ${media.phone`
    flex: 0 0 100%;
  `};
`;

const SocialGridContent = styled.div`
  height: 100px;
  border: solid 1px #dadada;
  background-color: #e8e8e8;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 60px;
    margin: 20px;
  }

  ${media.phone`
    margin: 0;
  `};
`;

const SocialAction = styled.span`
  margin: 15px;
  cursor: pointer;
  color: #4270e8;
`;

const ChangePasswordBtn = styled.div`
  height: 100px;
  border: solid 1px #dadada;
  background-color: #e8e8e8;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const SubmitBtn = styled.div`
  width: 450px;
  padding: 10px;
  margin: 80px auto 20px;

  cursor: pointer;
  text-align: center;
  color: #fff;
  background-color: #000;

  ${media.phone`
    width: 100%;
  `};
`;

const LogoutBtn = styled.div`
  width: 450px;
  padding: 10px;
  margin: 20px auto;

  cursor: pointer;
  text-align: center;
  color: #fff;
  background-color: #000;

  ${media.phone`
    width: 100%;
  `};
`;

const DeleteAddressBtn = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 10px;
  font-size: 14px;
`;

class UserComponent extends React.PureComponent {
  state = {
    firstNameInput: '',
    lastNameInput: '',
    selectedAddress: 0,
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.firstNameInput && props.userData.firstName) {
      return {
        firstNameInput: props.userData.firstName,
        lastNameInput: props.userData.lastName,
        selectedAddress: props.userData.defaultAddressId
      }
    }

    return {};
  }

  renderSocialAccGrid(type, isBinded) {
    return (
      <SocialGrid>
        <SocialGridContent>
          <img src={`images/${type === 'weChat' ? 'weixin' : 'QQ'}.png`} alt=""/>
          <span>{isBinded ? '已绑定' : '未绑定'}</span>
          <Link to={`/user/${isBinded ? 'remove-binding' : 'add-binding'}/${type === 'weChat' ? 'wechat' : 'qq'}`}>
            <SocialAction>
              {isBinded ? '解绑' : '绑定'}
            </SocialAction>
          </Link>
        </SocialGridContent>
      </SocialGrid>
    )
  }

  onAddAddress = () => {
    const { history } = this.props;
    history.push('/user/add-address');
  }

  onUpdatePhone = () => {
    const { history } = this.props;
    history.push('/user/update-phone');
  }

  onUpdatePassword = () => {
    const { history } = this.props;
    history.push('/user/update-password');
  }

  onSubmit = () => {
    const { firstNameInput, lastNameInput, selectedAddress } = this.state;
    const { updateUserData, togglePopup } = this.props;

    if (!NAME_REGEX.test(firstNameInput) || !NAME_REGEX.test(lastNameInput)) {
      togglePopup({
        type: 'info',
        message: '姓名必需是中/英字, 不能有数字或其他符号'
      })

      return;
    }

    updateUserData && updateUserData({
      firstName: firstNameInput,
      lastName: lastNameInput,
      defaultAddressId: selectedAddress
    });
  }
  
  onFirstNameChange = (e) => {
    this.setState({
      firstNameInput: e.target.value
    })
  }

  onLastNameChange = (e) => {
    this.setState({
      lastNameInput: e.target.value
    })
  }

  onLogout = () => {
    const { auth, history } = this.props;

    auth.logout();
    history.push('/');
  }

  shouldBlockLeave = () => {
    const { firstNameInput, lastNameInput, selectedAddress } = this.state;
    const { userData } = this.props;
    const isEdited = firstNameInput !== userData.firstName || lastNameInput !== userData.lastName || selectedAddress !== userData.defaultAddressId;

    return isEdited ? '你有尚未储存的改动, 是否离开?' : true;
  }

  render() {
    const { firstNameInput, lastNameInput, selectedAddress } = this.state;
    const { userData: { phone, addresses, socialAcc }, deleteAddress } = this.props;

    return (
      <PageContainer title="个人资料">
        <Wrapper>
          <SectionContainer>
            <div className="row form-group">
              <div className="col-sm-6 col-12">
                <label>名</label>
                <input className="form-control" name="firstName" type="text" value={firstNameInput} onChange={this.onFirstNameChange} />
              </div>
              <div className="col-sm-6 col-12">
                <label>姓</label>
                <input className="form-control" name="lastName" type="text" value={lastNameInput} onChange={this.onLastNameChange} />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-sm-12 col-12">
                <label>电话号码</label>
                <InputWithButton
                  name="phone"
                  value={phone || ''}
                  inputProps={{disabled: true}}
                  btnText="更改号码" 
                  onBtnClicked={this.onUpdatePhone} />
              </div>
            </div>
          </SectionContainer>

          <SectionContainer className="row form-group">
            <div className="col-sm-12 col-12">
              <label>收货地址</label>
              <AddressWrapper>
                { addresses && addresses.map(({ name, address, phone, id }) => {
                  const selected = id === selectedAddress;
                    return (
                      <AddressGrid key={id} selected={selected} onClick={() => {this.setState({ selectedAddress: id })}}>
                        <AddressReceiver>{name}</AddressReceiver>
                        <Phone>{phone}</Phone>
                        <Address>{address}</Address>

                        <DefaultAddress selected={selected}>
                          {selected ? '默认地址' : '设为默认'}
                        </DefaultAddress>
                        <DeleteAddressBtn onClick={() => deleteAddress(id)}>
                          X
                        </DeleteAddressBtn>
                      </AddressGrid>
                  )})
                }
                { (!addresses || (addresses && addresses.length < 5)) && (
                  <AddressGrid onClick={this.onAddAddress}>
                    <AddAddress>
                      <span>添加地址</span>
                    </AddAddress>
                  </AddressGrid>
                )}
              </AddressWrapper>
            </div>
          </SectionContainer>

          <SectionContainer className="row form-group">
            <div className="col-sm-12 col-12">
              <label>绑定帐号</label>
              <SocialWrapper>
                { this.renderSocialAccGrid('weChat', socialAcc && socialAcc.weChat) }

                {/* { this.renderSocialAccGrid('qq', socialAcc && socialAcc.qq) } */}
              </SocialWrapper>
            </div>
          </SectionContainer>

          <SectionContainer className="row form-group">
            <div className="col-12">
              <ChangePasswordBtn onClick={this.onUpdatePassword}>更改帐号登录密码</ChangePasswordBtn>
            </div>
          </SectionContainer>

          <SubmitBtn onClick={this.onSubmit}>
            <span>更新信息</span>
          </SubmitBtn>

          <MobileOnly>
            <LogoutBtn onClick={this.onLogout}>
              <span>登出</span>
            </LogoutBtn>
          </MobileOnly>

          <Prompt message={() => this.shouldBlockLeave()} />
        </Wrapper>
      </PageContainer>
    )
  }
};

export default withRouter(
  withPopup(
    withAuth(UserComponent)
  )
);