import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';

import config from '../../config';
import OrderComponent from './OrderComponent';

class OrderContainer extends React.Component {
  state = {
    orderData: []
  }

  async componentDidMount() {
    const orderData = await this.getPageData() || [];

    this.setState({ orderData });
  }

  getPageData() {
    const fetchUrl = `${config.serverEndPoint}/orderPage`;

    return axios.get(fetchUrl)
      .then(result => result.data)
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    const { orderData } = this.state;

    return (
      <OrderComponent
        orderData={orderData} />
    )
  }
};

export default withRouter(OrderContainer);

