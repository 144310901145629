import React from 'react';
import styled from 'styled-components';
import { media } from '../../styles/breakpoints';

const Title = styled.h2`
font-size: 20pt;
padding-bottom: 5%;
`
const Subtitle = styled.h2`
font-size: 20pt;
`
const Content = styled.div`
text-align: justify;
color: gray;
  ${media.tablet`
    padding:20px
  `};

  ${media.phone`
    padding:20px
  `}; 
`
const Body = styled.p`
padding-bottom:5%;
margin:25px;
`

const Wrapper = styled.div`
`

const Design = () => {
    return (
        <Wrapper className="row">
            <div className="col-xs-1 col-lg-1 col-md-1"></div>
            <div className="col-xs-10 col-lg-10 col-md-10">
                <Content>
                    <Title><b>设计</b></Title>
                    <br /><br />
                    <Body className="row">
                        <div className="col-lg-8 col-md-8" >
                            <Subtitle>个性</Subtitle>
                            <br />
                            Lamy产品不甘平凡，只求突破。它凭着先进的工业技术与一枝独秀的设计，誓要超越市场上的竞争对手。毫无疑问，Lamy产品个性独特鲜明，理所当然地带动着市场的趋势。
                </div>
                        <div className="col-lg-4 col-md-4">
                            <img src="./images/brand/journalist_lamy2000-klein_1_ger.jpg" alt="" />
                        </div>
                    </Body>
                    <hr />
                    <Body className="row">
                        <div className="col-lg-8 col-md-8" >
                            <Subtitle>功能性</Subtitle>
                            <br />
                            Lamy的设计讲求增加产品的实用性，让产品更具智慧，充分发挥应有的功能，并以舒适度与人体工学为前题。每项设计都必须反映Lamy产品在功能、技术与物料上的品质，营造出满足视觉感官的造型，同时表达产品的卓越技术水平和优秀品质。
                </div>
                        <div className="col-lg-4 col-md-4">
                            <img src="./images/brand/kaufmann_4pen-klein_3_ger.jpg" alt="" />
                        </div>
                    </Body>
                    <hr />
                    <Body className="row">
                        <div className="col-lg-8 col-md-8" >
                            <Subtitle>传意功能</Subtitle>
                            <br />
                            每件Lamy产品都是传达资讯的工具，为将要使用产品的人传情达意，其设计必须能够不言而喻地针对不同的质量、意念、目标顾客和价格，清楚表达产品在市场上的目标定位，因为单凭不同产品在视觉上的意念和个性比较，便足以构成天壤之别。
                </div>
                        <div className="col-lg-4 col-md-4">
                            <img src="./images/brand/fotografin_pico-klein_4_ger.jpg" alt="" />
                        </div>
                    </Body>
                    <hr />
                    <Body className="row">
                        <div className="col-lg-8 col-md-8" >
                            <Subtitle>一致性</Subtitle>
                            <br />
                            每件新产品的造型及技术质量都必须配合Lamy的方针，旨为秉承与提升品牌的形象。Lamy产品的「一致性」意味着即使产品有不同的变化以符合目标顾客的要求，它们仍是拥有紧密的共同特性。正是这个一致的设计特质打造了公司的身份地位，也是Lamy形象的主要骨干。
                </div>
                        <div className="col-lg-4 col-md-4">
                            <img src="./images/brand/student_al_star-klein_2_ger.jpg" alt="" />
                        </div>
                    </Body>
                    <hr />
                    <Body className="row">
                        <div className="col-lg-8 col-md-8" >
                            <Subtitle>环保性能</Subtitle>
                            <br />
                            环境问题必然也对Lamy产品的基本生产技术和设计概念产生影响。我们要求将物料物尽其用、采用节省能源的生产程序，并要求所有产品及包装的物料都是可再用的。
                </div>
                    </Body>
                    <hr />
                </Content>
            </div>
            <div className="col-xs-1 col-lg-1 col-md-1"></div>
        </Wrapper>


    )
}

export default Design