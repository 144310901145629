import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { ORDER_STATUS } from '../../../../constants';
import config from '../../../../config';
import { getFormattedDate } from '../../../../utils';

const Wrapper = styled.li`
  border: solid 1px #e2e2e2;
  margin-bottom: 30px;
  cursor: pointer;
`;

const HeaderRow = styled.div`
  padding: 20px;
  border-bottom: solid 1px #e2e2e2;
`;

const DetailRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  font-size: 15px;

  span {
    margin-left: 15px;
    width: 150px;
  }
`;

const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  align-items: center;
`;

const ProductItem = styled.div`
  width: 100%;
  display: flex;
  padding: 50px 0;
  align-items: center;

  &:not(:first-child) {
    border-top: solid 1px #ccc;
  }
`;

const ProductImage = styled.div`
  flex: 0 0 200px;
  margin-right: 50px;

  img {
    width: 100%;
  }
`;

const ProductDetail = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 50px;

  img {
    width: 130px;
  }

  span {
    margin-top: 10px;
  }
`;

const Price = styled.div`
  flex: 1;
  margin: 0 10px;

  span {
    margin-left: 30px;
  }
`;

const renderProductItem = ({ colorId, id, image, name, price, quantity, seriesId, seriesImage }) => {
  return (
    <ProductItem key={`${id}-${colorId}`}>
      <ProductImage>
        <img src={`${config.imagePrefix}/productbanner/${image}`} alt="" />
      </ProductImage>
      <ProductDetail>
        <img src={`${config.imagePrefix}/productseries/${seriesImage}`} alt="" />
        <span>{name}</span>
      </ProductDetail>
      <Price>
        ￥{price}
        <span>X {quantity}</span>
      </Price>
    </ProductItem>
  )
};

const OrderItem = ({ order }) => {
  const { id, status, createDate, totalPrice, products } = order;

  return (
    <Wrapper>
      <Link to={`/orderDetail?id=${id}`}>
        <HeaderRow>
          <div>{status ? ORDER_STATUS[status] : ''}</div>
          <DetailRow>
            <div>
              订单号
              <span>{id}</span>
              <span>|</span>
              <span>{getFormattedDate(createDate)}</span>
            </div>
            <span><b>订单金额: ￥{totalPrice}</b></span>
          </DetailRow>
        </HeaderRow>

        <ProductWrapper>
          { products && products.map((product) => renderProductItem(product))}
        </ProductWrapper>
      </Link>
    </Wrapper>
  )
}

export default React.memo(OrderItem);