import React from 'react';
import styled from 'styled-components';
import axios from 'axios';

import { ProductFooter } from '../Common';
import config from '../../config';

const Wrapper = styled.section`
  background-color: #fff;
  text-align: left;
  color: gray;
  
`;

const Body = styled.div`
  margin : 5% 0 0 0;
  padding : 50px;
`;

const Img = styled.img`
  width: 100%;
`;

const Content = styled.div`
  padding : 5px 0px 15px 0px;
  text-align: left;
`;

 
const Title = styled.div`
  font-size: 24pt;
  padding: 5px 0px 15px 0px;
`;

class CorpBuyingComponent extends React.Component {
  state = {
    infoData: ''
  }
  
  async componentDidMount() {
    const result = await this.getContactData();

    this.setState({ infoData: result ? result.value : '' });
  }

  async getContactData() {
    const fetchUrl = `${config.serverEndPoint}/corpBuyingPage`;

		try {
			const result = await axios.get(fetchUrl).then(result => result.data);

      return result;
		} catch (err) {
			console.log(err);
		}
  }

  render() {
    const { infoData } = this.state;

    return (
      <Wrapper>  
        <Img src="./images/customerservice/company.jpg" alt=""/>
        <div className="row">
          <div className="col-xs-1 col-lg-1 col-md-1"></div>
          <div className="col-xs-10 col-lg-10 col-md-10">
            <Body>
              <Title>企业选购</Title>
              <hr/>
              <Content>
              { infoData.split('\n').map((content, idx) => (
                <div key={idx}>
                  <label className="inputlabel">{content}</label>
                </div>
              ))}
              </Content>
            </Body>
            <ProductFooter/>
          </div>
          <div className="col-xs-1 col-lg-1 col-md-1"></div>
        </div>
        
      </Wrapper>
    );
  }

}



export default CorpBuyingComponent;
