import React from 'react';
import styled from 'styled-components';

import { CaptchaValidateForm, PageContainer } from '../../Common';

const MainContaineWrapper = styled.div`
  background-color: #f2f2f2;
`;

const MainContainer = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f2f2f2;
`;

const Row = styled.div`
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
  width: 100%;
`;

const Button = styled.button`
  margin: 0 10px;
  width: 100%;
  font-weight: 600;
  border: solid 1px #dadada;
  padding: 10px;
  background-color: #ebecf0;
  cursor: pointer;
`;

const RemoveSocialPageComponent = ({ type, history, removeSocialBinding }) => {
  const onBackBtnClicked = React.useCallback(() => {
    history.push('/user');
  }, [history]);

  return (
    <PageContainer title="个人资料" backBtnLink="/user">
      <MainContaineWrapper>
        <CaptchaValidateForm authHeader="解除绑定" formHeader="解除绑定">
          {({ pin }) => (
            <MainContainer>
              <div>
                是否要解除綁定的{type === 'wechat' ? '微信' : 'QQ'}
              </div>
              <Row>
                <Button onClick={onBackBtnClicked}>再想想</Button>
                <Button onClick={removeSocialBinding}>确定解除</Button>
              </Row>
            </MainContainer>
          )}
        </CaptchaValidateForm>
      </MainContaineWrapper>
    </PageContainer>
  )
};

export default RemoveSocialPageComponent;