// single store
let store = {
  getState: () => ({}),
  dispatch: () => {},
}
const listeners = {};
export const middleware = ({ getState, dispatch }) => {
  store.getState = getState;
  store.dispatch = dispatch;
  return next => action => {
    // reducer first
    const result = next(action);
    (listeners[action.type] || []).forEach(cb => {
      // side thread
      setTimeout(() => cb(action), 0);
    });
    return result;
  }
}

middleware.run = (saga) => {
  saga();
}

export const select = selector => new Promise(resolve => resolve(selector(store.getState())));

export const put = action => new Promise(resolve => resolve(store.dispatch(action)));

export const takeEvery = (pattern, cb) => {
  listeners[pattern] = [ ...(listeners[pattern] || []), cb ];
}