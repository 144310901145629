import React from 'react';
import styled from 'styled-components';
import Modal from '@material-ui/core/Modal';
import { media } from '../../styles/breakpoints';

const Content = styled.div`
.row{
    margin:0px
}
`

const Title = styled.h2`
font-size: 20pt;
padding-bottom: 5%;
`

const Subtitle = styled.h3`
font-size: 16pt;
`

const Wrapper = styled.div`
text-align: justify;
margin:5% 0 10% 0;
color: gray;

p{
    font-size:12pt;
    line-height:20pt;
}

  ${media.tablet`
    padding:20px
  `};

  ${media.phone`
    padding:20px
  `}; 

`

function getModalStyle() {
    const top = 0 ;
  const left = 25 ;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(${top}%, ${left}%)`,
    display: 'inline-block'
  };

}

const Img = styled.img`
width:100%
`

class Quality extends React.Component {
    state = {
        open1: false,
        open2: false,
        open3: false,
        open4: false,
    }
    handleOpen1 = () => {
        this.setState({ open1: true });
    };
    handleOpen2 = () => {
        this.setState({ open2: true });
    };
    handleOpen3 = () => {
        this.setState({ open3: true });
    };
    handleOpen4 = () => {
        this.setState({ open4: true });
    };
    handleClose1 = () => {
        this.setState({ open1: false });
    };
    handleClose2 = () => {
        this.setState({ open2: false });
    };
    handleClose3 = () => {
        this.setState({ open3: false });
    };
    handleClose4 = () => {
        this.setState({ open4: false });
    };
    render() {
        return (
            <Content>
                <div className="row">
                    <img className="col-lg-3 col-md-3 col-sm-6 col-xs-12" onClick={this.handleOpen1} id="artImage1" src="./images/brand/img_quality_1t.jpg" alt=""></img>
                    <img className="col-lg-3 col-md-3 col-sm-6 col-xs-12" onClick={this.handleOpen2} id="artImage1" src="./images/brand/img_quality_2t.jpg" alt=""></img>
                    <img className="col-lg-3 col-md-3 col-sm-6 col-xs-12" onClick={this.handleOpen3} id="artImage1" src="./images/brand/qualitaet_b3a_ger.jpg" alt=""></img>
                    <img className="col-lg-3 col-md-3 col-sm-6 col-xs-12" onClick={this.handleOpen4} id="artImage1" src="./images/brand/qualitaet_b4a_ger.jpg" alt=""></img>
                </div>
                
                <Modal open={this.state.open1} onClose={this.handleClose1}                >
                    <div style={getModalStyle()}>
                        <Img id="artImage1" src="./images/brand/img_quality_1.jpg" alt=""/>
                    </div>
                </Modal>
                <Modal open={this.state.open2} onClose={this.handleClose2}                >
                    <div style={getModalStyle()}>
                        <Img id="artImage1" src="./images/brand/img_quality_2.jpg" alt=""/>
                    </div>
                </Modal>
                <Modal open={this.state.open3} onClose={this.handleClose3}                >
                    <div style={getModalStyle()}>
                        <Img id="artImage1" src="./images/brand/qualitaet_b3_ger.jpg" alt=""/>
                    </div>
                </Modal>
                <Modal open={this.state.open4} onClose={this.handleClose4}                >
                    <div style={getModalStyle()}>
                        <Img id="artImage1" src="./images/brand/qualitaet_b4_ger.jpg" alt=""/>
                    </div>
                </Modal>
                <br />
                <div className="row">
                    <div className="col-xs-1 col-lg-1 col-md-1"></div>
                    <div className="col-xs-10 col-lg-10 col-md-10">
                        <Wrapper>
                            <Title>质量</Title>
                            <br />
                            <Subtitle>2009年4月</Subtitle>
                            <br />
                            <p>国家轻工业自来水笔圆珠笔质量监督检测中心"给LAMY颁发质量合格证书</p>
                            <br />
                            <br />
                            <Subtitle>LAMY生产准则：五大检定程序</Subtitle>
                            <br />
                            <p>每件LAMY书写工具的制作，都并非只为满足设计师展示创意的意欲，也不是纯粹属於工程师或技师的创作；其技术特性与造型都须要针对使用者的要求而决定，与LAMY生产的最基本理念同出一彻。</p>
                            <br />
                            <br />
                        </Wrapper>
                    </div>
                    <div className="col-xs-1 col-lg-1 col-md-1"></div></div>
            </Content>
        )
    }

}

export default Quality