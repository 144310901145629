import HomePage from '../app/HomePage';
import ContactUsPage from '../app/ContactUsPage';
import CategoryPage from '../app/CategoryPage';
import ProductListPage from '../app/ProductList/Product';
import ProductSeriesPage from '../app/ProductList/ProductSeries';
import ProductOptionPage from '../app/ProductList/ProductOption';
import ProductPage from '../app/ProductPage';
import mapPage from '../app/MapPage'
import CompanyPage from '../app/CompanyPage'
import disclaimerPage from '../app/DisclaimerPage'
import ServicePage from '../app/ServicePage'
import BrandPage from '../app/BrandPage';
import DesignPage from '../app/DesignPage';
import AboutPage from '../app/AboutPage';
import LoginPage from '../app/Login';
import RegisterPage from '../app/RegisterPage';
import { LoginRedirectPage } from '../app/User';
import NewsFeedDetailPage from '../app/AboutPage/NewsFeedDetail';
import SearchPage from '../app/SearchPage';
import communityPage from '../app/CommunityPage';
import PressDetailPage from '../app/AboutPage/PressDetail';
import ForgetPasswordPage from '../app/ForgetPasswordPage';
import LicensePage from '../app/LicensePage';
import RecruitmentPage from '../app/RecruitmentPage';

export const routes = [
  {
    path: "/",
    component: HomePage,
  },
  {
    path: "/contact-us",
    component: ContactUsPage,
  },
  {
    path: "/disclaimer",
    component: disclaimerPage,
  },
  {
    path: "/category",
    component: CategoryPage,
  },
  {
    path: "/productlist",
    component: ProductListPage,
  },
  {
    path: "/product",
    component: ProductPage,
  },
  {
    path: "/about",
    component: AboutPage,
  },
  {
    path: "/about/detail/:id",
    component: NewsFeedDetailPage,
  },
  {
    path: "/about/press/:id",
    component: PressDetailPage,
  },
  {
    path: "/brand",
    component: BrandPage,
  },
  {
    path: "/design",
    component: DesignPage,
  },
  {
    path: "/customer-service",
    component: ServicePage,
  },{
    path: "/productseries",
    component: ProductSeriesPage,
  },
  {
    path: "/productoption",
    component: ProductOptionPage,
  },
  {
    path: "/company",
    component: CompanyPage,
  },
  {
    path: "/map",
    component: mapPage,
  },
  {
    path: "/community",
    component: communityPage,
  },
  {
    path: "/login",
    component: LoginPage,
  },
  {
    path:"/third-party-login/:type",
    component: LoginRedirectPage
  },
  {
    path: "/register",
    component: RegisterPage,
  },
  {
    path: "/search",
    component: SearchPage,
  },
  {
    path: "/forget-password",
    component: ForgetPasswordPage,
  },
  {
    path: "/license",
    component: LicensePage,
  },
  {
    path: "/recruitment",
    component: RecruitmentPage,
  }
];