import {
  User, AddAddressPage, RemoveSocialPage,
  EditPasswordPage, EditPhonePage, AddSocialBindingPage,
  BindingRedirectPage
} from '../app/User';
import Order from '../app/Order'
import OrderDetail from '../app/OrderDetail'
import OrderReturnRequest from '../app/OrderReturnRequest';
import Cart from '../app/Cart';
import WechatPayPage from '../app/WechatPayPage';
import CheckoutSuccessPage from '../app/CheckoutSuccessPage';
import ForceUpdateUserDataPage from '../app/ForceUpdateUserDataPage';

export const privateRoutes = [
  {
    path: "/user",
    component: User,
    subRoutes:[
      {
        path:"/add-address",
        component: AddAddressPage
      },
      {
        path:"/add-binding/:type",
        component: AddSocialBindingPage
      },
      {
        path:"/third-party-binding/:type",
        component: BindingRedirectPage
      },
      {
        path:"/remove-binding/:type",
        component: RemoveSocialPage
      },
      {
        path:"/update-password",
        component: EditPasswordPage
      },
      {
        path:"/update-phone",
        component: EditPhonePage
      },
      {
        path: "/update-user-data",
        component: ForceUpdateUserDataPage
      }
    ]
  },
  {
    path: "/order",
    component: Order,
  },
  {
    path: "/orderDetail",
    component: OrderDetail,
  },
  {
    path: "/order-return",
    component: OrderReturnRequest,
  },
  {
    path: "/cart",
    component: Cart,
    subRoutes:[
      {
        path:"/wechatPay",
        component: WechatPayPage
      },
      {
        path:"/success",
        component: CheckoutSuccessPage
      },
    ]
  },
];