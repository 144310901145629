import React from 'react';
import styled from 'styled-components';
import { withRouter, Link } from 'react-router-dom';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import Countdown from 'react-countdown-now';
import Dialog from '@material-ui/core/Dialog';

import { ORDER_STATUS, ORDER_EXPIRE_TIME, COURIER_ID_MAP } from '../../constants';
import { PageContainer } from '../Common';
import { media } from '../../styles/breakpoints';
import { ProductItem, Receipt } from './components';
import { getFormattedDate } from '../../utils';
import { withPopup } from '../../context';

const MAX_ALLOWED_REQUEST_TIME = 10 * 24 * 60 * 60 * 1000;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${media.phone`
    padding: 30px 10px;
  `};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 26px;
  margin-bottom: 30px;

  ${media.phone`
    font-size: 20px;
    margin-bottom: 20px;
  `};
`;

const HeaderLeft = styled.div`
  font-weight: 600;

  button {
    background: none;
    padding-right: 10px;
  }
`;

const HeaderRight = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  min-width: 240px;
  min-height: 102px;
  
  div {
    margin-bottom: 10px;
  }

  span {
    padding-left: 20px;
  }

  ${media.phone`
    min-width: 150px;
    min-height: 65px;
  `};
`;

const OrderTime = styled.div`
  ${media.phone`
    display: none;
  `};
`;

const Status = styled.div`
  font-size: 20px;

  ${media.phone`
    font-size: 16px;
  `};
`;

const SectionContainer = styled.div`
  margin-bottom: 30px;

  ${media.phone`
    margin-bottom: 20px;
  `};
`;

const OrderNum = styled.div`
  padding-bottom: 8px;
  border-bottom: solid 3px #000;

  span {
    margin-left: 20px;
  }
`;

const ProductHeader = styled.ul`
  display: flex;
  align-items: center;
  padding: 20px 0;
  border-bottom: solid 1px #ccc;
`;

const ProductHeaderItem = styled.li`
  flex: 1;
  text-align: center;

  padding: ${props => props.padding ? props.padding : 'none'};
`;

const OrderDetailWrapper = styled.div`
  padding: 20px 35px;
  border: solid 1px #ccc;
  
  ${media.phone`
    border: none;
    padding: 0px 20px;
  `};
`;

const OrderDetailItem = styled.div`
  margin: 10px 0;
  display: flex;
  font-size: 14px;
`;

const OrderDetailTitle = styled.span`
  flex: 0 0 150px;

  ${media.phone`
    flex: 0 0 120px;
  `};
`;

const PriceWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 15px;
  margin-bottom: 10px;

  span {
    margin-left: 20px;
  }
`;

const RemarkWrapper = styled.div`
  display: flex;
  flex-direction: column;

  font-size: 15px;
`;

const Remark = styled.div`
  display: flex;
  margin-bottom: 5px;

  span {
    background-color: #efefef;
    padding: 10px 25px;
  }
`;

const RemarkTitle = styled.span`
  text-align: center;
  flex: 0 0 140px;
  margin-right: 3px;
`;

const RemarkContent = styled.span`
  flex: 1;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    margin-left: 20px;
    background-color: #000;
    padding: 7px 35px;
    color: #fff;
    font-size: 14px;
  }
`;

const EnhancedDialog = styled(Dialog)`
  & > div > div {
    margin: 0px;
    max-height: 100%;
  }
`;


const OrderDetailComponent = ({ orderDetail, history, togglePopup }) => {
  const {
    id, status, products, lastName, firstName, phone,
    address, createDate,
    courierId, courierNo, totalPrice, remark1, remark2,
    paymentType, transactionId, token, sentdate, transportFee
  } = orderDetail;

  const [isReceiptOpen, setIsReceiptOpen] = React.useState(false);

  const onPaidBtnClicked = React.useCallback(() => {
    if (paymentType === 'WXPAY' || paymentType === 'WXPAYH5') {
      history.push(`/cart/wechatPay?id=${id}`);
    } else if (paymentType === 'ALIPAY') {
      window.location = token;
    }
  }, [paymentType, id, history]);

  const onReceiptClose = React.useCallback(() => {
    setIsReceiptOpen(false);
  }, [setIsReceiptOpen]);

  const onReturnRequestBtnClick = React.useCallback(() => {
    const sentTs = new Date(sentdate).getTime();
    const currTs = new Date().getTime();

    if ((sentTs + MAX_ALLOWED_REQUEST_TIME) < currTs) {
      togglePopup({
        type: 'info',
        message: '出货10天內才能申請退/換货'
      })
    } else {
      history.push(`/order-return?id=${id}&courierNo=${courierNo}`)
    }

  }, [history, sentdate]);

  return (
    <PageContainer>
      <Wrapper>
        <Header>
          <HeaderLeft>
            <Link to={'/order'}>
              <button>{'<'}</button>
            </Link>
            订单详情
          </HeaderLeft>
          <HeaderRight>
            <Status>
              {status ? ORDER_STATUS[status] : <span>&nbsp;</span>}
            </Status>
            <OrderTime>
              下单时间: 
              <span>{createDate ? getFormattedDate(createDate) : ''}</span>
            </OrderTime>
            { status === 'PENDING' && (
              <div>
                有效时间:
                <Countdown
                  date={new Date(createDate).getTime() + ORDER_EXPIRE_TIME}
                  renderer={({ hours, minutes, seconds }) => <span>{hours}:{minutes}:{seconds}</span>} />
              </div>
            )}
            { (status === 'PENDING' || status === 'PAID' || status === 'SENT' || status === 'RETURN') && (
              <div>
                付款选项: 
                <span>{ paymentType === 'WXPAY' || paymentType === 'WXPAYH5' ? '微信' : '支付宝' }</span>
              </div>
            )}
          </HeaderRight>
        </Header>

        <SectionContainer>
          <OrderNum>
            订单号
            <span>
              {id}
            </span>
          </OrderNum>
          <ProductHeader>
            <ProductHeaderItem padding="0 30px">商品名称</ProductHeaderItem>
            <ProductHeaderItem padding="0 30px">商品</ProductHeaderItem>
            <ProductHeaderItem>数量</ProductHeaderItem>
            <ProductHeaderItem>订单金额</ProductHeaderItem>
          </ProductHeader>
          <CSSTransitionGroup
            transitionName="product-animation"
            transitionEnterTimeout={300}
            transitionLeaveTimeout={300}>
            { orderDetail && products && products.map((product, idx) => (
              <ProductItem key={idx} product={product} />
            ))}
            { transportFee && transportFee > 0 && <ProductItem product={{
              name: '运费',
              quantity: 1,
              price: transportFee
            }} /> }
          </CSSTransitionGroup>

        </SectionContainer>

        <SectionContainer>
          <OrderDetailWrapper>
            { transactionId && <OrderDetailItem>
              <OrderDetailTitle>交易编号</OrderDetailTitle>
              <span>{transactionId}</span>
            </OrderDetailItem>
            }
            <OrderDetailItem>
              <OrderDetailTitle>收件人姓名</OrderDetailTitle>
              <span>{lastName}{firstName}</span>
            </OrderDetailItem>
            <OrderDetailItem>
              <OrderDetailTitle>收件人联系电话</OrderDetailTitle>
              <span>{phone}</span>
            </OrderDetailItem>
            <OrderDetailItem>
              <OrderDetailTitle>收货人地址</OrderDetailTitle>
              <span>{address}</span>
            </OrderDetailItem>
            <OrderDetailItem>
              <OrderDetailTitle>物流</OrderDetailTitle>
              <span>{courierId && courierId !== '0' ? COURIER_ID_MAP[courierId] : '未知'}</span>
            </OrderDetailItem>
            <OrderDetailItem>
              <OrderDetailTitle>运单号</OrderDetailTitle>
              <span>{courierNo ? courierNo : '未知'}</span>
            </OrderDetailItem>
          </OrderDetailWrapper>
        </SectionContainer>

        <SectionContainer>
          <PriceWrapper>
            {products && products.length ? products.length : '0'} 商品-总金额
            <span>￥{totalPrice}</span>
          </PriceWrapper>
            <ButtonWrapper>
              { status === 'PENDING' && <button onClick={onPaidBtnClicked}>付款</button> }
              { (status === 'PAID' || status === 'SENT') && <button onClick={() => setIsReceiptOpen(true)}>付款收据</button> }
              { (status === 'SENT') &&
                <button onClick={onReturnRequestBtnClick}>
                  退货/換货
                </button>
              }
            </ButtonWrapper>
        </SectionContainer>

        <SectionContainer>
          <RemarkWrapper>
            <Remark>
              <RemarkTitle>留言</RemarkTitle>
              <RemarkContent>{remark1}</RemarkContent>
            </Remark>
            <Remark>
              <RemarkTitle>客服回复</RemarkTitle>
              <RemarkContent>{remark2}</RemarkContent>
            </Remark>
          </RemarkWrapper>
        </SectionContainer>

      </Wrapper>

      <EnhancedDialog open={isReceiptOpen} onClose={onReceiptClose} maxWidth="lg">
        <Receipt orderDetail={orderDetail} onClose={onReceiptClose} />
      </EnhancedDialog>
    </PageContainer>
  )
};

export default withRouter(
  withPopup(
    React.memo(OrderDetailComponent)
  )
);