import React from 'react';
import axios from 'axios';

import { getIntQueryStringByKey, withScrollToTop } from '../../../utils';
import config from '../../../config';
import ProductListPageComponent from './ProductListPageComponent';
import { withCart } from '../../../context';

class ProductListPageContainer extends React.Component {
  state = {
    pageTitle: '',
    bannerItems: [],
    productItems: [],
  }

  async componentDidMount() {
    this.loadPageData();
  }

  componentDidUpdate(prevProps) {
    const prevId = getIntQueryStringByKey(prevProps.location.search, 'id', 1);
    const currId = getIntQueryStringByKey(this.props.location.search, 'id', 1);

    if (prevId !== currId) {
      this.setState({ pageTitle: '', bannerItems: [], productItems: [] });

      this.loadPageData();
    }
  }

  loadPageData() {
    const { location } = this.props;
    const categoryId = getIntQueryStringByKey(location.search, 'id', 1);

    const fetchUrl = `${config.serverEndPoint}/productListPage?categoryId=${categoryId}`;

    return axios.get(fetchUrl)
      .then(result => result.data)
      .then(result => {
        const { pageTitle, bannerItems: bannerImages, productItems } = result ? result : { pageTitle: '', bannerItems: [], productItems: [] };

        let bannerItems = [];
        bannerImages.forEach(banner => {
          bannerItems.push({
            image: `${config.imagePrefix}/category/${banner}`
          })
        });
    
        this.setState({ pageTitle, bannerItems, productItems });
      })
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    const { pageTitle, bannerItems, productItems } = this.state;
    const { location, cart } = this.props;
    const type = getIntQueryStringByKey(location.search, 'type', 0);
    const categoryId = getIntQueryStringByKey(location.search, 'id', 1);

    let filteredProductItems = type ? productItems.filter(product => product[`option${type}`] === 'Y') : productItems;
    
    return (
      <ProductListPageComponent
        categoryId={categoryId}
        pageTitle={pageTitle}
        bannerItems={bannerItems}
        productItems={filteredProductItems}
        addItemToCart={cart.addItemToCart} />
    )
  }
};

export default withScrollToTop(withCart(ProductListPageContainer), ['id']);

