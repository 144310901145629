import React from 'react';
import axios from 'axios';

import config from '../../config';
import { withAuth } from '../auth';

const { Consumer, Provider } = React.createContext();

class _CartProvider extends React.Component {
  state = {
    items: []
  }

  constructor(props) {
    super(props);

    this.ctx = {
      addItemToCart: this.addItemToCart,
      getCartItemCount: this.getCartItemCount,
      getCartItems: this.getCartItems,
    }

    if (props.auth.isAuthed()) {
      this.updateCartItem();
    }
  }

  addItemToCart = async (productId, colorId, quantity) => {
    const updateUrl = `${config.serverEndPoint}/shopping-cart`;

    try {
      await axios.post(updateUrl, {
        productId, colorId, quantity
      });
    } catch(e) {
      console.log(e);

      return false;
    }

    this.updateCartItem();

    return true;
  }

  getCartItemCount = () => {
    return this.state.items.length;
  }

  getCartItems = () => {
    return this.state.items;
  }

  updateCartItem = async () => {
    const fetchUrl = `${config.serverEndPoint}/shopping-cart`;

    try {
      const items = await axios.get(fetchUrl).then(result => result.data);

      this.setState({ items });
    } catch(e) {
      console.log(e);
    }
  }

  // Clear cart item for logout
  clearCartItem = () => {
    this.setState({ items: [] });
  }

  render() {
    return (
      <Provider value={{
        clearCartItem: this.clearCartItem,
        updateCartItem: this.updateCartItem,
        addItemToCart: this.addItemToCart,
        items: this.state.items
      }}>
        {this.props.children}
      </Provider>
    )
  }
}

const withCart = (Component) => (props) => (
  <Consumer>
    { cart => <Component cart={cart} {...props} /> }
  </Consumer>
);

const CartProvider = withAuth(_CartProvider);
  
export {
  CartProvider,
  Consumer as CartConsumer,
  withCart
}