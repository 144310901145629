import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { media } from '../../../styles/breakpoints';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  font-size: 13px;

  ${media.phone`
    flex-direction: column;
    text-align: left;
    padding-left: 15px;
    padding-bottom: 80px;
  `};
`;

const LeftWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  a {
    margin-left: 20px;
  }

  ${media.phone`
    flex-direction: column;
    text-align: left;

    a {
      margin-left: 0px;
      margin-bottom: 5px;
    }
  `};
`;

const BusinessNum = styled.a`
  ${media.phone`
    text-align: right;
    margin-right: 20px;
  `};
`;

const FooterComponent = () => (
  <Wrapper>
    <LeftWrapper>
      <Link to="/disclaimer">免责条款</Link>
      <Link to="/license">营业执照</Link>
      <Link to="/recruitment">加入我们</Link>
    </LeftWrapper>
    <BusinessNum href="http://www.beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">沪ICP备18005105号</BusinessNum>
  </Wrapper>
);


export default FooterComponent;