const menuItems = [
  {
    name: '购买',
    subItems: [
      {
        name: '书写工具',
        subItems: [
          {
            name: '墨水笔',
            url: '/productlist?id=1',
          },
          {
            name: '宝珠笔',
            url: '/productlist?id=2',
          },
          {
            name: '自动铅笔',
            url: '/productlist?id=4',
          },
          {
            name: '原子笔',
            url: '/productlist?id=3',
          },
        ]
      },
      {
        name: '配件其他',
        subItems: [
          {
            name: '儿童画笔',
            url: '/productlist?id=8',
          },
          {
            name: '礼盒套装',
            url: '/productlist?id=9',
          },
          {
            name: '墨水笔芯',
            url: '/productlist?id=7',
          },
          {
            name: '配件',
            url: '/productlist?id=6',
          },
        ]
      },
      {
        name: '服务',
        subItems: [
          {
            name: '常见问题',
            url: '/customer-service?id=0',
          },
          {
            name: '保养',
            url: '/customer-service?id=2',
          },
          {
            name: '维修',
            url: '/customer-service?id=4',
          },
          {
            name: '刻字',
            url: '/customer-service?id=3',
          },
        ]
      },
      {
        name: '高端书写',
        url: '/productoption?id=1',
      },
      {
        name: '商务书写',
        url: '/productoption?id=2',
      },
      {
        name: '年轻书写',
        url: '/productoption?id=3',
      },
    ]
  },
  {
    name: '关于我们',
    subItems: [
      {
        name: '关于凌美',
        subItems: [
          {
            name: '最新动态',
            url: '/about?id=0',
          },
          {
            name: '新闻',
            url: '/about?id=1',
          },
          {
            name: '公司沿革',
            url: '/about?id=2',
          },
          {
            name: '建筑与艺术',
            url: '/about?id=3',
          },
        ]
      },
      {
        name: '设计',
        subItems: [
          {
            name: '设计亮点',
            url: '/design?id=0',
          },
          {
            name: '设计师',
            url: '/design?id=1',
          },
          {
            name: '国际奖项',
            url: '/design?id=2',
          },
        ]
      },
      {
        name: '品牌',
        subItems: [
          {
            name: '质量1',
            url: '/brand?id=0',
          },
          {
            name: '设计',
            url: '/brand?id=1',
          },
          {
            name: '德国制造',
            url: '/brand?id=2',
          },
        ]
      },
    ]
  },
  {
    name: '零售商',
    url: '/map',
  },
  {
    name: '社区',
    url: '/community',
  },
  {
    name: '我的订单',
    url: '/order',
    requireLogin: true,
  },
];

export default menuItems;