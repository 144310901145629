import React from 'react';
import styled from 'styled-components';
import { ProductFooter } from '../Common';

const Wrapper = styled.section`
background-color: #fff;
padding : 50px;
text-align: left;
color: gray;
`;

const Content = styled.div`
margin : 0 0 0 2%;
font-size:14px;
line-height:200%;
`;

 
const Title = styled.div`
  font-size: 24pt;
  margin: 0 0 5% 0;
`;

const Subtitle = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

const Delivery =()=>  (
 
  <Wrapper>  
        <Title>发货及配选</Title>
        <Subtitle>发货须知</Subtitle>
        <br/>
        <Content>
        <br/>
        商品运送 
        <br/>
        <br/>
        本公司将于确认付款后配送所订购商品至客户指定地点，一张订单仅提供同一个地址的运送。 对快递过程无法预期、不可控的因素导致送货延迟或无法交货原因来自客户错误讯息其所造成增加费用支出，将由客户自行承担。 如果客户未提供完成交货必须的明确交货指示、文件，本公司得以通知商户商品将被另行保管，但相关的产品风险将转移给客户。 
        <br/>
        <br/>
        所有权归属及损坏风险的承担 
        <br/>
        <br/>
        商品的所有权在商品依客户订单指示送达后及归客户所有，相关的损毁或遗失的风险由客户承担。 
        <br/>
        </Content>
        <ProductFooter/>
      </Wrapper>
);

export default Delivery;
