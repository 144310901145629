import React from 'react';
import styled from 'styled-components';
import { getIntQueryStringByKey } from '../../utils';
import { CompanyFooter } from '../Common';
import { media, TabletAndDesktop, MobileOnly } from '../../styles/breakpoints';
import qs from 'query-string';
import { Link } from 'react-router-dom';
import Highlight from './Highlight';
import HighlightMobile from './HighlightMobile';
import Designer from './Designer';
import Awards from './Awards';

const TabWrapper = styled.div`
  display: flex;

  ${media.phone`
  `};
`;

const Tab = styled.div`
  width: 160px;
  height: 40px;
  text-align: center;
  font-size: 18px;
  line-height: 40px;
  cursor: pointer;
  
  
  background-color: ${props => props.selected ? '#f2f0f1' : '#cccacb' };
  
  :hover {
    color: #000;
    background-color: #f2f0f1;
  }

  
  ${media.tablet`
    width: 150px;
    height: 40px;
    display: none;
  `};

  ${media.phone`
    width: 60px;
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    display: none;
  `};
`;

const Wrapper = styled.section`
  background-color: #fff;
`;

const Content = styled.section`
`;

const FooterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

class DesignComponent extends React.Component {

  state = {
    value: parseInt(this.props.location.search.split('=')[1])
  };

  componentDidUpdate(prevProps) {
    const prevId = getIntQueryStringByKey(prevProps.location.search, 'id', 1);
    const currId = getIntQueryStringByKey(this.props.location.search, 'id', 1);
    if (prevId !== currId ) {
      this.loadPageData();
    }
  }

  async loadPageData() {
    // Reset State
    this.setState({
      value: getIntQueryStringByKey(this.props.location.search, 'id', 1)
    });
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleQ1Click = () =>{
    this.setState(state => ({ Q1 : !state.Q1 }));
  }
  render() {
    const { value } = this.state;
    const parsed = qs.parse(this.props.location.search) || {};
    const selectedIdx = parsed.id ? parseInt(parsed.id) : 0;
    const typeList = ["设计亮点","设计师","国际奖项"]


    return (
      <div>
        <TabWrapper>
            { typeList.map((type, idx) => (
              <Link key={idx} to={`${this.props.location.pathname}?id=${idx}`}>
                <Tab selected={idx === selectedIdx}>
                  { type }
                </Tab>
              </Link>
            ))}
          </TabWrapper>
        <Wrapper>
          <Content>
            {value === 0 && 
              <React.Fragment>
               <MobileOnly>
                  <HighlightMobile />
                </MobileOnly>
                <TabletAndDesktop>
                  <Highlight />
                </TabletAndDesktop>
              </React.Fragment>
            }
            {value === 1 && <Designer/>}
            {value === 2 && <Awards/>}
          </Content>

          <FooterWrapper>
            <div className="col-xs-1 col-lg-1 col-md-1"></div>
            <div className="col-xs-10 col-lg-10 col-md-10">
              <CompanyFooter />
            </div>
            <div className="col-xs-1 col-lg-1 col-md-1"></div>
          </FooterWrapper>
        </Wrapper>
      </div>
    )
  }
}


export default DesignComponent;
