import React from 'react';
import styled from 'styled-components';

import { Carousel, ProductFooter } from '../Common';
import { PageSection } from './Component';
import { media } from '../../styles/breakpoints';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  align-items: center;
  text-align: left;
`;

const ContentWrapper = styled.div`
  flex-direction: column;
  padding: 0 !important;

  ${media.phone`
    padding: 15px !important;
  `};
`;

const Header = styled.div`
  font-size: 24pt;
  margin-bottom: 1em;
  color: gray;
`;

const CategoryPageComponent = ({ bannerItems, categoryList, seriesItems, serviceList }) => {
  return (
    <Wrapper>
      <Carousel items={bannerItems} />

      <ContentWrapper className="col-lg-8 col-sm-10">
        <Header>寻找你的凌美</Header>

        <PageSection categoryList={categoryList} seriesItems={seriesItems} serviceList={serviceList} />

        <ProductFooter />
      </ContentWrapper>
    </Wrapper>
  )
};

export default CategoryPageComponent;