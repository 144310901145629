import React from 'react';
import styled from 'styled-components';
import axios from 'axios';

import config from '../../config';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 30px;
  color: gray;

  img {
    width: 100%
  }
`;

const Header = styled.div`
  font-size: 22pt;
  color: #000;
  margin: 30px 0;
  font-weight: bold;
`;

const Title = styled.h2`
  margin-bottom: 20px;
  font-size: 20pt;
`;

const ListItem = styled.div`
  margin-bottom: 20px;
`;

const ConentWrapper = styled.div`
  font-size: 14px;

  p {
    line-height: 200%;
    margin: 0;
  }
`;

class History extends React.Component {
  state = {
    list: [],
  }

	async componentDidMount() {
		const list = await this.getPageData();

		this.setState({ list });
	}

  getPageData = async () => {
    const fetchUrl = `${config.serverEndPoint}/highlightPage`;

    try {
      const result = await axios.get(fetchUrl);

      return result.data;
    }
    catch (err) {
      console.log(err);
    }
  }

	render () {
		const { list } = this.state;

		return (
			<Wrapper>
        <Header></Header>
        { list && list.map((item, idx) => {
          return (
            <ListItem key={idx}>
              <Title>{item.date1} : {item.title}</Title>
              <br />
              <img src={`${config.imagePrefix}/designhighlights/${item.image2}`} alt=""></img>
              <br />
              { item.description1 && item.description1.split('\n').map((desc) => (
                <ConentWrapper>
                  <p>{desc}</p>
                  <br />
                </ConentWrapper>
              ))}
            </ListItem>
          )
        })}
			</Wrapper>
		);
	}
}
export default History;