import React from 'react';
import styled from 'styled-components';

const WeiboWrapper = styled.div`
  padding: 10px;
  background-color: #fff;
`
const WidgetTitle = styled.div`
  display: block;
  padding-left: 15px;
  line-height: 2em;
  background: rgba(0, 0, 0, 0.25);
  font-size: 24px !important;
  color: #fff;
  text-transform: uppercase;
`;

const WeiboGrid = React.memo(() => {
  return (
    <React.Fragment>
      <WidgetTitle>Lamy 新浪微博</WidgetTitle>
      <WeiboWrapper id="weibo">
        <ul><span></span></ul>
        <ul>
          <iframe
            title="weibo"
            id="weibo"
            style={{width: '100%', height: '240px'}}
            frameBorder="-10"
            scrolling="yes"
            src="http://v.t.sina.com.cn/widget/widget_blog.php?uid=2027033521" />
        </ul>
      </WeiboWrapper>
    </React.Fragment>
  )
});

export default WeiboGrid;