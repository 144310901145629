import React from 'react';
import styled from 'styled-components';
import format from 'date-fns/format';
import xss from 'xss';

const StyledContainer = styled.div`
  width: 100%;
  position: relative;
  text-align: left;
`;

const NameContainer = styled.div`
  font-weight: bold;
`;

const MessageRow = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Message = styled.div`
  background-color: #FFF;
  padding: 0.5rem;
  max-width: 250px;
  overflow-wrap: break-word;
  border: 1px solid #DDDDDD;
  border-radius: 4px;
`;

const TimeDisplay = styled.div`
  color: rgba(0, 0, 0, 0.3);
  padding: 0 0.5rem;
`;

const insertTextAtIndices = (text, obj) => {
  return text.replace(/./g, function(character, index) {
    return obj[index] ? obj[index] + character : character;
  });
};

const filter = new xss.FilterXSS({})

const AdminMessage = ({ name, message, time }) => {
  message = filter.process(message);
  const urlMatches = message.match(/\b(http|https)?:\/\/\S+/gi) || [];

  urlMatches.forEach(link => {
    const startIndex = message.indexOf(link);
    const endIndex = startIndex + link.length;
    message = insertTextAtIndices(message, {
      [startIndex]: `<a href="${link}" target="_blank" rel="noopener noreferrer" class="message-link">`,
      [endIndex]: "</a>"
    });
  });

  return (
    <StyledContainer>
      <NameContainer>{name}</NameContainer>
      <MessageRow>
        <Message>
          <span dangerouslySetInnerHTML={{ __html: message }} />
        </Message>
        <TimeDisplay>{format(new Date(time * 1000), 'HH:mm')}</TimeDisplay>
      </MessageRow>
    </StyledContainer>
  );
};

export default AdminMessage;
