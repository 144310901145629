import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
padding : 20px;
text-align: justify;
color: gray;
white-space: pre-line;
p{
    font-size:14px;
    line-height:200%;
}
img{
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 15px;
    float: left;
}
`

const Title = styled.h2`
margin-top:50px
`

const Designer = () => {
    return (
        <Wrapper>
            <div className="row">
                <div className="col-xs-1 col-lg-1 col-md-1"></div>
                <div className="col-xs-10 col-lg-10 col-md-10">
                <div>
                <Title>产品设计师</Title>
                <p>究竟应该由内部的特设部门创作产品设计，还是由外部设计师提供创意的服务，这点对凌美来说从来不是两难。
                <br /> <br />
                    其实凌美早在新创新时代的第一件书写工具便是与外部设计师的携手创作。此人便是推动包豪斯运动的Gerd A. Müller。单凭这一点，便足以令凌美对他注目，邀请他带领公司的设计新风格，并在往后多年间参与创造的过程。
                <br /><br />
                    到了70年代，无间的合作带来宝贵的经验，最重要的是这些「独立创作人」与公司内部的设计员工都从意见交流中获得无限的启发，这些合作促使了往后凌美其他产品设计师及设计公司的合作无间。
            </p>
            </div>
            <hr/>
            <div className="row">
                <div className="col-lg-8 col-md-8">
                    <h4>
                    格尔德A.米勒<br />
                    Gerd A. Müller<br /><br />
                    </h4>
                    <p>他虽於1991年去世，但其为Lamy设计的作品却永垂不朽。<br /><br />
                    
                    以下为Gerd A. Müller设计的书写系列：<br />
                    LAMY 2000系列<br />
                    LAMY cp1系列<br />
                    LAMY st系列<br />
                    LAMY unic独特系列<br /></p>
                    
                </div>
                <div className="col-lg-4 col-md-4">
                    <img src="./images/design/mueller_ger.jpg" alt=""/>
                </div>
            </div>
            <hr/>
            <div className="row">
                <div className="col-lg-8 col-md-8">
                    <h4>
                    尔夫冈<br />
                    Wolfgang Fabian<br /><br />
                    </h4>
                    出生於1943年，早期是一位取得专业资格的金匠，其後修读工业设计。 <br /><br />

                    自1981年起，他带领Fabian Industrie-Design的公司成员为日用品及资本商品研製专门及技术性的设计方案。 <br /><br />

                    Fabian Industrie-Design的产品设计在国际间获奖无数。 <br /><br />

                    Fabian负责创作以下的LAMY系列： <br />
                    LAMY agenda 译动系列 <br />
                    LAMY AL-star 恒星系列 <br />
                    LAMY logo 连环系列 <br />
                    LAMY pickup 流动系列 <br />
                    LAMY safari 狩猎系列 <br />
                    LAMY spirit 心灵系列 <br />
                    LAMY swift 速动系列 <br />
                    LAMY tipo 指标系列<br />
                </div>
                <div className="col-lg-4 col-md-4">
                    <img src="./images/design/fabian_ger.jpg" alt=""/>
                </div>
            </div>
            <hr/>
            <div className="row">
                <div className="col-lg-8 col-md-8">
                    <h4>
                    Knud Holscher<br />
                    </h4>
                    
                    这位原籍丹麦的设计师於1930年生於Rødby，在50年代取得建築学位後随即赢取多项建築比赛的奖项。今时今日，他设计的建築物获奖无数，与他数不尽的设计殊荣不煌多让。 <br /><br />
                    
                    其别出心裁的设计作品如国际知名的d line系列的门把、扶手和橱柜系统与及Erco 的Quinta射灯系列，都反映了Knud Holscher的优雅简约风，成就视觉与功能完美无缝的结合。 <br /><br />
                    这位成功的设计师曾与Arne Jacobsen、Alan Tye、及著名丹麦建築公司Krohn & Hartvig Rasmussen合作交流，其後自1995年起在哥本哈根於自己创办的公司工作。 <br /><br />
                    他就是在这里为Lamy设计了LAMY dialog 2焦点系列的钢珠笔。<br />
                </div>
                <div className="col-lg-4 col-md-4">
                    <img src="./images/design/holscher_ger.jpg" alt=""/>
                </div>
            </div>
            <hr/>
            <div className="row">
                <div className="col-lg-8 col-md-8">
                    <h4>
                    深泽直人<br />
                    Naoto Fukasawa<br /><br />
                    </h4>
                    
                    本著名产品设计师，家用电器和日用杂物设计品牌“±0”的创始人。他曾为多家知名公司诸如苹果、爱普生进行过品牌设计，其作品在欧洲和美国赢得过几十余设计大奖。他的设计主张是：用最少的元素（上下公差为±0） 来展示产品的全部功能。 <br /><br />
                    
                    为LAMY设计NOTO 原子笔<br />
                </div>
                <div className="col-lg-4 col-md-4">
                    <img src="./images/design/fukasawa_ger.jpg" alt=""/>
                </div>
            </div>
            <hr/>
            <div className="row">
                <div className="col-lg-8 col-md-8">
                    <h4>
                    Andreas Haug<br />
                    </h4>
                    
                    本於1946年在海登海姆出生，刚开展事业时在Daimler Benz出任商业及技术见习生，1968年至1972年间於斯图加特(Stuttgart)的国家艺术学院(National academy of fine arts)修读设计。 <br /><br />
                    
                    他於1972年成为Esslinger设计部的首位协作人，1982至1984年间出任frogdesign的行政持股人，并在1984年成为该公司的设计顾问及设计副总裁。<br /><br />
                    他其後於1987年与Tom Schönherr携手创办Phoenix Product Design<br /><br />
                    其作品曾获颁多项国际设计大奖。<br /><br/>
                    Phoenix Product Design创作了以下系列： <br/>
                    LAMY accent优雅系列 <br/>
                    LAMY 4pen 四用笔 <br/>
                    LAMY nexx系列 <br/>
                    LAMY smile微笑系列<br/>
                </div>
                <div className="col-lg-4 col-md-4">
                    <img src="./images/design/phoenix_ger.jpg" alt=""/>
                </div>
            </div>
            <hr/>
            <div className="row">
                <div className="col-lg-8 col-md-8">
                    <h4>
                    Franco Clivio<br />
                    </h4>
                    
                    Franco Clivio於1942年出生於瑞士Schulen，就读於University for Creation in Ulm。<br /><br />
                    
                    他为众多客户提供设计服务，其中包括Erco、FSB、Gardena、Rodenstock及西门子(Siemens)。<br /><br />
                    Clivio更於Università IUAV di Venezia讲授产品设计的课目，除此之外，他亦曾到美国、德国及芬兰作访问教授，并在苏黎世的University for Design 授课。<br /><br />
                    他的作品屡获国际殊荣。<br /><br />
                    Clivio为LAMY设计了LAMY pico口袋系列和Dialog焦点系列<br />
                </div>
                <div className="col-lg-4 col-md-4">
                    <img src="./images/design/clivio_ger.jpg" alt=""/>
                </div>
            </div>
            <hr/>
            <div className="row">
                <div className="col-lg-8 col-md-8">
                    <h4>
                    Konstantin Grcic<br />
                    </h4>
                    
                    Konstantin Grcic於1965年出生於慕尼黑，修读伦敦皇家艺术学院，後於1991年创立自己的公司Konstantin Grcic Industrial Design。 <br /><br />
                    
                    他此後一直从事傢具、灯具及其他产品的设计工作，为多个尊贵品牌创作，如Agape、 Authentics、ClassiCon、Driade、Flos、Krups等等。<br /><br />
                    他的多件作品都获得国际殊荣。其Mayday灯具於2001年荣获金圆规设计大奖(Compasso D\'Oro)，并被纽约现代艺术馆永久收藏。 <br /><br />
                    Grcic现於慕尼黑居住及工作。<br />
                </div>
                <div className="col-lg-4 col-md-4">
                    <img src="./images/design/KonstaninGrcic.jpg" alt=""/>
                </div>
            </div>
            <hr/>
            <div className="row">
                <div className="col-lg-8 col-md-8">
                    <h4>
                    Richard Sapper<br />
                    </h4>
                    
                    Richard Sapper是全球数一数二的优秀产品设计师。他生於慕尼黑，首先在米兰发展事业，认为意大利的市场适合其充满智慧的设计：德国的精密準确与拉丁的华丽尊贵的完美结合。 <br /><br />
                    
                    Sapper的作品流露著他的设计意念及手法，例子见於其优雅的Tizio座檯灯、IBM的 think pad手提电脑、Alessi的鸣声烧水壶、数之不尽的傢具、钟表、厨具等等。<br /><br />
                    他为LAMY设计了274型号原子笔LAMY dialog 1焦点系列。<br /><br />
                </div>
                <div className="col-lg-4 col-md-4">
                    <img src="./images/design/sapper_ger.jpg" alt=""/>
                </div>
            </div>
            <hr/>
            <div className="row">
                <div className="col-lg-8 col-md-8">
                    <h4>
                    Hannes Wettstein<br />
                    </h4>
                    
                    Hannes Wettstein於1958年瑞士Ascona出生，现为设计、企业设计及建築造型的专业从业人。 <br /><br />
                    
                    他於90年代在阿姆斯特丹、汉诺威、巴塞尔及米兰多间学院出任讲师，更在苏黎世理工大学(ETH Zurich)授课。1994年至2001年间，他於德国的University for design in Karlsruhe出任教授一职。 <br />
                    此後他旗下的公司zed从事品牌发展及製作的工作，树立成功品牌形象的模範。 <br /><br />
                    Zed活跃於在多个层面，以哲学、伦理、观感、经济、生态、精确及现实主义为其基本理念。各科各门的设计精英都与zed有联繫。<br /><br />
                    他为Lamy设计了LAMY scribble艺术家系列 及 LAMY studio演艺系列。<br/><br/>
                </div>
                <div className="col-lg-4 col-md-4">
                    <img src="./images/design/wettstein_ger.jpg" alt=""/>
                </div>
            </div>
            <hr/>
            <div className="row">
                <div className="col-lg-8 col-md-8">
                    <h4>
                    Sieger Design<br />
                    </h4>
                    
                    Sieger Design 是一家品牌咨询及设计公司，专为国际知名企业研发产品，其中包括Alape(阿拉帕)、Dornbracht(当代)、Duravit(杜拉维特)、Jochen Pohl、Sony、 Wiesmann(威兹曼汽车) 及 WMF。 <br /><br />
                    
                    公司设于北莱茵威斯特法伦州的 Harkotten Castle 。公司的董事及团队不只追求登峰造极的设计美学，更把经济、生态、社会和文化元素全面融入其中。充满睿智的功能、上乘的用料、不朽的原型设计，是指导公司发展的大原则。  <br />
                    公司由家庭经营，其一贯的设计风格及高端的科技，配合精良的巧手工艺，是公司最大的特色。现时公司由第二代接棒，Christian及Michael Sieger兄弟成功领导由大约40名员工组成的公司，其中包括设计各个领域的跨学科专才。 <br /><br />
                    在这种一体化的概念下，公司的营销公关、设计、通信设计、建筑及设计管理，都能在同一屋檐下进行。<br /><br />
                </div>
                <div className="col-lg-4 col-md-4">
                    <img src="./images/design/sieger-design_ger.jpg" alt=""/>
                </div>
            </div>
            <hr/>
                </div>
                <div className="col-xs-1 col-lg-1 col-md-1"></div>
          </div>
            
        </Wrapper>
    )
}

export default Designer