import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import config from '../../../../config';
import { media } from '../../../../styles/breakpoints';

const Wrapper = styled.div`
  border-top: 1px solid #DDD;
  padding-top: 15px;
  margin-bottom: 30px;
`;

const Header = styled.div`
  text-align: left;
  font-size: 18px;
`;

const AccessoryList = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;

const AccessoryItem = styled.li`
  margin-top: 50px;
  width: 33.333333333%;

  ${media.phone`
    width: 50%;
  `};
`;

const AccessoryName = styled.div`
  margin-top: 30px;
  padding: 0 5px;
`;

const AccessoryImg = styled.img`
  width: 100%;
`;

const AccessorySection = ({ accessories }) => {
  return (
    <Wrapper>
      <Header>产品配件 (推荐搭配, 请单独拍下付款)</Header>

      <AccessoryList>
        { accessories && accessories.map((accessory, index) => (
          <AccessoryItem>
            <Link to={`/product?id=${accessory.id}&colorId=${accessory.colorId}`}>
              <AccessoryImg src={`${config.imagePrefix}/product/${accessory.image}`} alt="accessory" />

              <AccessoryName>{accessory.name}</AccessoryName>
            </Link>
          </AccessoryItem>
        ))}

      </AccessoryList>
    </Wrapper>
  )
}

export default React.memo(AccessorySection);