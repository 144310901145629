import React from 'react';
import styled from 'styled-components';

import { QRCodeLogin, PhoneLogin } from './components';
import { PageContainer } from '../Common';
import { TabletAndDesktop, MobileOnly } from '../../styles/breakpoints';

const Wrapper = styled.div`
  background-color: #fff;
  text-align: center;
`;

const NavTabs = styled.ul`
  display: flex;
  border-bottom: solid 1px #ccc;
`;

const NavTab = styled.li`
  flex: 1;
  padding: 15px 10px;

  cursor: pointer;
  font-weight: 600;

  background-color: ${props => props.selected ? '#f2f2f2' : '#d3d3d3'};
`;

const MainContainer = styled.div`
  background-color: #f2f2f2;
  height: 500px;
`;

const LoginComponemt = ({ onPhoneLogin }) => {
  const [selectedTab, setSelectedTab] = React.useState(0);
  return (
    <PageContainer title="LAMY帐户">
      <Wrapper>
        <TabletAndDesktop>
          <NavTabs>
            <NavTab selected={selectedTab === 0} onClick={() => setSelectedTab(0)}>扫码登录</NavTab>
            <NavTab selected={selectedTab === 1} onClick={() => setSelectedTab(1)}>帐户登录</NavTab>
          </NavTabs>

          <MainContainer>
            { selectedTab === 0 ? (
              <QRCodeLogin />
            ) : (
              <PhoneLogin onPhoneLogin={onPhoneLogin} />
              )}
          </MainContainer>
        </TabletAndDesktop>
        <MobileOnly>
          <MainContainer>
            <PhoneLogin onPhoneLogin={onPhoneLogin} />
          </MainContainer>
        </MobileOnly>
      </Wrapper>
    </PageContainer>
  )
};

export default LoginComponemt;