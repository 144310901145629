import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { ProductFooter } from '../../Common';
import { ProductItem } from '../Components';
import { PEN_TYPE_LIST } from '../../../constants';
import { media } from '../../../styles/breakpoints';
import config from '../../../config';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  align-items: center;
`;

const ContentWrapper = styled.div`
  width: 100%;
  padding: 40px 80px;

  ${media.phone`
    padding: 20px;
  `};
`;

const Header = styled.div`
  text-align: left;
  font-size: 24pt;
  margin-bottom: 1em;
  color: gray;
`;

const SeriesImage = styled.div`
  text-align: left;
  margin-bottom: 15px;
`;

const ProductSeriesListPageComponent = ({ optionId, seriesItems, addItemToCart }) => {
  return (
    <Wrapper>
      <ContentWrapper>
        <Header>{PEN_TYPE_LIST[optionId] || ''}</Header>

        { seriesItems && Object.entries(seriesItems).map((seriesItem) => {
          const series = seriesItem[1];

          return (
            <React.Fragment key={series.id}>
              <SeriesImage>
                <Link to={`/productseries?id=${series.seriesId}`}>
                  <img src={`${config.imagePrefix}/productseries/${series.seriesImage}`} alt="" />
                </Link>
              </SeriesImage>

              { series && series.seriesProducts && series.seriesProducts.map((product) => (
                <ProductItem key={product.id} product={product} addItemToCart={addItemToCart} hideSeriesImage />
              ))}
            </React.Fragment>
          )
        })}

        <ProductFooter />
      </ContentWrapper>
    </Wrapper>
  )
};

export default React.memo(ProductSeriesListPageComponent);