import React from 'react';
import styled from 'styled-components';
import { getIntQueryStringByKey } from '../../utils';
import { CompanyFooter } from '../Common';
import { media } from '../../styles/breakpoints';
import qs from 'query-string';
import { Link } from 'react-router-dom';

import  Care  from './Care';
import CorpBuying from './CorpBuying';
import Question from './Question';
import Repair from './Repair';
import Delivery from './Delivery';
import Lettering from './Lettering';

const TabWrapper = styled.div`
  display: flex;

  ${media.phone`
  `};
`;

const Tab = styled.div`
  width: 160px;
  height: 40px;
  text-align: center;
  font-size: 18px;
  line-height: 40px;
  cursor: pointer;
  
  background-color: ${props => props.selected ? '#f2f0f1' : '#cccacb' };
  
  :hover {
    color: #000;
    background-color: #f2f0f1;
  }

  
  ${media.tablet`
    display: none;
    width: 150px;
    height: 40px;
  `};

  ${media.phone`
    display: none;
    width: 60px;
    height: 30px;
    line-height: 30px;
    font-size: 16px;
  `};
`;

const Wrapper = styled.section`
background-color: #fff;
`;

const Content = styled.section`
.row{
  margin:0px
}
`;



class ServiceComponent extends React.Component {

  state = {
    value: parseInt(this.props.location.search.split('=')[1]),
    Q1:false,
    Q2:false,
    Q3:false,
    C1:false,
    C2:false,
    C3:false,
    C4:false,
  };

  componentDidUpdate(prevProps) {
    const prevId = getIntQueryStringByKey(prevProps.location.search, 'id', 1);
    const currId = getIntQueryStringByKey(this.props.location.search, 'id', 1);
    if (prevId !== currId ) {
      this.loadPageData();
    }
  }

  async loadPageData() {
    // Reset State
    this.setState({
      value: getIntQueryStringByKey(this.props.location.search, 'id', 1)
    });
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleQ1Click = () =>{
    this.setState(state => ({ Q1 : !state.Q1 }));
  }

  handleQ2Click = () =>{
    this.setState(state => ({ Q2 : !state.Q2 }));
  }

  handleQ3Click = () =>{
    this.setState(state => ({ Q3 : !state.Q3 }));
  }
  handleC1Click = () =>{
    this.setState(state => ({ C1 : !state.C1 }));
  }
  handleC2Click = () =>{
    this.setState(state => ({ C2 : !state.C2 }));
  }
  handleC3Click = () =>{
    this.setState(state => ({ C3 : !state.C3 }));
  }
  handleC4Click = () =>{
    this.setState(state => ({ C4 : !state.C4 }));
  }

  getItemStyle =() =>{
    return {
      padding:0
    }
  } 

  render() {
    const { value } = this.state;
    const parsed = qs.parse(this.props.location.search) || {};
    const id = parsed.id || 1;
    const selectedIdx = parsed.id ? parseInt(parsed.id) : 0;
    const typeList = ["常见问题","企业选购","保养须知","刻字服务","维修服务","发货及配选"]

    return (

      <div>
        <TabWrapper>
            { typeList.map((type, idx) => (
              <Link key={idx} to={`${this.props.location.pathname}?id=${idx}`}>
                <Tab selected={idx === selectedIdx}>
                  { type }
                </Tab>
              </Link>
            ))}
          </TabWrapper>
        <Wrapper>
          <Content>
              {value === 0 && <Question getItemStyle={()=>this.getItemStyle()} state={this.state} handleQ1Click={()=>this.handleQ1Click()} handleQ2Click={()=>this.handleQ2Click()} handleQ3Click={()=>this.handleQ3Click()}/>}
              {value === 1 && <CorpBuying/>}
              {value === 2 && <Care getItemStyle={()=>this.getItemStyle()} state={this.state} handleC1Click={()=>this.handleC1Click()} handleC2Click={()=>this.handleC2Click()} handleC3Click={()=>this.handleC3Click()} handleC4Click={()=>this.handleC4Click()}/>}
              {value === 3 && <Lettering/>} 
              {value === 4 && <Repair/>} 
              {value === 5 && <Delivery/>}  
          </Content>
        </Wrapper>
      </div>
    );
  }
}


export default ServiceComponent;
