import React, { Component } from 'react';
import axios from 'axios';
import styled from 'styled-components';

import config from '../../config';
import BaiduMap from './BaiduMap';
import retailInfo from './mockData.json';
import ShopList from './ShopList';
import { media, MobileOnly, TabletAndDesktop } from '../../styles/breakpoints';

const Wrapper = styled.section`
  background-color: #ffffff;
  text-align: left;
  padding: 60px 100px 80px;
  width: 100%;
  min-height: calc(100vh - 150px - 66px);

  ${media.phone`
    padding: 20px;
  `};
`;

const Header = styled.h2`
  margin-bottom: 20px;
`;

const FunctionRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const SelectGrpWrapper = styled.div`
  margin-bottom: 20px;
  padding: 0 !important;
`;

const SearchInput = styled.input`
  display: block;
  width: 100%;
  height: 38px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const ButtonWrapper = styled.div`
  padding-right: 0 !important;
`;

const Button = styled.button`
  height: 38px;
  width: 100%;
  color: #333;
  background-color: #fff;
  padding: 6px 12px;
  border: solid 1px #ccc;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
`;

const MobileBaiduMapWrapper = styled.div`
  margin-top: 10px;
`;

class MapComponent extends Component {
  state = {
    selectedRetail: null,
    selectedMarker: null,
    retailList: [],
    selectedCity: '',
    selectedIndex: 0,
    showInfoWindow: true,
    searchParam: '',
    searchList: null
  }

  async componentDidMount() {
    const result = await this.getPageData();
    let retailList = result ? result : []

    retailList && retailList.forEach((result) => {
      result.retail.forEach(async (data) => {
        if (data.latitude === "" && data.longitude === "") {
          const coor = await this.getPointByAddress(data.address)
          if (coor != null) {
            data.latitude = coor.lat
            data.longitude = coor.lng
          }
        }
      })
    });

    this.setState({ retailList });
  }
  
  getPageData = async () => {
    const fetchUrl = `${config.serverEndPoint}/MapPage`;

    try {
      const result = await axios.get(fetchUrl);
      return result.data;
    }
    catch (err) {
      console.log(err);
    }
  }

  renderSelectGrp(info) {
    return (
      <SelectGrpWrapper className="col-12">
        <div>
          <select className="form-control"
            onChange={this.onSelectedCity}>
            <option>请选择地区</option>
            {
              info.map(({ city }) => (
                <option key={city} value={city}>{city}</option>
              ))
            }
          </select>
        </div>
      </SelectGrpWrapper>
    );
  }

  updateMapPosition = () => {
    const point = new window.BMap.Point(121.483292, 31.238959);
    this.map.centerAndZoom(point, 11);
  }

  onSelectedCity = (e) => {
    this.setState({
      selectedCity: e.target.value,
      searchList: null
    })
  }

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      this.onSearch();
    }
  }

  onSearchInput = event =>{
    this.setState({ searchParam : event.target.value })
  }

  onSearch = async () => {
    const { searchParam } = this.state;
    if (!searchParam) {
      return;
    }

    const fetchUrl = `${config.serverEndPoint}/MapPage?t=${searchParam}`;

    try {
      const searchList = await axios.get(fetchUrl).then(result => result.data);
  
      if (searchList) {
        searchList.forEach(async (data) => {
          if (data.latitude === "" && data.longitude === "") {
            const coor = await this.getPointByAddress(data.address)
            if (coor != null) {
              data.latitude = coor.lat
              data.longitude = coor.lng
            }
          }
        });
      }
  
      this.setState({ searchList });
    } catch (e) {
      console.log(e);
    }
  }

  getPointByAddress = (address) => {
    const myGeo = new window.BMap.Geocoder();
    return new Promise((resolve) =>
      myGeo.getPoint(address, (point) => {
        resolve(point)
      }))
  }

  onRetailSelected = (retailId) => {
    this.setState({ selectedRetail: retailId });
  }

  render() {
    const { retailList, searchParam, searchList } = this.state;
    const { selectedCity, selectedRetail } = this.state;

    let target = retailList.find((ele) => {
      if (selectedCity !== '' && selectedCity !== '请选择地区') {
        return ele.city === selectedCity
      } else {
        return ele.city === '上海'
      }
    })

    return (
      <Wrapper>
        <Header>寻找凌美</Header>
        <MobileOnly>
          <div className="row">
            <div className="col-12">
              { this.renderSelectGrp(retailList) }
              <FunctionRow>
                <SearchInput
                  label="搜索"
                  value={searchParam}
                  onChange={this.onSearchInput}
                  onKeyPress={this.handleKeyPress}
                  margin="normal" />
                <ButtonWrapper className="col-lg-2">
                  <Button type="button" onClick={this.onSearch}>搜索</Button>
                </ButtonWrapper>
              </FunctionRow>
              <ShopList
                onClick={this.onRetailSelected}
                searchList={searchList}
                retailList={target ? target.retail : retailInfo[0].retail} />
            </div>
            <MobileBaiduMapWrapper className="col-12">
              <BaiduMap
                onClick={this.onRetailSelected}
                className="col-12"
                idx={this.state.selectedIndex}
                selectedRetail={selectedRetail}
                setMapRef={ref => this.map = ref ? ref.map : null}
                searchList={searchList}
                retailList={target ? target.retail : retailInfo[0].retail} />
            </MobileBaiduMapWrapper>
          </div>
        </MobileOnly>
        <TabletAndDesktop>
          <div className="row">
            <div className="col-3">
              { this.renderSelectGrp(retailList) }
              <ShopList
                onClick={this.onRetailSelected}
                searchList={searchList}
                retailList={target ? target.retail : retailInfo[0].retail} />
            </div>
            <div className="col-9">
              <FunctionRow>
                <SearchInput
                  label="搜索"
                  value={searchParam}
                  onChange={this.onSearchInput}
                  onKeyPress={this.handleKeyPress}
                  margin="normal" />
                <ButtonWrapper className="col-lg-2">
                  <Button type="button" onClick={this.onSearch}>搜索</Button>
                </ButtonWrapper>
              </FunctionRow>
              <BaiduMap
                onClick={this.onRetailSelected}
                className="col-9"
                idx={this.state.selectedIndex}
                selectedRetail={selectedRetail}
                setMapRef={ref => this.map = ref ? ref.map : null}
                searchList={searchList}
                retailList={target ? target.retail : retailInfo[0].retail} />
            </div>
          </div>
        </TabletAndDesktop>
      </Wrapper>
    );
  }
}

export default MapComponent;