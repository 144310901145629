import React from 'react';
import styled from 'styled-components';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

import { OrderItem, OrderItemMobile } from './components';
import { PageContainer } from '../Common';
import { media, MobileOnly, TabletAndDesktop } from '../../styles/breakpoints';

const filterItem = [
  {
    name: '全部订单',
    status: 'ALL',
  },
  {
    name: '等待交易',
    status: 'PENDING',
  },
  {
    name: '已付款',
    status: ['PAID', 'SENT', 'RETURN'],
  },
  {
    name: '已过期',
    status: 'EXPIRED',
  },
]

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${media.phone`
    padding: 0;
  `};
`;

const OrderItemWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  min-height: 400px;
`;

const Filter = styled.ul`
  display: flex;
  margin: 25px 0 15px;
`;

const FilterItem = styled.li`
  margin-right: 13px;
  padding-bottom: 3px;
  cursor: pointer;

  border-bottom: ${props => props.selected ? 'solid 2px #000' : 'none'};

  &:not(:first-child) {
    margin-left: 13px;
  }
`;

const NotFound = styled.div`
  text-align: center;
  margin-top: 200px;
`;

const OrderComponent = ({ orderData }) => {
  const [selectedFilter, setSelectedFilter] = React.useState('ALL');

  const filteredOrderData = selectedFilter === 'ALL' ?
    orderData
    : orderData.filter((order) => 
      typeof selectedFilter === 'string' ?
        order.status === selectedFilter
        : selectedFilter.includes(order.status)
    )

  return (
    <PageContainer title="我的订单">
      <Wrapper>
        <Filter>
          { filterItem.map((filter, idx) => (
            <React.Fragment key={idx}>
              { idx === 0 ? null : '|'}
              <FilterItem
                onClick={() => setSelectedFilter(filter.status)}
                selected={selectedFilter === filter.status}
              >
                { filter.name }
              </FilterItem>
            </React.Fragment>
          ))}
        </Filter>
        <CSSTransitionGroup
          transitionName="product-animation"
          transitionEnterTimeout={300}
          transitionLeaveTimeout={300}>
          <OrderItemWrapper id={selectedFilter}>
            <TabletAndDesktop>
              { filteredOrderData && filteredOrderData.length > 0 ?
                  filteredOrderData.map((order) => (
                    <OrderItem key={order.id} order={order}/>
                  )) : (
                    <NotFound>暫無訂單</NotFound>
                  )
              }
            </TabletAndDesktop>
            <MobileOnly>
              { filteredOrderData && filteredOrderData.length > 0 ?
                  filteredOrderData.map((order) => (
                    <OrderItemMobile key={order.id} order={order}/>
                  )) : (
                    <NotFound>暫無訂單</NotFound>
                  )
              }
            </MobileOnly>
          </OrderItemWrapper>
        </CSSTransitionGroup>
      </Wrapper>
    </PageContainer>
  )
};

export default OrderComponent;