import React from 'react';
import { withRouter } from 'react-router-dom';
import lodashGet from 'lodash/get';
import { Prompt } from 'react-router';

import ForceUpdateUserDataPageComponent from './ForceUpdateUserDataPageComponent';
import { withAuth, withPopup } from '../../context';
import { NAME_REGEX, PHONE_REGEX } from '../../constants';

class ForceUpdateUserDataPageContainer extends React.PureComponent {
  state = {
    submitted: false
  }

  validateForm = ({ firstName, lastName, password, password2, addressReceiver, address, addressPhone }) => {
    if (
      firstName === '' || lastName === '' || password === ''
      || password2 === '' || addressReceiver === ''
      || address === '' || addressPhone === ''
    ) {
      return '请填寫所有資料';
    }
    if (!NAME_REGEX.test(firstName) || !NAME_REGEX.test(lastName) || !NAME_REGEX.test(addressReceiver)) {
      return '姓名必需是中/英字, 不能有数字或其他符号';
    }
    if (password !== password2) {
      return '两次输入的密码不一致';
    }
    if (password.length < 6) {
      return '密码不能少于6个字符';
    }

    if (!PHONE_REGEX.test(addressPhone)) {
      return '电话号码必需為11個字';
    }
  
    return;
  }

  onSubmit = async (formData) => {
    const { togglePopup, history, auth } = this.props;
    try {
      this.setState({ submitted: true });
      const error = this.validateForm(formData);
      if (error) {
        togglePopup({
          type: 'info',
          message: error
        })
        return;
      }

      await auth.forceUpdateUserData(formData);
      history.push('/');

    } catch (err) {
      console.log(err);
      const responseMsg = lodashGet(err, 'response.data.message', '更新失败');
      this.setState({ submitted: false });

      togglePopup({
        type: 'info',
        message: responseMsg
      })
    }
  }

  render() {
    const { submitted } = this.state;

    return (
      <React.Fragment>
        <ForceUpdateUserDataPageComponent onSubmit={this.onSubmit} />

        <Prompt when={!submitted} message="未填寫資料將無法登入" />
      </React.Fragment>
    )
  }
}

export default withRouter(
  withPopup(
    withAuth(ForceUpdateUserDataPageContainer)
  )
);