import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import styled from 'styled-components';
import { CompanyFooter } from '../Common';

const Wrapper = styled.section`
background-color: #fff;
text-align:left;
padding:20px;
`;

const Content = styled.div`
  width:100%; margin:0 0 7.5% 0;
`;
const Button = styled.button`
width: 100%;
padding: 0;
`;
 
const Header = styled.h2`
  padding:10px 0;color:gray;
  font-size:24pt;
`;

const Body = styled.div`
  font-size: 14px;
  line-height: 200%;
  font-weight: normal;
  color: #999;
`;

function getModalStyle() {
  const top = 50 ;
  const left = 50 ;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    display: 'inline-block'
  };
}

const styles = theme => ({
  paper: {
    position: 'absolute',
  },
});

class CompanyComponent extends React.Component {
  state = {
    open: false,
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Button onClick={this.handleOpen}><img src="./images/company/title-image_ger.jpg" width="100%" alt=""/></Button>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
          onClose={this.handleClose}
        >

        <div style={getModalStyle()} className={classes.paper}>
            <video controls>
                <source src="http://www.lamy.net.cn/movies/LAMY2012.mp4" type="video/mp4"></source>
            </video>
        </div>
        </Modal>
        <Wrapper >
        <div className="row">
        <div className="col-xs-1 col-lg-1 col-md-1"></div>
        <div className="col-xs-10 col-lg-10 col-md-10">
        
            <Content>
                <Header>关于凌美</Header>
                <Body>德国书写工具品牌LAMY始创人C•Josef Lamy于1930年在海德堡创立了欧瑟斯墨水笔制造厂（Orthos F-üllfederhalter-Fabrik），为LAMY品牌辉煌的历史揭开了序章。 1952年，崭露头角的LAMY 27流线型墨水笔，配备“Tintomatik” 系统，震撼问世, 彰显公司的创新敏锐触觉。1966年Gerd A.Müller终于打造出令人耳目一新的现代经典之作-LAMY 2000系列。产品撮合了聚碳酸酯与不锈钢材质，绝妙的配搭，使之从芸芸众生的书写工具中脱颖而出，时至今日，依然在市场具有影响力。</Body>
            </Content>
            <CompanyFooter />
        </div>
        <div className="col-xs-1 col-lg-1 col-md-1"></div>
        </div>
        </Wrapper>
        
        
      </div>
    );
  }
}

CompanyComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

// We need an intermediary variable for handling the recursive nesting.
const CompanyComponentWrapped = withStyles(styles)(CompanyComponent);

export default CompanyComponentWrapped;
