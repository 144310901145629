export const get = key => {
  const cookies = document.cookie.split('; ');
  const wanted = cookies.find(x => x.startsWith(`${key}=`));
  return wanted ? wanted.substr(`${key}=`.length) : null;
}

// Good enough no need expire
// HardCode same domain
export const set = (key, value) => {
  document.cookie = `${key}=${value}; path=/`;
}

export const clear = key => {
  document.cookie = `${key}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
}