import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { withRouter } from 'react-router-dom';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(80, 80, 80, 0.3);
`;

const Loader = styled.div`
  position: absolute;
  top: calc(50vh - 50px);
  left: calc(50% - 50px);
`

class NetworkOverlay extends React.Component {
  state = {
    isLoading: false
  }
  
  constructor(props) {
    super(props);

    axios.interceptors.request.use(this.onAxiosRequest);
    
    axios.interceptors.response.use(this.onAxiosResponse, this.onAxiosError);
  }

  onAxiosRequest = (config) => {
    this.setState({ isLoading: true });
    
    return config;
  }

  onAxiosResponse = (config) => {
    this.setState({ isLoading: false });
    
    return config;
  }

  onAxiosError = (error) => {
    this.setState({ isLoading: false });

    return Promise.reject(error);
  }


  render() {
    const { isLoading } = this.state;

    return isLoading ? (
      <Wrapper>
        <Loader>
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </Loader>
      </Wrapper>
    ) : null;
  }
}

export default withRouter(NetworkOverlay);