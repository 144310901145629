import React from 'react';
import styled from 'styled-components';
import { ProductFooter } from '../Common';

const Wrapper = styled.section`
background-color: #fff;
padding : 50px;
text-align: left;
color: gray;
`;

const Content = styled.div`
font-size: 14px;
line-height: 200%;
`;


const Title = styled.div`
  font-size: 24pt;
  margin: 0 0 5% 0;
`;


const Repair = () => (

  <Wrapper>
    <Title>维修服务</Title>
    <hr />
    <Content>
      <br />
      许多LAMY书写工具的型号都配有相应的配件。我们将为您提供这些配件，因此您可以凭着保修卡到店铺进行购买。
        <br />
        <br />
      如果产品需要更复杂的维修，请联系我们服务部门，我们很荣幸为您提供更多协助。
        <br />
    </Content>
    <ProductFooter/>
  </Wrapper>
);

export default Repair;
