import React from "react";
import styled from 'styled-components';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Link } from 'react-router-dom';
import { media } from '../../../styles/breakpoints';

const Wrapper = styled.div`
  margin-bottom: 3em;
  min-height: 450px;

  a {
    display: block;
  }
  
  ${media.tablet`
    margin-bottom: 20px;
    min-height: 280px;
  `};

  ${media.phone`
    margin-bottom: 20px;
    min-height: 147px;
  `};
`;

const settings = {
  showArrows: false,
  showThumbs: false,
  showStatus: false,
  showIndicators: true,
  infiniteLoop: true,
  emulateTouch: true,
  autoPlay: true,
  stopOnHover: true,
  interval: 5000,
};

const CarouselComponent = ({ className, items }) => {
  return (
    <Wrapper className={className}>
      <Carousel {...settings}>
        {
          items && items.length > 0 && items.map(({ link, image }, index) => {
            return link ? (
              <Link to={link} key={index}>
                <img src={image} alt={link} />
              </Link>
            ) : (
              <img key={index} src={image} alt="Carousel" />
            )
          })
        }
      </Carousel>
    </Wrapper>
  )
}

export default CarouselComponent;