import React from 'react';

import HeaderComponent from './HeaderComponent';
import { withCart } from '../../../context';

class HeaderContainer extends React.Component {
  render() {
    const { cart } = this.props;

    return (
      <HeaderComponent
        clearCartItem={cart.clearCartItem}
        cartItemCount={cart.items.length} />
    )
  }
}


export default withCart(HeaderContainer);