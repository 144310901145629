import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import { media } from '../../../../styles/breakpoints';
import { SimpleModal } from '../../../Common';
import { withPopup } from '../../../../context';
import Swipe from '../Swipe';

const Wrapper = styled.div`
  width: 50%;
  margin: auto;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.phone`
    width: 90%;
  `};
`;

const InputHeader = styled.div`
  width: 100%;
  margin-bottom: 5px;
  text-align: left;
  color: #808080;
`;

const Input = styled.input`
  margin-bottom: 20px;
`;

const Button = styled.div`
  width: 100%;
  margin-bottom: 20px;
  font-weight: 600;
  border: solid 1px #dadada;
  padding: 10px;
  background-color: #ebecf0;
  cursor: pointer;
`;

const ForgetPasswordWrapper = styled.div`
  cursor: pointer;
  color: #808080;
  text-align: right;
  width: 100%;
  margin-bottom: 20px;
`;

const LoginComponemt = ({ history, onPhoneLogin, togglePopup }) => {
  const [phone, setPhone] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [isSwiped, setIsSwiped] = React.useState('');
  const [openModal, setOpenModal] = React.useState(false);

  const onPhoneChange = React.useCallback((event) => {
    setPhone(event.target.value);
  }, [setPhone])

  const onPasswordChange = React.useCallback((event) => {
    setPassword(event.target.value);
  }, [setPassword])

  const onSwiped = React.useCallback((event) => {
    setIsSwiped(true);
  }, [setIsSwiped])

  const onSubmit = React.useCallback(async (e) => {
    e.preventDefault();
    
    if (!phone || !password) {
      togglePopup({
        type: 'info',
        message: '请输入帐号密码',
      });

      return;
    }

    if (!isSwiped) {
      togglePopup({
        type: 'info',
        message: '请先解锁滑动验证',
      });

      return;
    }

    const result = await onPhoneLogin(phone, password, isSwiped);

    if (!result || !result.success) {
      togglePopup({
        type: 'info',
        message: result ? result.message : '登入失败',
      });

      return;
    }
  }, [phone, password, isSwiped, setOpenModal, onPhoneLogin])

  const onCreateAcc = React.useCallback((e) => {
    e.preventDefault();
    
    history.push('/register');
  }, [history]);

  const closeModal = React.useCallback(() => {
    setOpenModal(false);
  }, [setOpenModal]);

  const onForgetPassword = React.useCallback((token) => {
    history.push('/forget-password');
  }, []);

  return (
    <Wrapper>
      <InputHeader>登入电话号码</InputHeader>
      <Input className="form-control" name="phone" value={phone} onChange={onPhoneChange} />
      <InputHeader>登入密码</InputHeader>
      <Input className="form-control" name="password" type="password" value={password} onChange={onPasswordChange} />
      <ForgetPasswordWrapper onClick={onForgetPassword}>忘记密码</ForgetPasswordWrapper>

      <Swipe onSuccess={onSwiped} />

      <Button onClick={onSubmit}>登入</Button>
      <Button onClick={onCreateAcc}>建立您的帐户</Button>

      <SimpleModal
        confirmMsg="登入号码或密码错误"
        visible={openModal}
        confirmCallback={closeModal} />
    </Wrapper>
  )
};

export default withRouter(
  withPopup(
    React.memo(LoginComponemt)
  )
);