import React from 'react';
import styled from 'styled-components';

const PhoneInputGrp = styled.div`
  margin-bottom: 15px;
`;

const Input = styled.input`
  margin-bottom: 15px;
  border-radius: 0px !important;
`;

const InputButton = styled.button`
  border-radius: 0px !important;
`;

const InputWithButton = ({ name, value, onChange, inputProps, btnText, onBtnClicked, disabled }) => {
  return (
    <PhoneInputGrp className="input-group">
      <Input className="form-control" name={name} value={value} onChange={onChange} disabled={disabled} {...inputProps} />
      <div className="input-group-append">
        <InputButton className="btn btn-outline-secondary" onClick={onBtnClicked}>{btnText}</InputButton>
      </div>
    </PhoneInputGrp>
  )
}

export default InputWithButton;