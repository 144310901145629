import React from 'react';
import styled from 'styled-components';

import CartItem from '../CartItems';

const CartItemWrapper = styled.div`
  margin-bottom: 40px;
`;

const CartItemHead = styled.div`
  display: flex;
  margin-bottom: 10px;
  padding: 14px 35px 14px 80px;

  background-color: #efefef;
`;

const ColumnHeader = styled.div`
  margin: 0 10px;
  flex: 1;
`;

const AmountRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const ConfirmBtn = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    background-color: #000;
    padding: 7px 35px;
    color: #fff;
    font-size: 14px;
  }
`;

const SuccessPage = ({ shoppingCartData, onNextStep, selectedProducts, onProductSelect }) => {
  const onSelect = React.useCallback((id, selected) => {
    onProductSelect(id, selected);
  }, [onProductSelect]);

  const onConfirmClicked = React.useCallback(() => {
    if (selectedProducts.length > 0) {
      onNextStep();
    }
  }, [selectedProducts, onNextStep]);

  return (
    <React.Fragment>
      <CartItemWrapper>
        <CartItemHead>
          <ColumnHeader>商品名称</ColumnHeader>
          <ColumnHeader>价格</ColumnHeader>
          <ColumnHeader>数量</ColumnHeader>
          <ColumnHeader>小计</ColumnHeader>
        </CartItemHead>

        { shoppingCartData && shoppingCartData.length > 0 && shoppingCartData.map((item, idx) => (
          <CartItem
            key={item.id}
            item={item}
            onSelect={onSelect}
            onQuantityChange={() => {}}
            selected={selectedProducts.includes(item.id)} />
        ))}
      </CartItemWrapper>

      <AmountRow>
        <div>{`共${2}件商品, 已选择${1}件`}</div>
        <div>{`${1}商品, 总金额  ￥${1234}`}</div>
      </AmountRow>

      <ConfirmBtn>
        <button onClick={onConfirmClicked}>确认并下单</button>
      </ConfirmBtn>
    </React.Fragment>
  )
}

export default React.memo(SuccessPage);