import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { media } from '../../../styles/breakpoints';

const Wrapper = styled.div`
  padding: 50px 100px 100px;
  text-align: left;
  background-color: ${props => props.hasBgColor ? '#f2f2f2' : '#fff' };

  ${media.tablet`
    padding: 35px 50px 60px;
  `};

  ${media.phone`
    background-color: ${props => props.hasMobileBgColor ? '#f2f2f2' : '#fff' };
    padding: 25px 10px;
  `};
`;

const HeaderRow = styled.div`
  margin-bottom: 30px;

  ${media.phone`
    margin-bottom: 20px;
  `};
`;

const Header = styled.h2`
  font-weight: bold;
  font-size: 26px;

  ${media.phone`
    font-size: 20px; 
  `}
`;

const SubHeader = styled.h3`
  font-size: 16px;

  margin-left: ${props => props.hasBackBtnLink ? '25px' : '0'};
`;

const BackBtn = styled.span`
  button {
    background: none;
    padding-right: 10px;
  }
`;

const MainContaineWrapper = styled.div`
`;

const PageContainer = ({ title, subTitle, children, hasBgColor, hasMobileBgColor, backBtnLink }) => {
  return (
    <Wrapper hasBgColor={hasBgColor} hasMobileBgColor={hasMobileBgColor}>
      { title &&
        <HeaderRow>
          <Header>
            { backBtnLink && 
              <BackBtn>
                <Link to={backBtnLink}>
                  <button>{'<'}</button>
                </Link>
              </BackBtn>
            }
            {title}
          </Header>
          { subTitle && <SubHeader hasBackBtnLink={!!backBtnLink}>{subTitle}</SubHeader> }
        </HeaderRow>
      }

      <MainContaineWrapper>
        { children }
      </MainContaineWrapper>
    </Wrapper>
  )
};

export default React.memo(PageContainer);