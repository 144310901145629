import React from 'react';
import { Switch } from "react-router-dom";

import RouteHandler from './RouteHandler';

const Router = ({ routes, privateRoutes }) => (
  <Switch>
    { createRoutes(routes) }

    { createPrivateRoutes(privateRoutes) }
  </Switch>
)

const constructRoutes = (pathPrefix, constRoutes) => {
  let paths = [];
  constRoutes.forEach(({ path, component, subRoutes }) => {
    const fullPath = `${pathPrefix}${path}`;
    paths.push({
      path: fullPath, component,
    });
    if (subRoutes && subRoutes.length) {
      paths = paths.concat(
        constructRoutes(fullPath, subRoutes)
      );
    }
  })

  return paths;
}

const createRoutes = (routes) => {
  return constructRoutes('', routes).map(({ path, component }, idx) => (
    <RouteHandler
      exact={true}
      key={idx}
      path={path}
      component={component}
    />
  ));
}

const createPrivateRoutes = (routes) => {
  return constructRoutes('', routes).map(({ path, component }, idx) => (
    <RouteHandler
      exact={true}
      key={idx}
      path={path}
      component={component}
      redirectTo="/"
    />
  ));
}

export default Router;