import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import WeiboGrid from '../WeiboGrid';

import { media } from '../../../../styles/breakpoints';

const COL_PER_ROW = 3;

const Wrapper = styled.div`
  display: flex;
  min-height: ${props => props.itemLen > 0 ? 'unset' : '500px'};

  ${media.phone`
    flex-direction: column;
  `}
`;

const GridColumn = styled.div`
  
`;

const GridItem = styled.div`
  margin-bottom: 30px;
`;

const GridDesc = styled.div`
  text-align: left;
  padding: 15px;
  background: #fff;
  font-size: 14px;

  h2 {
    font-size: 24px;
    color: #333;
    text-transform: uppercase;
  }

  p {
    color: #333;
  }
`;

const GridImage = styled.img`
  width: 100%;
`;

const renderGrids = gridItems => {
  let grids = []

  for (let i = 0; i < COL_PER_ROW; i++) {
    grids.push(
      <GridColumn key={i} className="col-sm-6 col-md-4">
        { renderGridColumn(i, gridItems) }
      </GridColumn>
    );
  }

  return grids;
};

const renderGridColumn = (col, gridItems) => {
  let gridColumn = [];

  for (let i = col; i < gridItems.length; i += COL_PER_ROW) {
    const gridItem = gridItems[i];

    if (gridItem === 'weibo') {
      gridColumn.push(
        <GridItem key={i}>
          <WeiboGrid />
        </GridItem>
      );
    } else {
      gridColumn.push(
        <GridItem key={i}>
          <Link to={gridItem.link}>
            <GridImage src={gridItem.image} alt="Banner" />
            <GridDesc>
              <h2>{gridItem.header}</h2>
              <p>{gridItem.content}</p>
              <span>Learn more</span>
            </GridDesc>
          </Link>
        </GridItem>
      );
    }
  }
  
  return gridColumn;
};

const GridList = ({ gridItems }) => (
  <Wrapper itemLen={gridItems ? gridItems.length : 0}>
    { renderGrids(gridItems) }
  </Wrapper>
);

export default GridList;