import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import ReactSwipe from 'react-swipe';

import { withPopup, withAuth } from '../../../../context';
import { media, MobileOnly, TabletAndDesktop } from '../../../../styles/breakpoints';
import config from '../../../../config';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 200px;
  border-bottom: solid 1px #ccc;
  margin-bottom: 20px;
  padding-bottom: 10px;
`;

const HeaderWrapper = styled.div`
  text-align: left;
  display: flex;
  justify-content: space-between;

  ${media.phone`
    flex-direction: ${props => props.hideSeriesImage ? 'column': 'row'};
  `};
`;

const ButtonWrapper = styled.div`
  ${media.phone`
    ${props => props.hideSeriesImage ? css`
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;
    ` : ''}
  `};
`;

const CtrlBtn = styled.button`
  margin-left: 10px;
  padding: 3px 19px;

  font-size: 15px;
  border-radius: 20px;
  border: solid 1px #ccc;
  background-color: #fff;
  color: #000;

  ${media.phone`
    padding: 3px 10px;
    font-size: 12px;
  `};
`;

const ProductSeries = styled.div`
  width: 130px;
  height: 26px;
  margin-bottom: 10px;

  img {
    width: 100%;
  }

  ${media.phone`
    width: 100px;
  `};
`;

const ProductName = styled.div`
  text-align: left;

  ${media.phone`
    font-size: 14px;
    order: ${props => props.hideSeriesImage ? 1 : ''};
  `};
`;

const ProductImage = styled.div`
  position: relative;
  margin: 15px auto;
  display: flex;
  align-items: center;
  cursor: pointer;
  min-height: 140px;
  min-width: 720px;

  ${media.tablet`
    min-height: unset;
    min-width: unset;

    img {
      width: 100%;
    }
  `};

  ${media.phone`
    padding: 3px 10px;
    font-size: 12px;
    min-height: unset;
    min-width: unset;
    width: 100%;

    img {
      width: 315px;
    }
  `};
`;

const ColorWrapper = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const ColorIndicator = styled.div`
  width: 26px;
  height: 26px;
  background-color: ${props => `#${props.colorCode}`};
  background-image: ${props => `url('/images/productlist/${props.selected ? 'img_color_filter_selected' : 'img_color_filter'}.png')`};
`;

const ColorImage = styled.img`
  padding: 3px;
  width: 25px;
  height: 25px;
  border: ${props => `solid 1px ${props.selected ? '#c6c7c9' : 'transparent'}`};
  border-radius: 50%;
`;

const ColorText = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  font-size: 14px;

  ${media.phone`
    font-size: 12px;
  `};
`;

const EnhancedReactSwipe = styled(ReactSwipe)`
  width: 100%;
`;

const renderProductHeader = (product, onAddToCart, onBuyDirectly, hideSeriesImage) => {
  const ProductNameComp = (
    <ProductName hideSeriesImage={hideSeriesImage}>
      {product.name}
    </ProductName>
  );

  const ButtonWrapperComp = (
    <ButtonWrapper hideSeriesImage={hideSeriesImage}>
      <CtrlBtn onClick={onAddToCart}>加入购物车</CtrlBtn>
      <CtrlBtn onClick={onBuyDirectly}>直接购买</CtrlBtn>
    </ButtonWrapper>
  );

  return (
    <React.Fragment>
      { hideSeriesImage ? (
          <HeaderWrapper hideSeriesImage>
            { ProductNameComp }
            { ButtonWrapperComp }
          </HeaderWrapper>
        ) : (
            <React.Fragment>
              <HeaderWrapper>
                <ProductSeries>
                <Link to={`/productseries?id=${product.seriesId}`}>
                  <img src={`${config.imagePrefix}/productseries/${product.seriesImage}`} alt="series" />
                </Link>
              </ProductSeries>
              { ButtonWrapperComp }
            </HeaderWrapper>
            { ProductNameComp }
          </React.Fragment>
      )}
    </React.Fragment>
  )
}

const ProductItem = ({ product, addItemToCart, togglePopup, history, hideSeriesImage, auth }) => {
  const setColorTimeout = React.useRef();
  const reactSwipeEl = React.useRef();
  const [selectedColorIdx, setSelectedColorIdx] = React.useState(0);

  const onChangeItemColor = React.useCallback((idx) => {
    if (setColorTimeout.current) {
      clearTimeout(setColorTimeout.current);
    }

    setColorTimeout.current = setTimeout(() => { 
      setSelectedColorIdx(idx);
    }, 300);
    
    if (reactSwipeEl && reactSwipeEl.current && reactSwipeEl.current.slide) {
      reactSwipeEl.current.slide(idx);
    }
  }, [setSelectedColorIdx]);
  
  const selectedColorObj = (product.color && product.color[selectedColorIdx]) || {};
  const productUrl = `/product?id=${product.id}&colorId=${selectedColorObj.colorId}`

  const onAddToCart = React.useCallback(async () => {
    if (!auth.isAuthed()) {
      togglePopup({
        type: 'info',
        message: '请先登入',
        confirmHandler: () => history.push('/login')
      })

      return;
    }
    
    await addItemToCart(product.id, selectedColorObj.colorId, 1);

    togglePopup({
      type: 'info',
      message: '已成功加到购物车'
    })
  }, [addItemToCart, product, selectedColorObj]);
  
  const onBuyDirectly = React.useCallback(async() => {
    if (!auth.isAuthed()) {
      togglePopup({
        type: 'info',
        message: '请先登入',
        confirmHandler: () => history.push('/login')
      })

      return;
    }

    await addItemToCart(product.id, selectedColorObj.colorId, 1);

    history.push('/cart');
  }, [addItemToCart, product, selectedColorObj]);

  const swipeOptions = React.useMemo(() => ({
    callback: (idx) => {
      setSelectedColorIdx(idx);
    },
    continuous: false
  }), [])

  return (
    <Wrapper>
      { renderProductHeader(product, onAddToCart, onBuyDirectly, hideSeriesImage) }

      <TabletAndDesktop>
        <ProductImage>
          <Link to={productUrl}>
            <img src={`${config.imagePrefix}/productbanner/${selectedColorObj.image}`} alt="" />
          </Link>
        </ProductImage>
        <ColorWrapper>
          { product.color && product.color.map(({ colorCode, colorId, colorImg }, idx) => (
            <div key={colorId}>
              <Link to={`/product?id=${product.id}&colorId=${colorId}`}>
                {colorImg ? (
                  <ColorImage
                    selected={idx === selectedColorIdx}
                    src={`${config.imagePrefix}/productbanner/${colorImg}`}
                    alt=""
                    onMouseOver={() => onChangeItemColor(idx)} />
                ) : (
                  <ColorIndicator
                    selected={idx === selectedColorIdx}
                    colorCode={colorCode}
                    onMouseOver={() => onChangeItemColor(idx)} />
                )}
              </Link>
            </div>
          ))}
        </ColorWrapper>
      </TabletAndDesktop>
      <MobileOnly>
        <ProductImage>
          <EnhancedReactSwipe
            ref={reactSwipeEl}
            swipeOptions={swipeOptions}
            childCount={product.color.length}>
            { product.color && product.color.map((colorObj) => (
              <Link to={productUrl} key={colorObj.colorId}>
                <img src={`${config.imagePrefix}/productbanner/${colorObj.image}`} alt="" />
            </Link>
            ))}
          </EnhancedReactSwipe>
        </ProductImage>

        <ColorWrapper>
          { product.color && product.color.map(({ colorCode, colorId, colorImg }, idx) => (
            <div key={colorId}>
                {colorImg ? (
                  <ColorImage
                    selected={idx === selectedColorIdx}
                    src={`${config.imagePrefix}/productbanner/${colorImg}`}
                    alt=""
                    onMouseOver={() => onChangeItemColor(idx)} />
                ) : (
                  <ColorIndicator
                    selected={idx === selectedColorIdx}
                    colorCode={colorCode}
                    onMouseOver={() => onChangeItemColor(idx)} />
                )}
            </div>
          ))}
        </ColorWrapper>
      </MobileOnly>
      <ColorText>{ selectedColorObj.colorName }</ColorText>
    </Wrapper>
  );
}

export default React.memo(
  withPopup(
    withRouter(
      withAuth(ProductItem)
    )
  )
);