import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';

import config from '../../config';
import CartComponent from './CartComponent';
import { withPopup, withCart } from '../../context';
import { checkIsMobile } from '../../utils';

class CartContainer extends React.Component {
  state = {
    shoppingCartData: {},
    userData: {},
    accessories: [],
    transportFee: {}
  }

  async componentDidMount() {
    const result = await this.getPageData();

    const { shoppingCartData, userData, accessories, transportFee } = result ? result : { shoppingCartData: {}, userData: {}, accessories: [], transportFee: {} };

    this.setState({ shoppingCartData, userData, accessories, transportFee });
  }

  getPageData() {
    const fetchUrl = `${config.serverEndPoint}/cartPage`;

    return axios.get(fetchUrl)
      .then(result => result.data)
      .catch(err => {
        console.log(err);
      });
  }

  onRemoveProduct = async (productId, colorId) => {
    const { togglePopup } = this.props;
    const { shoppingCartData } = this.state;

    try {
      const removeUrl = `${config.serverEndPoint}/shopping-cart?productId=${productId}&colorId=${colorId}`;

      await axios.delete(removeUrl);

      const newShoppingCartData = shoppingCartData.filter((cartItem) => !(cartItem.productid === productId && cartItem.colorid === colorId));

      this.setState({
        shoppingCartData: newShoppingCartData
      });
    } catch (e) {
      console.log(e);
      togglePopup({
        type: 'info',
        message: '删除失败, 请检查你的网络'
      })
    }
  }

  addAddress = (addressData) => {
    const createUrl = `${config.serverEndPoint}/userAddress`;

    return axios.post(createUrl, addressData);
  }

  onQuantityChange = (id, value) => {
    const { shoppingCartData } = this.state;

    this.setState({
      shoppingCartData: shoppingCartData.map((item) => {
        return {
          ...item,
          quantity: item.id === id ? value : item.quantity
        };
      })
    })
  }

  onCheckout = async (deliveryAddress, shoppingCartData, selectedPayment, remark) => {
    const { togglePopup, history, cart } = this.props;

    console.log('onCheckout', deliveryAddress, shoppingCartData, selectedPayment, remark);

    // Check for mobile for wechatM5
    const isMobile = checkIsMobile();
    const paymentType = selectedPayment === 0
      ? isMobile ? 'wechatH5' : 'wechat'
      : 'alipay';

    const checkoutUrl = `${config.serverEndPoint}/checkout`;

    try {
      const result = await axios.post(checkoutUrl, {
        deliveryAddress,
        shoppingCartData,
        paymentType,
        remark
      }).then(result => result.data);

      if (result.error) {
        togglePopup({
          type: 'info',
          message: result.message,
        })  
      } else {
        // Both wechat and wechatH5 will be handled in the same page
        if (paymentType === 'wechat' || paymentType === 'wechatH5') {
          cart.updateCartItem();
          history.push(`/cart/wechatPay?id=${result.id}`);
        } else if (paymentType === 'alipay') {
          window.location = result.url;
        }
      }
    } catch (e) {
      console.log(e);
      togglePopup({
        type: 'info',
        message: '下单失败, 请检查你的网络',
      })
    }
  }

  render() {
    const { shoppingCartData, userData, accessories, transportFee } = this.state;

    return (
      <CartComponent
        onCheckout={this.onCheckout}
        addAddress={this.addAddress}
        userData={userData}
        onQuantityChange={this.onQuantityChange}
        shoppingCartData={shoppingCartData}
        onRemoveProduct={this.onRemoveProduct}
        accessories={accessories}
        transportFee={transportFee} />
    )
  }
};

export default withRouter(
  withPopup(
    withCart(CartContainer)
  )
);

