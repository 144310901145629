import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import qs from 'query-string';
import { Link } from 'react-router-dom';

import { PEN_TYPE_LIST, ACCESSORT_TYPE_LIST } from '../../../../constants';
import { media } from '../../../../styles/breakpoints';

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 50px;

  ${media.phone`
    margin-bottom: 30px;
  `};
`;

const Tab = styled.div`
  width: 160px;
  height: 40px;
  text-align: center;
  font-size: 18px;
  line-height: 40px;
  cursor: pointer;
  margin-right: 15px;
  
  background-color: ${props => props.selected ? '#cccacb' : '#f2f0f1' };
  
  :hover {
    color: #fff;
    background-color: #cccacb;
  }

  
  ${media.tablet`
    width: 150px;
    height: 40px;
  `};

  ${media.phone`
    width: 60px;
    height: 30px;
    line-height: 30px;
    font-size: 16px;
  `};
`;

const TypeTab = ({ location, categoryId }) => {
  const parsed = qs.parse(location.search) || {};
  const id = parsed.id || 1;
  const selectedIdx = parsed.type ? parseInt(parsed.type) : 0;
  const typeList = categoryId === 8 ? ACCESSORT_TYPE_LIST : PEN_TYPE_LIST;

  return (
    <Wrapper>
      { typeList.map((type, idx) => (
        <Link key={idx} to={`${location.pathname}?id=${id}&type=${idx}`}>
          <Tab selected={idx === selectedIdx}>
            { type }
          </Tab>
        </Link>
      ))}
    </Wrapper>
  )
};

export default withRouter(
  React.memo(TypeTab)
);