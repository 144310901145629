import React from 'react';
import styled from 'styled-components';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { ProductFooter } from '../Common';

const Wrapper = styled.section`
background-color: #fff;
padding : 50px;
text-align: left;
color: gray;
`;


const Title = styled.div`
  font-size: 24pt;
  padding: 5px 0px 15px 0px;
`;

const CareComponent = ({getItemStyle,state, handleC1Click,handleC2Click,handleC3Click,handleC4Click}) => (
  <Wrapper>
    <Title>保养须知</Title>
    <List
      component="nav"
      className={'classes.root'}
    >
      <hr />
      <ListItem button onClick={() =>handleC1Click()}>
        <ListItemText inset primary="保养维护" style={getItemStyle()}/>
        {state.C1 ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={state.C1} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem className={'classes.nested'}>
            <ListItemText>
              定期清洗、正常使用状况每三个月应清洗一次；超过1个月以上时间暂不使用的钢笔，笔应彻底清洗擦干，以免墨水干滞而堵塞出水管线而影响钢笔使用寿命
              <br />
              <br />
              A. 使用吸墨器清洗
              <br />
              准备干净的温水、将温水填充入吸墨器，置于钢笔后重复吸墨与释出的动作，直到释出的温水不再浑浊并用面纸擦拭即可。
              <br />
              <br />
              B. 无吸墨器清洗（活塞式内置储墨器笔除外）
              <br />
              将前端缓缓转出并置于清水下冲洗使清水自然流入导墨装置后自然释出，直到释出的温水不再浑浊并用面纸擦拭即可。
              <br />
              <br />
              C. 活塞式内置储墨器笔
              <br />
              准备干净温水，将温水以正常入墨及释出的方式清洗，直到释出的温水不再浑浊再用面纸擦拭即可。
              <br />
              <br />
              - 钢笔使用完毕应随手将笔盖盖上，以保护笔尖，携带时笔尖朝上；
              <br />
              - 吸墨器长时间使用时会造成磨损间隙，因而产生漏水现象，此时应购买更换新的吸墨器即可；
              <br />
              - 金属笔身的金属表面氧化是自然现象因此需要经常用干净棉布擦拭。
              <br />
            </ListItemText>
          </ListItem>
        </List>
      </Collapse>
      <hr />
      <ListItem button onClick={() => handleC2Click()}>
        <ListItemText inset primary=" 墨水填充范例" style={getItemStyle()}/>
        {state.C2 ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={state.C2} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem className={'classes.nested'}>
            <ListItemText>
              A. 如何使用T10一次性水芯
              <br />
              将粗短的那头塞入笔握部分，旋转上笔杆，将笔尖朝下，然后轻甩一下笔身，以便将水芯中的墨水引导至笔尖，就可以享受LAMY带来的美好书写感受了
              <br />
              <br />
              B. 如何使用吸墨器
              <br />
              Z24吸墨器（即红色吸墨器）有2个卡点，对准笔握部分的槽内卡紧即可使用；
              <br />
              Z25（黑色吸墨器）为优雅系列专用吸墨器，需旋转插入笔握；
              <br />
              Z26（黑色吸墨器）直接插入笔握即可
              <br />
              <br />
              C. 内置储墨器活塞式钢笔（LAMY2000）
              <br />
              以旋转活塞方式吸墨
              <br />
              <br />
              D. 一般钢笔墨水构成有三部分：水、色素和胶质。如果胶质太多墨水容易渐渐停在下水管，建议使用LAMY专用的墨水，长久使用不会产生堵塞、断水和变色的情况。
              <br />
              <br />
              <img src="./images/customerservice/img_care4.jpg" alt=""/>
            </ListItemText>
          </ListItem>
        </List>
      </Collapse>
      <hr />
      <ListItem button onClick={() => handleC3Click()}>
        <ListItemText inset primary=" 宝珠笔日常使用" style={getItemStyle()}/>
        {state.C3 ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={state.C3} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem className={'classes.nested'}>
            <ListItemText>
            宝珠笔也就是平常所用的中性笔，需要放入对应型号的LAMY专用宝珠笔芯才可使用，LAMY的宝珠笔芯统一为0.7mm左右粗细； 
            <br/>
            <br/>
            宝珠笔是水性的，兼有钢笔和油性圆珠笔的特点，书写圆润流畅，线条均匀，是日常办公和签字的最佳伴侣； 
            <br/>
            <br/>            
            替换宝珠笔芯，只需拧开笔杆，取出旧笔芯，再放入新笔芯，接着将笔杆拧回原位就可以书写了。 
            <br/>
            <br/>
            </ListItemText>
          </ListItem>
        </List>
      </Collapse>
      <hr />
      <ListItem button onClick={() => handleC4Click()}>
        <ListItemText inset primary=" 多功能笔的笔芯更换" style={getItemStyle()}/>
        {state.C4 ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={state.C4} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem className={'classes.nested'}>
            <ListItemText>
            将笔杆前端旋转出后把所需更换的笔芯往前轻拉出更换即可 
            <br/>
            <br/>
            <img src="./images/customerservice/img_care7.jpg" alt=""/>
            </ListItemText>
          </ListItem>
        </List>
      </Collapse>
    </List>
    <ProductFooter/>
  </Wrapper>
);


export default CareComponent;
