import React from 'react';
import styled from 'styled-components';
import Modal from 'react-bootstrap4-modal';

const ModalBodyHeader = styled.h4`
  font-size: 18px;
`;

const { Consumer, Provider } = React.createContext();

class PopupContext extends React.Component {
  state = {
    showPopup: false,
    type: '',
    header: '',
    message: '',
    confirmHandler: null,
  }

  constructor(props) {
    super(props);

    this.ctx = {
      togglePopup: this.togglePopup
    }
  }

  // type: 'confirm' || 'info'
  togglePopup = ({ type, header, message, confirmHandler }) => {
    this.setState({
      showPopup: true,
      type: type,
      header: header,
      message: message,
      confirmHandler: confirmHandler,
    });
  }

  onConfirm = () => {
    const { confirmHandler } = this.state;

    if (confirmHandler) {
      confirmHandler();
    }

    this.closePopup();
  }

  closePopup = () => {
    this.setState({ showPopup: false });
  }

  // Clear cart item for logout
  clearCartItem = () => {
    this.setState({ items: [] });
  }

  render() {
    const { children } = this.props;
    const { type, showPopup, message, header } = this.state;

    return (
      <Provider value={this.ctx}>
        {children}

        <Modal visible={showPopup} onClickBackdrop={this.closePopup} dialogClassName="confirm-modal-dialog">
          <div className="modal-header">
            <h5 className="modal-title">{header}</h5>
          </div>
          <div className="modal-body">
            <ModalBodyHeader>{message}</ModalBodyHeader>
          </div>
          <div className="modal-footer">
            { type === 'confirm' && 
              <button type="button" className="btn btn-secondary" onClick={this.closePopup}>
                取消
              </button>
            }
            <button type="button" className="btn btn-primary" onClick={this.onConfirm}>
              确认
            </button>
          </div>
        </Modal>
      </Provider>
    )
  }
}

const withPopup = (Component) => (props) => (
  <Consumer>
    { ({ togglePopup }) => <Component togglePopup={togglePopup} {...props}/> }
  </Consumer>
);
  
export {
  PopupContext,
  Consumer as PopupConsumer,
  withPopup
}