export const ORDER_STATUS = {
  PENDING: '等待交易',
  EXPIRED: '订单已过期',
  PAID: '已付款',
  SENT: '已出货',
  REVOKE: '取消',
  RETURN: '申请退/换货',
};

export const PEN_TYPE_LIST = [ '全部', '高端', '商务', '年轻' ];
export const ACCESSORT_TYPE_LIST = [ '全部', '彩鉛', '水彩' ];


export const NAME_REGEX = /^([\u4e00-\u9fa5]{1,20}|[a-zA-Z| ]{1,30})$/;
export const PHONE_REGEX = /^\d{11}$/;
export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const ORDER_EXPIRE_TIME = 60 * 60 * 2 * 1000;  // 2 hours

export const COURIER_ID_MAP = {
  "1": "顺丰速运",
  "2": "申通快递",
  "3": "圆通速递",
  "4": "中通速递",
  "5": "汇通快运",
  "6": "韵达快运",
  "7": "EMS快递"
};