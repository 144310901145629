import React from 'react';
import styled from 'styled-components';

import { CaptchaValidateForm } from '../Common';
import { media } from '../../styles/breakpoints';
import { withPopup } from '../../context';
import { NAME_REGEX, PHONE_REGEX } from '../../constants';

const Wrapper = styled.div`
  background-color: #fff;
  padding: 50px 100px 100px;

  ${media.phone`
    padding: 25px 15px;
  `};
`;

const MainContaineWrapper = styled.div`
  background-color: #f2f2f2;
`;

const MainContainer = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f2f2f2;
`;

const Header = styled.div`
  text-align: left;
  font-weight: 600;
  font-size: 26px;
  margin-bottom: 30px;
`;

const InputHeader = styled.div`
  width: 100%;
  margin-bottom: 5px;
  text-align: left;
  color: #808080;
`;

const Input = styled.input`
  margin-bottom: 15px;
  border-radius: 0px !important;
`;

const Button = styled.button`
  width: 100%;
  margin-top: 50px;
  font-weight: 600;
  border: solid 1px #dadada;
  padding: 10px;
  background-color: #ebecf0;
  cursor: pointer;
`;

const Text = styled.div`
  width: 100%;
  color: #929292;
  font-size: 15px;
  margin-bottom: 15px;
  text-align: left;

  p {
    margin-bottom: 5px;
  }
`;

const validateForm = (firstName, lastName, password, password2, addressReceiver, address, addressPhone) => {
  if (firstName === '' || lastName === '' || password === '' || password2 === '' || addressReceiver === '' || address === '' || addressPhone === '') {
    return '请填寫所有資料';
  }
  if (!NAME_REGEX.test(firstName) || !NAME_REGEX.test(lastName) || !NAME_REGEX.test(addressReceiver)) {
    return '姓名必需是中/英字, 不能有数字或其他符号';
  }
  if (password !== password2) {
    return '两次输入的密码不一致';
  }
  if (password.length < 6) {
    return '密码不能少于6个字符';
  }

  if (!PHONE_REGEX.test(addressPhone)) {
    return '电话号码必需為11個字';
  }

  return;
}

const RegisterPageComponent = ({ onRegister, togglePopup }) => {
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [password2, setPassword2] = React.useState('');
  const [addressReceiver, setAddressReceiver] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [addressPhone, setAddressPhone] = React.useState('');

  const onFirstNameChange = React.useCallback((event) => {
    setFirstName(event.target.value);
  }, [setFirstName]);

  const onLastNameChange = React.useCallback((event) => {
    setLastName(event.target.value);
  }, [setLastName]);

  const onPasswordChange = React.useCallback((event) => {
    setPassword(event.target.value);
  }, [setPassword])

  const onPassword2Change = React.useCallback((event) => {
    setPassword2(event.target.value);
  }, [setPassword2])

  const onAddressReceiverChange = React.useCallback((event) => {
    setAddressReceiver(event.target.value);
  }, [setAddressReceiver])

  const onAddressChange = React.useCallback((event) => {
    setAddress(event.target.value);
  }, [setAddress])

  const onAddressPhoneChange = React.useCallback((event) => {
    setAddressPhone(event.target.value);
  }, [setAddressPhone])

  const onSubmit = React.useCallback(async (pin, phone) => {
    const formError = validateForm(firstName, lastName, password, password2, addressReceiver, address, addressPhone);

    if (formError) {
      togglePopup({
        type: 'info',
        message: formError
      })

      return;
    }

    onRegister({
      pin, phone, firstName, lastName, password, password2, addressReceiver, address, addressPhone
    });
  }, [onRegister, firstName, lastName, password, password2, addressReceiver, address, addressPhone])

  return (
    <Wrapper>
      <Header>LAMY 注册</Header>
      <MainContaineWrapper>
        <CaptchaValidateForm enablePhoneInput authHeader="手机认证" formHeader="填写资料" validatePhoneRoutePrefix="register">
          {({ pin, phone }) => (
            <MainContainer>
              <Text>
                <p>请填写以下资料以完成注册
                </p>
                <p>电话号码: {phone}</p>
              </Text>
              <InputHeader>姓</InputHeader>
              <Input className="form-control" name="lastName" value={lastName} onChange={onLastNameChange} />
              <InputHeader>名</InputHeader>
              <Input className="form-control" name="firstName" value={firstName} onChange={onFirstNameChange} />
              <InputHeader>密码</InputHeader>
              <Input className="form-control" type="password" name="password" value={password} onChange={onPasswordChange} />
              <InputHeader>再次输入密码</InputHeader>
              <Input className="form-control" type="password" name="password2" value={password2} onChange={onPassword2Change} />
              <InputHeader>常用送货地址</InputHeader>
              <Input className="form-control" name="address" value={address} onChange={onAddressChange} />
              <InputHeader>常用送货地址收货人 (收件人必须为实名, 否则不会发货)</InputHeader>
              <Input className="form-control" name="addressReceiver" value={addressReceiver} onChange={onAddressReceiverChange} />
              <InputHeader>常用送货地址收货人电话号码</InputHeader>
              <Input className="form-control" name="addressPhone" value={addressPhone} onChange={onAddressPhoneChange} />

              <Button onClick={() => onSubmit(pin, phone)}>注册</Button>
            </MainContainer>
          )}
        </CaptchaValidateForm>
      </MainContaineWrapper>
    </Wrapper>
  )
};

export default withPopup(RegisterPageComponent);