import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';

import { getIntQueryStringByKey } from '../../utils';
import config from '../../config';
import OrderReturnRequestComponent from './OrderReturnRequestComponent';
import { withPopup } from '../../context';

class OrderDetailContainer extends React.Component {
  sendReturnRequest = async ({ type, comment, images }) => {
    const { location, togglePopup, history } = this.props;
    try {
      const orderId = getIntQueryStringByKey(location.search, 'id', 1);

      await axios.put(`${config.serverEndPoint}/return-request`, {
        id: orderId,
        type, comment, images
      });

      togglePopup({
        type: 'info',
        header: '申请成功',
        message: '我们会详细检视您提交的资料，并进一步跟您联络',
      });

      history.push(`/orderDetail?id=${orderId}`);
    } catch (e) {
      togglePopup({
        type: 'info',
        message: '申请失败，出货10天内才能申请退/换货'
      });
    }
  }

  render() {
    const { location } = this.props;
    const orderId = getIntQueryStringByKey(location.search, 'id', 1);
    const courierNo = getIntQueryStringByKey(location.search, 'courierNo', 1);

    return (
      <OrderReturnRequestComponent
        sendReturnRequest={this.sendReturnRequest}
        orderId={orderId}
        courierNo={courierNo} />
    )
  }
};

export default withRouter(
  withPopup(
    OrderDetailContainer
  )
);

