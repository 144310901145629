import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';

import config from '../../config';
import UserComponent from './UserComponent';
import { withPopup } from '../../context';

class UserContainer extends React.Component {
  state = {
    userData: {},
  };

  async componentDidMount() {
    const userData = await this.getUserData();

    this.setState({ userData });
  }

  confirmDeleteAddress = (addressId) => {
    const { togglePopup } = this.props;

    togglePopup({
      type: 'confirm',
      header: '确认删除地址',
      message: '您确认要删除地址吗？',
      confirmHandler: () => this.deleteAddress(addressId)
    })
  }
  
  deleteAddress = async (addressId) => {
    const { userData } = this.state;
    try {
      const updateUrl = `${config.serverEndPoint}/userAddress/${addressId}`;

      await axios.delete(updateUrl);

      this.setState({
        userData: {
          ...userData,
          addresses: userData.addresses.filter((address) => address.id !== addressId)
        }
      })
    } catch (e) {
      console.log(e);
    }
  }

  getUserData = () => {
    const fetchUrl = `${config.serverEndPoint}/userPage`;

    return axios.get(fetchUrl)
      .then(result => result.data)
      .catch(err => {
        console.log(err);
      });
  }

  updateUserData = async (userData) => {
    const { togglePopup } = this.props;
    try {
      const updateUrl = `${config.serverEndPoint}/userPage`;

      await axios.put(updateUrl, userData);

      togglePopup({
        type: 'info',
        message: '个人资料更新成功',
      })

      const newUserData = await this.getUserData();

      this.setState({ userData: newUserData });
    } catch (e) {
      console.log(e);

      togglePopup({
        type: 'info',
        message: '个人资料更新失败',
      })
    }
  }

  getPageData() {
    const fetchUrl = `${config.serverEndPoint}/userPage`;

    return axios.get(fetchUrl)
      .then(result => result.data)
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    const { history } = this.props;
    const { userData } = this.state;

    return (
      <UserComponent
        userData={userData}
        updateUserData={this.updateUserData}
        deleteAddress={this.confirmDeleteAddress}
        history={history} />
    )
  }
};

export default withRouter(
  withPopup(
    UserContainer
  )
);

