import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import footerData from './footerData';
import { media } from '../../../styles/breakpoints';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 70px 0 10% 0;
  ${media.tablet`
    padding: 1em;
  `};

  ${media.phone`
    padding: 1em;
    flex-direction: column;
  `};
`;

const ColWrapper = styled.div`
  display: block;
  width: 30%;
  text-align: left;

  ${media.phone`
    width: 100%;
    margin-bottom: 30px;
  `};
`;

const ColHeader = styled.div`
  border-bottom: solid 1px #ccc;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-size: 12pt;
  color: #000;

  ${media.phone`
    font-size: 16pt;
  `};
`;

const ColItems = styled.div`
  padding: 5px 0;
  font-size: 10pt;

  a {
    color: #336699;
  }
`;

const ItemText = styled.span`
  margin-left: 5px;
`;

const CompanyFooter = () => {
  return (
    <Wrapper>
      { footerData && footerData.length > 0 && footerData.map((col, footerIdx) => (
        <ColWrapper key={footerIdx}>
          <ColHeader>{col.header}</ColHeader>
            { col.items && col.items.length > 0 && col.items.map((item, colIdx) => (
              <ColItems key={colIdx}>
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAIAAABvrngfAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAC5JREFUeNpiOHPmzH9UwADEyKJANgOchS4EFwWSjCDNMHD27FkgyQJnwQFAgAEA7OlLWu6GOC0AAAAASUVORK5CYII=" alt=""/>
                <ItemText>
                  <Link to={item.to}>
                    {item.name}
                  </Link>
                </ItemText>
              </ColItems>
            ))}
        </ColWrapper>
      ))}
    </Wrapper>
  )
}

export default CompanyFooter;