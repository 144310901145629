import React from 'react';
import styled from 'styled-components';
import compose from 'lodash/fp/compose';

import { connect, bindActionCreator } from 'context/chat/chatContext';
import { openWidget, sendMessage } from 'context/chat/chatRedux';
import { ProductFooter, ProductShowcase } from '../Common';
import { withPopup, withAuth } from '../../context';
import { media } from '../../styles/breakpoints';

import config from 'config';

import { AccessorySection, RelatedSection } from './components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  align-items: center;
`;

const ContentWrapper = styled.div`
  width: 100%;
  padding: 40px 120px;

  ${media.phone`
    padding: 20px;
  `};
`;

const NameWrapper = styled.div`
  text-align: left;
  font-size: 34px;
  margin-bottom: 60px;
  color: #999;

  ${media.phone`
    font-size: 20px;
    margin-bottom: 20px;
  `};
`;

const DescriptionWrapper = styled.div`
  text-align: left;
  margin-bottom: 60px;
  white-space: pre-line;
  font-size: 14px;
  line-height: 200%;
`;

const Description1 = styled.div`
  margin-bottom: 10px;

  ${media.phone`
    margin-bottom: 20px;
  `};
`;

const Description2 = styled.div`
  margin-bottom: 40px;

  ${media.phone`
    margin-bottom: 20px;
  `};
`;

const Remark = styled.div`
  color: red;
  margin-bottom: 30px;
`;

const Price = styled.div`
  ${media.phone`
    font-size: 16px;
  `};

  text-decoration: ${props => props.isVip ? 'line-through' : 'none'};
  margin-bottom: ${props => props.isVip ? '10px' : '30px'};
`;

const SelectQtyWrapper = styled.div`
  margin-bottom: 5px;

  select {
    width: 50px;
    height: 25px;
    color: #000;
    border: 1px solid gray;
  }

  span {
    &:nth-child(2) {
      margin-left: 20px;
    }

    ${media.phone`
      font-size: 14px;
    `};
  }

  ${media.phone`
    font-size: 16px;
  `};
`;

const ButtonRow = styled.div`

`;

const Button = styled.button`
  width: 126px;
  height: 40px;
  margin-right: 15px;
  margin-top: 35px;
  margin-bottom: 30px;
  background-color: #dddddd;

  ${media.phone`
    margin-top: 15px;
    margin-right: 14px;
    margin-bottom: 15px;
  `};
`;

const CsButton = styled(Button)`
  ${media.phone`
    width: 100px;
    height: 30px;
  `};
`;

const PurchaseLimit = styled.div`
  line-height: 1;
  color: #456eec;
`;

const handleAddToCartErrorIfExist = (selectedQty, product, togglePopup, auth, history) => {
  if (!auth.isAuthed()) {
    togglePopup({
      type: 'info',
      message: '请先登入',
      confirmHandler: () => history.push('/login')
    })
    return false;
  }

  if (product.purchaseLimit !== 0 && product.purchaseLimit < selectedQty) {
    togglePopup({
      type: 'info',
      message: '购买数量大于上限'
    })
    return false;
  }

  return true;
}


const ProductPageComponent = ({
  product,
  accessories,
  relatedProducts,
  productId,
  colorId,
  history,
  addItemToCart,
  togglePopup,
  auth,
  chatServerOnline,
  openWidget,
  sendMessage,
}) => {
  const [selectedQty, setSelectedQty] = React.useState(1);

  const handleChange = React.useCallback((event) => {
    setSelectedQty(event.target.value);
  }, [setSelectedQty])

  const onAddToCartBtnClicked = React.useCallback(async () => {
    const quantity = selectedQty ? parseInt(selectedQty) : 0;

    if (!handleAddToCartErrorIfExist(quantity, product, togglePopup, auth, history)) {
      return;
    }

    await addItemToCart(productId, colorId, parseInt(quantity));

    togglePopup({
      type: 'info',
      message: '已成功加到购物车'
    })
  }, [productId, colorId, addItemToCart, selectedQty, product, auth])

  const buyDirectly = React.useCallback(async () => {
    const quantity = selectedQty ? parseInt(selectedQty) : 0;

    if (!handleAddToCartErrorIfExist(quantity, product, togglePopup, auth, history)) {
      return;
    }

    await addItemToCart(productId, colorId, quantity);

    history.push('/cart');
  }, [history, productId, colorId, addItemToCart, selectedQty, product, auth])

  const contactCS = React.useCallback(() => {
    openWidget();
    if(chatServerOnline) {
      sendMessage({ type: 'TEXT', content: `咨询商品: "${product.productName}"` });
      sendMessage({ type: 'IMAGE', content: `${config.imagePrefix}/productbanner/${product.image1}`})
    }
  }, [product, chatServerOnline]);

  const maxAllowedQuantity = product
    ? product.purchaseLimit === 0
      ? 99 : product.purchaseLimit
    : 1;

  const shdShowVipPrice = auth.isVip && product.categoryType !== 'ACCESSORY';
  return (
    <Wrapper>
      <ProductShowcase
        imagePrefix="productbanner"
        productImages={[product.image1, product.image3, product.image5]}
        seriesId={product.seriesId}
        seriesImage={product.seriesImage} />

      <ContentWrapper>
        <NameWrapper>{product.productName}</NameWrapper>

        <DescriptionWrapper>
          <Description1>{product.description1}</Description1>

          <Description2>{product.description2}</Description2>

          <Remark>{product.remark1}</Remark>

          <Price isVip={shdShowVipPrice}>{`价格： ￥${product.price || ''}`}</Price>
          { shdShowVipPrice && <Price>{`会员价： ￥${product.vipPrice || ''}`}</Price> }

          <SelectQtyWrapper>
            数量：
            <select value={selectedQty} onChange={handleChange}>
              {[...Array(maxAllowedQuantity)].map((v, i) => (
                <option key={i} value={i+1}>{i+1}</option>
              ))}
            </select>
            <span>{ `库存: ${product.quantity}` }</span>
          </SelectQtyWrapper>

          { product.purchaseLimit && product.purchaseLimit > 0 && product.remark ?
            (
              <PurchaseLimit>{ product.remark }</PurchaseLimit>
            )
            : null
          }

          <ButtonRow>
            <Button onClick={onAddToCartBtnClicked}>加入购物车</Button>

            <Button onClick={buyDirectly}>直接购买</Button>
          </ButtonRow>

          <a href="http://wpa.qq.com/msgrd?v=3&uin=2101803859&site=qq&menu=yes" target="_blank" rel="noopener noreferrer">
            <CsButton>联系客服</CsButton>
          </a>
          <div>
            客服热线： 400 8212 799
          </div>
        </DescriptionWrapper>

        { accessories && accessories.length > 0 && <AccessorySection accessories={accessories} /> }

        { relatedProducts && relatedProducts.length > 0 && <RelatedSection relatedProducts={relatedProducts} /> }

        <ProductFooter />
      </ContentWrapper>
    </Wrapper>
  )
};

const mapStateToProps = state => ({
  chatServerOnline: state.serverOnline,
});

const mapDispatchToProps = dispatch => bindActionCreator({
  openWidget,
  sendMessage,
}, dispatch);

export default compose(
  withPopup,
  withAuth,
  connect(mapStateToProps, mapDispatchToProps),
)(ProductPageComponent);