import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import lodashGet from 'lodash/get';

import { PageContainer } from '../Common';
import config from '../../config';
import { withPopup } from '../../context';
import { PHONE_REGEX, NAME_REGEX, EMAIL_REGEX } from '../../constants';
import { media } from '../../styles/breakpoints';

const provinceOption = ['----请选择----', '北京', '天津', '上海', '重庆', '安徽', '福建', '甘肃', '广东', '贵州', '海南', '河北', '黑龙江', '河南', '湖北', '湖南', '江苏', '江西', '吉林', '辽宁', '青海', '山东', '陕西', '山西', '四川', '云南', '浙江', '内蒙古', '广西', '西藏', '宁夏', '新疆', '香港', '澳门', '台湾']

const FormWrapper = styled.div`
  display: flex;
  background-color: #f2f2f2;
  padding: 60px;
  width: 100%;
  min-height: calc(100vh - 150px - 66px - 170px);

  ${media.phone`
    padding: 10px;
  `};
`;

const Form = styled.form`
  width: 100%;
`;

const RadioWrapper = styled.div`
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;

  label {
    margin-right: 40px;
    line-height: 1.5;
  }
`;

const RadioInput = styled.input`
  margin-right: 10px;
`;

const Seperator = styled.div`
  margin: 30px 0px;
  border: 1px solid #888;
`;

const SubmitButton = styled.button`
  margin: 20px auto;
  color: black !important;
  background-color: #e6e6e6 !important;
  border-color: gray !important;
  width: 40% !important;

  ${media.phone`
    width: 80% !important;
  `};
`;

class ContactsComponent extends React.Component {
  state = {
    contactData: "",
    formData: {
      type: "PRODUCT",
      name: '',
      sex: 'M',
      email: '',
      phone: '',
      content: '',
      province: '----请选择----',
      location: '', 
    }
  }

  onTypeChange = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        type: e.target.value
      }
    })
  }

  onNameChange = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        name: e.target.value
      }
    })
  }

  onSexChange = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        sex: e.currentTarget.value
      }
    })
  }

  onEmailChange = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        email: e.target.value
      }
    })
  }

  onPhoneChange = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        phone: e.target.value
      }
    })
  }

  onContentChange = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        content: e.target.value
      }
    })
  }

  onProvinceChange = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        province: e.target.value
      }
    })
  }

  onLocationChange = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        location: e.target.value
      }
    })
  }
  
  onSubmit = async (e) => {
    const { formData: { type, name, phone, email, content, sex, province, location } } = this.state;
    const { togglePopup } = this.props;

    e.preventDefault();

    if (!name || !phone || !email || !sex || !content || !location) {
      togglePopup({
        type: 'info',
        message: '请填写所有栏位'
      });
      return;
    }
    
    if (!PHONE_REGEX.test(phone)) {
      togglePopup({
        type: 'info',
        message: '电话号码必需為11個字'
      });
      return;
    }
    if (!NAME_REGEX.test(name)) {
      togglePopup({
        type: 'info',
        message: '姓名必需是中/英字, 不能有数字或其他符号'
      });
      return;
    }
    if (!EMAIL_REGEX.test(email)) {
      togglePopup({
        type: 'info',
        message: '电邮地址不正確'
      });
      return;
    }

    if (province === '----请选择----') {
      togglePopup({
        type: 'info',
        message: '请填上省份'
      });
      return;
    }

		try {
      const fetchUrl = `${config.serverEndPoint}/contactUsPage`;
      await axios.put(fetchUrl, {
        type, name, phone, email, content, sex, province, location,
      }).then(result => result.data);

      togglePopup({
        type: 'info',
        message: '提交成功, 感谢联络我们!'
      })
      this.setState({ formData: {
        type: "PRODUCT",
        name: '',
        sex: 'M',
        email: '',
        phone: '',
        content: '',
        province: '----请选择----',
        location: '',
      }});
		} catch (err) {
      console.log(err);
      const message = lodashGet(err, 'response.data.message', '提交失败');

      togglePopup({
        type: 'info',
        message
      })
		}
  }

  async componentDidMount() {
    const result = await this.getContactData();

    this.setState({ contactData: result.value || '' });
  }

  async getContactData() {
    const fetchUrl = `${config.serverEndPoint}/contactUsPage`;

		try {
			const result = await axios.get(fetchUrl).then(result => result.data);

      return result;
		} catch (err) {
			console.log(err);
		}
  }

  render() {
    const { contactData, formData: { type, name, sex, email, phone, content, province, location } } = this.state;

    return (
      <PageContainer title="联络我们">
        <FormWrapper>
          <Form>
            <div className="row form-group">
              <div className="col-sm-6 col-12">
                <label>主题留言</label>
                <select className="form-control" name="topic" value={type} onChange={this.onTypeChange} >
                  <option value="PRODUCT">产品</option>
                  <option value="CS">服务</option>
                  <option value="GROUP">团购</option>
                  <option value="OTHER">其他</option>
                </select>
              </div>
            </div>
            <div className="row form-group">
              <div className="col-sm-6 col-12">
                <label>姓名</label>
                <input className="form-control" name="name" type="text" value={name} onChange={this.onNameChange} />
              </div>
              <div className="col-sm-6 col-12">
                <label>性别</label>
                <RadioWrapper>
                  <label>
                    <RadioInput type="radio" name="sex" value="M" size="5" checked={sex === 'M'} onChange={this.onSexChange} />
                    男
                  </label>
                  <label>
                    <RadioInput type="radio" name="sex" value="F" size="5" checked={sex === 'F'} onChange={this.onSexChange} />
                    女
                  </label>
                </RadioWrapper>
              </div>
            </div>
            <div className="row form-group">
              <div className="col-sm-6 col-12">
                <label>电邮地址</label>
                <input className="form-control" name="email" type="text" value={email} onChange={this.onEmailChange} />
              </div>
              <div className="col-sm-6 col-12">
                <label>联络电话</label>
                <input className="form-control" name="tel" type="text" value={phone} onChange={this.onPhoneChange} />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-sm-6 col-12">
                <label>省份</label>
                <select className="form-control" name="topic" value={province} onChange={this.onProvinceChange} >
                  { provinceOption.map((item) => (
                    <option value={item}>{item}</option>
                  ))}
                </select>
              </div>
              <div className="col-sm-6 col-12">
                <label>地区</label>
                <input className="form-control" name="location" type="text" value={location} onChange={this.onLocationChange} />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-sm-6 col-12">
                <label>留言内容</label>
                <textarea className="form-control" name="content" value={content} onChange={this.onContentChange}></textarea>
              </div>
            </div>
  
            <Seperator />
  
            <div className="row">
              { contactData.split('\n').map((content, idx) => (
                <div key={idx} className="col-12">
                  <label className="inputlabel">{content}</label>
                </div>
              ))}
            </div>
  
            <SubmitButton className="btn btn-primary btn-lg btn-block" onClick={this.onSubmit}>
              <span>递交留言</span>
            </SubmitButton>
  
          </Form>
        </FormWrapper>
      </PageContainer>
    );
  }
}


export default withPopup(
  ContactsComponent
);