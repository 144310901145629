import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { withAuth } from "../context";

const RouteHandler = ({
  path, location, component, redirectTo, isPrivate, auth
}) => {
  if (auth.shouldUpdateData() && path !== '/user/update-user-data') {
    return <Redirect to={{ pathname: '/user/update-user-data', state: { from: location } }} />
  }

  if (isPrivate && auth.isAuthed()) {
    return <Redirect to={{ pathname: redirectTo, state: { from: location } }} />
  }

  return <Route path={path} component={component} />;
};

export default withAuth(RouteHandler);
