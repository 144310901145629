import React from 'react';
import styled from 'styled-components';
import Modal from 'react-bootstrap4-modal';

const ModalBodyHeader = styled.h4`
  margin-top: 40px;
  font-size: 18px;
`;

const Button = styled.button`
  margin-top: 20px;
  float: right;
`;

const SimpleModal = ({ visible, onClose, confirmMsg, confirmCallback }) => {
  return (
    <Modal visible={visible} onClickBackdrop={onClose ? onClose : confirmCallback} dialogClassName="confirm-modal-dialog">
      <div className="modal-body">
        <ModalBodyHeader>{confirmMsg}</ModalBodyHeader>
        <Button className="btn btn-primary" onClick={confirmCallback}>
          确认
        </Button>
      </div>
    </Modal>
  )
}

export default React.memo(SimpleModal);