import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import lodashGet from 'lodash/get';

import config from '../../../config';
import EditPasswordPageComponent from './EditPasswordPageComponent';
import { SimpleModal } from '../../Common';
import { withPopup } from '../../../context';

class EditPasswordPageContainer extends React.Component {
  state = {
    openSuccessModal: false,
    openFailModal: false
  }

  editPassword = async (oldPw, newPw) => {
    const { togglePopup } = this.props;

    try {
      const editUrl = `${config.serverEndPoint}/userPassword`;

      await axios.put(editUrl, { oldPw, newPw });

      this.setState({ openSuccessModal: true })
    } catch (err) {
      console.log(err);
      const responseMsg = lodashGet(err, 'response.data.message', '更新失败');
      togglePopup({
        type: 'info',
        message: responseMsg,
      })
    }
  }

  closeSuccessModal = () => {
    const { history } = this.props;

    this.setState({ openSuccessModal: false });

    history.push('/user');
  }

  closeFailModal = () => {
    this.setState({ openFailModal: false });
  }

  render() {
    const { openSuccessModal, openFailModal } = this.state;

    return (
      <React.Fragment>
        <EditPasswordPageComponent editPassword={this.editPassword} />

        <SimpleModal
          confirmMsg="修改密码成功"
          visible={openSuccessModal}
          confirmCallback={this.closeSuccessModal} />

        <SimpleModal
          confirmMsg="密码错误"
          visible={openFailModal}
          confirmCallback={this.closeFailModal} />
      </React.Fragment>
    )
  }
};

export default withRouter(
  withPopup(EditPasswordPageContainer)
);
