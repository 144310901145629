import React from 'react'
import styled from 'styled-components'

import { media } from '../../../../styles/breakpoints';

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border: solid 1px #ccc;
  height: 47px;

  ${media.phone`
    width: 110px;
    height: 30px;
  `};
`
const Input = styled.input`
  width: 100%;
  background-color: #efefef;
  text-align: center;
`;

const CtrlBtn = styled.span`
  cursor: pointer;
  font-size: 30px;
  padding: 0 10px;
`;

const MinusBtn = styled(CtrlBtn)`
  padding: 0 10px 3px;

  ${media.phone`
    padding: 0 10px 4px;
  `};
`;


const QuantityInput = ({ value, onChange, id, disabled, ...props }) => {
  const onAdd = React.useCallback(() => {
    const newVal = parseInt(value) + 1;

    if (newVal > 99) {
      return;
    }

    onChange(id, '' + newVal);
  }, [id, value, onChange]); 

  const onMinus = React.useCallback(() => {
    const newVal = parseInt(value) - 1;
    
    if (newVal <= 0) {
      return;
    }

    onChange(id, '' + newVal);
  }, [id, value, onChange]);

  const onInputChange = React.useCallback((e) => {
    const reg = /^\d{1,2}$/;

    if (e.target.value === '' || reg.test(e.target.value)) {
      onChange(id, e.target.value)
    }
  }, [id, onChange]);


  return (
    <InputWrapper>
      { !disabled && <MinusBtn onClick={onMinus}>-</MinusBtn> }
      <Input value={value} onChange={onInputChange} disabled={disabled} {...props} />
      { !disabled && <CtrlBtn onClick={onAdd}>+</CtrlBtn> }
    </InputWrapper>
  )
}

export default QuantityInput;
