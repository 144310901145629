import React from 'react';
import styled from 'styled-components';
import { Divider } from '@material-ui/core';

const Container = styled.div`
  width: 100%;
  position: relative;
  padding: 0.5rem;
  text-align: center;
`
const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
`

const StyledDivider = styled(Divider)`
  flex: 1;
`;

const StyledSpan = styled.span`
  margin: 0 1rem;
  color: rgba(0, 0, 0, 0.12);
`;

const LogMessage = ({ message }) => (
  <Container>
    <InnerContainer>
      <StyledDivider />
        <StyledSpan>{message}</StyledSpan>
      <StyledDivider/>
    </InnerContainer>
  </Container>
);

export default LogMessage;
