import React from 'react';
import styled from 'styled-components';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

const EnhancedList = styled(List)`
  height: 400px;
  overflow-y: scroll;
  border: solid 1px #ddd;
  border-radius: 4px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
`;

const EnhancedListItem = styled(ListItem)`
  border-bottom: solid 1px #ddd !important;
`;

const SubItemTextWrapper = styled.div`
  flex: 1 1 auto;
  padding: 0 16px;
  min-width: 0;
  padding-left: 0;
`;

const SubHeader = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5em;
  margin: 0;
  display: block;
`;

const SubItemText = styled.div`
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.46429em;
`;

const ShopList = ({ retailList, searchList, onClick }) => {
  const mapList = searchList ? searchList : retailList;
  return (
    <EnhancedList>
      { mapList && mapList.map((retail, index) => {
        return (
          <EnhancedListItem button onClick={() => onClick(retail.id)}>
            <SubItemTextWrapper>
              <SubHeader>{retail.name}</SubHeader>
              <SubItemText>{retail.address}</SubItemText>
              <SubItemText>营业时间{retail.openinghour}</SubItemText>
              <SubItemText>电话: {retail.phone}</SubItemText>
            </SubItemTextWrapper>
          </EnhancedListItem>
        )
      })}
    </EnhancedList>
  )
}

export default ShopList;