import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { media } from '../../../../styles/breakpoints';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  align-items: flex-start;
`;

const Header = styled.div`
  color: gray;
  margin-bottom: 1em;
  font-size: 1.5rem;
`;

const CategoryList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const CategoryItem = styled.div`
  position: relative;
  margin-bottom: 13px;
  width: 376px;
  height: 62px;

  span {
    left: 27%;
    position: absolute;
    font-size: 17px;
    top: 31%;
    color: #333;

    ${media.phone`
      top: 20%;
    `};
  }

  img {
    width: 100%;
  }

  ${media.tablet`
    width: 48%;
    height: auto;
  `};

  ${media.phone`
    width: 100%;
    height: auto;
  `};
`;

const ProductOption = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.phone`
    flex-direction: column;
  `};
`;

const ProductOptionItem = styled.div`
  flex: 0 0 32%;
  margin-bottom: 39px;

  img {
    width: 100%;
  }
`;

const SeriesList = styled.div`
  font-size: 1.2em;
  line-height: 2;
  text-align: left;
  margin-bottom: 30px;
`;

const ServiceList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 30px;

  ${media.phone`
    margin-bottom: 10px;
  `};
`;

const ServiceListItem = styled.div`
  position: relative;
  margin-bottom: 13px;
  width: 376px;
  height: 62px;

  span {
    left: 20%;
    position: absolute;
    font-size: 17px;
    top: 31%;
    color: #333;

    ${media.phone`
      top: 20%;
    `};
  }

  img {
    width: 100%;
  }

  ${media.tablet`
    width: 48%;
    height: auto;
  `};

  ${media.phone`
    width: 100%;
    height: auto;
  `};
`;


const PageSection = ({ categoryList, seriesItems, serviceList }) => {
  return (
    <Wrapper>
      <Header>产品分类</Header>
      <CategoryList>
        { categoryList && categoryList.length > 0 && categoryList.map((category, index) => (
          <CategoryItem key={index}>
            <Link to={category.to}>
              <img src={category.imageSrc} alt="category"></img>
              <span>{category.name}</span>
            </Link>
          </CategoryItem>
        ))}
      </CategoryList>

      <ProductOption>
        <ProductOptionItem>
          <Link to="./productoption?id=1">
            <img src="/images/category/category_b1.jpg" alt="option" />
          </Link>
        </ProductOptionItem>
        <ProductOptionItem>
          <Link to="./productoption?id=2">
            <img src="/images/category/category_b2.jpg" alt="option" />
          </Link>
        </ProductOptionItem>
        <ProductOptionItem>
          <Link to="./productoption?id=3">
            <img src="/images/category/category_b3.jpg" alt="option" />
          </Link>
        </ProductOptionItem>
      </ProductOption>

      <Header>产品系列 A - Z</Header>
      <SeriesList>
        { seriesItems && seriesItems.length > 0 && seriesItems.map((series, index) => (
          <React.Fragment key={index}>
            { index !== 0 ? ' / ' : null}
            <Link to={`./productseries?id=${series.id}`}>
              <span>{series.name}</span>
            </Link>
          </React.Fragment>
        ))}
      </SeriesList>

      <Header>产品服务</Header>
      <ServiceList>
        { serviceList && serviceList.length > 0 && serviceList.map((service, index) => (
          <ServiceListItem key={index}>
            <Link to={service.to}>
              <img src="./images/category/category_c7.jpg" alt="" />
              <span>{service.name}</span>
            </Link>
          </ServiceListItem>
        ))}
      </ServiceList>
    </Wrapper>
  )
};

export default PageSection;