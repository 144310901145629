import io from 'socket.io-client';
import * as Cookies from 'utils/cookie';
import config from 'config';

//socket.io.opts.query
let socket = null;
export default {
  init() {
    socket = io(config.chatServerEndpoint, {
      query: { type: 'USER', 'LAMY-CHAT-USER-ID': Cookies.get('LAMY-CHAT-USER-ID') },
      autoConnect: false,
    });
    return socket;
  },
  saveSession(sessionId) {
    Cookies.set('LAMY-CHAT-USER-ID', sessionId);
  },
  instance(){
    return socket;
  },
};
