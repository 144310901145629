import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  text-align: left;
`;

const HeaderRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const InputHeader = styled.div`
  margin-bottom: 5px;
  text-align: left;
  color: #808080;
`;

const ErrorMsg = styled.div`
  color: red;
`;

const Input = styled.input`
  margin-bottom: 15px;
  border-radius: 0px !important;
`;

const TextInput = ({
  name, value, onChange, header, hasErr, errMsg, type = 'text', ...props
}) => {
  return (
    <Wrapper>
      <HeaderRow>
        <InputHeader>{header}</InputHeader>
        { hasErr && <ErrorMsg>{errMsg}</ErrorMsg> }
      </HeaderRow>
      <Input className="form-control" type={type} name={name} value={value} onChange={onChange} {...props} />
    </Wrapper>
  )
};

export default TextInput;