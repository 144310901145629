import React from 'react';
import { css } from 'styled-components';
import MediaQuery from 'react-responsive';

export const sizes = {
  // Extra large devices (large desktops, 1200px and up)
  largeDesktop: 1199,
  // Large devices (desktops, 992px and up)
  desktop: 991,
  // Medium devices (tablets, 768px and up)
  tablet: 768,
  // Small devices (landscape phones, 576px and up)
  phone: 576
};

// iterate through the sizes and create a media template
export const media = Object.keys(sizes).reduce((accumulator, label) => {
  accumulator[label] = (...args) => css`
    @media (max-width: ${sizes[label]}px) {
      ${css(...args)};
    }
  `;
  return accumulator;
}, {});

export const MobileOnly = ({ children }) => (
  <MediaQuery maxDeviceWidth={sizes.phone}>
    { children }
  </MediaQuery>
)

export const TabletAndDesktop = ({ children }) => (
  <MediaQuery minDeviceWidth={sizes.phone + 1}>
    { children }
  </MediaQuery>
)