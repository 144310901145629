import React from 'react';
import styled from 'styled-components';
import Modal from 'react-bootstrap4-modal';

const ModalBodyHeader = styled.h4`
  font-size: 18px;
`;

const ConfirmModal = ({
  visible, onClose, onComfirm,
  modalHeader, confirmMsg
}) => {
  const onConfirmClick = React.useCallback(() => {
    onComfirm();

    onClose();
  }, [onComfirm]);

  return (
    <Modal visible={visible} onClickBackdrop={onClose} dialogClassName="confirm-modal-dialog">
      <div className="modal-header">
        <h5 className="modal-title">{modalHeader}</h5>
      </div>
      <div className="modal-body">
        <ModalBodyHeader>{confirmMsg}</ModalBodyHeader>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={onClose}>
          取消
        </button>
        <button type="button" className="btn btn-primary" onClick={onConfirmClick}>
          确认
        </button>
      </div>
    </Modal>
  )
}

export default React.memo(ConfirmModal);