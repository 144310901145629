import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { ORDER_STATUS } from '../../../../constants';
import config from '../../../../config';
import { getFormattedDate } from '../../../../utils';

const Wrapper = styled.li`
  border: solid 1px #e2e2e2;
  margin-bottom: 30px;
  cursor: pointer;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  border-bottom: solid 1px #e2e2e2;

  span {
    margin-left: 5px;
  }
`;

const Date = styled.div`
  margin-top: 5px;
`;

const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: solid 1px #ccc;
`;

const ProductItem = styled.div`
  width: 100%;
  display: flex;
  padding: 10px 0;
  align-items: center;

  &:not(:first-child) {
    border-top: solid 1px #ccc;
  }
`;

const ProductImage = styled.div`
  flex: 1;
  margin-right: 10px;

  img {
    width: 100%;
  }
`;

const ProductDetail = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 5px;
  font-size: 12px;

  img {
    width: 100px;
  }

  span {
    margin-top: 5px;
  }
`;

const Price = styled.div`
  flex: 1;
  text-align: center;
`;

const TotalPrice = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 15px;
`;

const renderProductItem = ({ colorId, id, image, name, price, quantity, seriesId, seriesImage }) => {
  return (
    <ProductItem key={`${id}-${colorId}`}>
      <ProductImage>
        <img src={`${config.imagePrefix}/productbanner/${image}`} alt="" />
      </ProductImage>
      <ProductDetail>
        <img src={`${config.imagePrefix}/productseries/${seriesImage}`} alt="" />
        <span>{name}</span>
        <span>数量: {quantity}</span>
      </ProductDetail>
      <Price>￥{price}</Price>
    </ProductItem>
  )
};

const OrderItemMobile = ({ order }) => {
  const { id, status, createDate, totalPrice, products } = order;

  return (
    <Wrapper>
      <Link to={`/orderDetail?id=${id}`}>
        <HeaderRow>
          <div>
            <div>
              订单号<span>{id}</span>
            </div>
            <Date>
              {getFormattedDate(createDate)}
            </Date>
          </div>
          <div>{status ? ORDER_STATUS[status] : ''}</div>
        </HeaderRow>

        <ProductWrapper>
          { products && products.map((product) => renderProductItem(product))}
        </ProductWrapper>
        <TotalPrice><b>订单金额: ￥{totalPrice}</b></TotalPrice>
      </Link>
    </Wrapper>
  )
}

export default React.memo(OrderItemMobile);