import React from 'react';
import styled from 'styled-components';
import HeadsetMic from '@material-ui/icons/HeadsetMic';

import { connect, bindActionCreator } from 'context/chat/chatContext';
import { openWidget } from 'context/chat/chatRedux';
import { sizes, MobileOnly, TabletAndDesktop } from 'styles/breakpoints';

const Bubble = styled.button`
  padding: 3px 1rem;
  background-color: #bbb;
  background: linear-gradient(to bottom, #f0f0f0, #aaa);
  position: fixed;
  bottom: 0;
  right: 6vw;
  display: ${({open}) => open ? 'block': 'none'};
  border-radius: 6px 6px 0 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 134px;
  justify-content: center;

  @media (max-width: ${sizes.phone}px) {
    & {
      right: 0;
      bottom: 50vh;
      width: 30px;
      height: 94px;
      padding: 0.5rem 0;
      border-radius: 6px 0 0 6px;
      background-color: #F0F0F0;
      background: linear-gradient(to right, #f0f0f0, #DDDDDD);
      flex-direction: column;
    }
  }
`;

const RedDot = styled.svg`
  width: 6px;
  height: 6px;
  margin-left: 0.5rem;

  @media (max-width: ${sizes.phone}px) {
    margin-left: 0;
    margin-top: 0.25rem;
  }
`;

const ChatBubble = ({ widgetShow, openWidget, hasUnreadMessage }) => (
  <Bubble open={!widgetShow}>
    <HeadsetMic/>
    <a href="http://wpa.qq.com/msgrd?v=3&uin=2101803859&site=qq&menu=yes" target="_blank" rel="noopener noreferrer">
      <TabletAndDesktop>在线客服</TabletAndDesktop><MobileOnly>客服</MobileOnly>
    </a>
    {hasUnreadMessage && (
      <RedDot viewBox="0 0 24 24" fill="red">
        <circle cx="12" cy="12" r="12" />
      </RedDot>
    )}
  </Bubble>
);

const mapStateToProps = state => ({
  widgetShow: state.widgetShow,
  hasUnreadMessage: state.hasUnreadMessage,
});

const mapDispatchToProps = dispatch => bindActionCreator({
  openWidget,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChatBubble);