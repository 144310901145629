import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  align-items: center;
  text-align: left;
`;

const ContentWrapper = styled.div`
  width: 100%;
  padding: 40px 120px;
`;

const SearchInputWrapper = styled.div`
  padding: 20px 0 20px 20px;
  background-color: #f0f0f0;
  border: 1px #d9d9d9 solid;
  width: 700px;
`;

const Input = styled.input`
  width: 310px;
  height: 20px;
  margin-right: 169px;
  margin-bottom: 20px;
`;

const Button = styled.button`
  width: 88px;
  height: 23px;
  background-color: #666;
  color: #fff;
  font-size: 14px;
`;

const SearchResultWrapper = styled.div`
  margin: 20px 0;
  width: 700px;
`;

const ProductItem = styled.div`
  border-bottom: solid 1px #ccc;
  cursor: pointer;
  margin-top: 5px;

  &:hover {
    text-decoration: underline;
  }
`;

const SearchPageComponent = ({ searchText, onSearchTextChange, fetchSearchResult, searchResult }) => {
  const onInputChange = React.useCallback((event) => {
    onSearchTextChange(event.target.value);
  }, [onSearchTextChange])

  return (
    <Wrapper>
      <ContentWrapper>
        <SearchInputWrapper>
          <Input value={searchText} onChange={onInputChange} />
          <Button onClick={fetchSearchResult}>再次搜索</Button>
        </SearchInputWrapper>
        <SearchResultWrapper>
          { searchResult ? searchResult.map((product) => (
            <Link to={`/product?id=${product.productId}&colorId=${product.colorId}`}>
              <ProductItem>
                <div>{ product.name }</div>
                <div>{ `http://lamy.vip/product?id=${product.productId}&colorId=${product.colorId}` }</div>
              </ProductItem>
            </Link>
          )) : '抱歉！没有找到相关的产品！' }
        </SearchResultWrapper>
      </ContentWrapper>
    </Wrapper>
  )
};

export default SearchPageComponent;