import React from 'react';
import styled from 'styled-components';

import { Carousel, ProductFooter } from '../../Common';
import { ProductItem, TypeTab, AccessoryItem } from '../Components';
import { media } from '../../../styles/breakpoints';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  align-items: center;
`;

const ContentWrapper = styled.div`
  width: 100%;
  padding: 20px 80px;

  ${media.tablet`
    padding: 50px;
  `};

  ${media.phone`
    padding: 20px;
  `};
`;

const AccessoryWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${media.phone`
    justify-content: center;
  `};
`;

const Header = styled.div`
  text-align: left;
  font-size: 24pt;
  margin-bottom: 1em;
  color: gray;

  ${media.phone`
    font-size: 16pt;
  `};
`;

const renderProductItem = (productItems, addItemToCart) => {
  return (
    productItems && productItems.map((product) => (
      <ProductItem key={product.id} product={product} addItemToCart={addItemToCart} />
    ))
  )
}

const renderAccessoryItem = (productItems) => {
  return (
    <AccessoryWrapper>
      { productItems && productItems.map((accessory) => (
        <AccessoryItem key={accessory.id} accessory={accessory} />
      ))}
    </AccessoryWrapper>
  )
}

const ProductListPageComponent = ({ categoryId, pageTitle, bannerItems, productItems, addItemToCart }) => {
  return (
    <Wrapper>
      <Carousel items={bannerItems} />

      <ContentWrapper>
        <Header>{pageTitle}</Header>

        { (categoryId <= 4 || categoryId === 8) && <TypeTab categoryId={categoryId} /> }

        { categoryId <= 4 ? renderProductItem(productItems, addItemToCart) : renderAccessoryItem(productItems) }

        <ProductFooter />
      </ContentWrapper>
    </Wrapper>
  )
};

export default React.memo(ProductListPageComponent);