import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';

function scrollToTop() {
  window.scrollTo(0, 0);
}

const withScrollToTop = (WrappedComponent, options) => {
  return class withScrollToTopComponent extends Component {
    componentDidUpdate(prevProps) {
      const prevSearch = qs.parse(prevProps.location.search);
      const currSearch = qs.parse(this.props.location.search);
      
      const shdUpdate = options.reduce((prev, key) => {
        const prevParam = prevSearch[key];
        const currParam = currSearch[key];
        return prev || prevParam !== currParam;
      }, false);

      if (shdUpdate) {
        scrollToTop();
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
};

export default (WrappedComponent, options) => {
  return withRouter(withScrollToTop(WrappedComponent, options));
};