import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import lodashGet from 'lodash/get';

import config from '../../config';
import ForgetPasswordPageComponent from './ForgetPasswordPageComponent';
import { withPopup } from '../../context';

class ForgetPasswordPageContainer extends React.Component {
  forgetPassword = async (formData) => {
    const { togglePopup, history } = this.props;

    try {
      const createUrl = `${config.serverEndPoint}/forget-password`;

      await axios.post(createUrl, formData);

      togglePopup({
        type: 'info',
        message: '您的账号新密码设置完成'
      });

      history.push('/login');
    } catch (err) {
      console.log(err);
      const responseMsg = lodashGet(err, 'response.data.message', '更新失败');
      togglePopup({
        type: 'info',
        message: responseMsg,
      })
    }
  }

  render() {
    return (
      <ForgetPasswordPageComponent forgetPassword={this.forgetPassword} />
    )
  }
};

export default withRouter(
  withPopup(ForgetPasswordPageContainer)
);
