export default [
  {
    header: '书写工具',
    items: [
      {
        name: '墨水笔',
        to: '/productlist?id=1'
      },
      {
        name: '宝珠笔',
        to: '/productlist?id=2'
      },
      {
        name: '自动铅笔',
        to: '/productlist?id=4'
      },
      {
        name: '原珠笔',
        to: '/productlist?id=3'
      },
    ]
  },
  {
    header: '配件其他',
    items: [
      {
        name: '儿童画笔',
        to: '/productlist?id=8'
      },
      {
        name: '礼盒套装',
        to: '/productlist?id=9'
      },
      {
        name: '墨水笔芯',
        to: '/productlist?id=7'
      },
      {
        name: '配件',
        to: '/productlist?id=6'
      },
    ]
  },
  {
    header: '产品服务',
    items: [
      {
        name: '常见问题',
        to: '/customer-service?id=0'
      },
      {
        name: '企业选购',
        to: '/customer-service?id=1'
      },
      {
        name: '保养须知',
        to: '/customer-service?id=2'
      },
      {
        name: '刻字服务',
        to: '/customer-service?id=3'
      },
      {
        name: '维修服务',
        to: '/customer-service?id=4'
      },
      {
        name: '发货及配送',
        to: '/customer-service?id=5'
      },
    ]
  },
]