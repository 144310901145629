import React from 'react';
import ReactDOM from 'react-dom';

import { App } from './app';

import { CartProvider, AuthProvider } from './context';
import { Provider as ChatProvider } from './context/chat';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './index.css';


ReactDOM.render(
  <ChatProvider>
    <AuthProvider>
      <CartProvider>
        <App />
      </CartProvider>
    </AuthProvider>
  </ChatProvider>,
  document.getElementById('root')
);