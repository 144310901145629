import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { withRouter } from 'react-router-dom';

import config from '../../../config';
import { PageContainer } from '../../Common';
import { media } from '../../../styles/breakpoints';
import { getQueryStringByKey } from '../../../utils';

const Wrapper = styled.div`
  margin: auto;
  width: 70%;
  padding: 150px 100px;

  ${media.tablet`
    padding: 100px 30px;
  `};

  ${media.phone`
    width: 100%;
    padding: 30px 10px;
  `};
`;

const Button = styled.button`
  width: 100%;
  font-weight: 600;
  border: solid 1px #dadada;
  padding: 10px;
  background-color: #ebecf0;
  cursor: pointer;
`;

const Header = styled.h2`
  text-align: center;
  font-weight: 600;
  font-size: 26px;
  margin-bottom: 30px;
`;

const MainContaineWrapper = styled.div`
  background-color: #f2f2f2;
`;

class BindingRedirectPage extends React.PureComponent {
  state = {
    success: false
  }
  
  onBackBtnClick = () => {
    const { history } = this.props;

    history.push('/user');
  }

  async componentDidMount() {
    const { match, location } = this.props;
    const code = getQueryStringByKey(location.search, 'code')

    if (!code) {
      // TODO: Throw error
      return;
    }

    try {
      const addBindingUrl = `${config.serverEndPoint}/third-party-binding/${match.params.type}`;

      await axios.put(addBindingUrl, { code });

      this.setState({ success: true });
    } catch (e) {
      console.log(e);
    }
  }


  render() {
    const { success } = this.state;

    return (
      <PageContainer title="个人资料" backBtnLink="/user">
        <Wrapper>
          <MainContaineWrapper>
            { success ? (
              <React.Fragment>
                <Header>綁定成功</Header>
                <Button onClick={this.onBackBtnClick}>返回</Button>
              </React.Fragment>
            ) : (
              <Header>正在绑定...</Header>
            )}
          </MainContaineWrapper>
        </Wrapper>
      </PageContainer>
    )
  }
}

export default withRouter(BindingRedirectPage);