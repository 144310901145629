import React from 'react';
import styled from 'styled-components';

import { Carousel, MobileMenu } from '../Common';
import GridList from './Component/GridList';
import { media, MobileOnly } from '../../styles/breakpoints';

const EnhancedCarousel = styled(Carousel)`
  ${media.phone`
    margin-bottom: 0;
    min-height: unset;
  `};
`;

const MobileMenuWrapper = styled.div`
  margin-bottom: 20px;
`;

const HomePageComponent = ({ bannerItems, gridItems }) => {
  return (
    <div>
      <EnhancedCarousel items={bannerItems} />

      <MobileOnly>
        <MobileMenuWrapper>
          <MobileMenu />
        </MobileMenuWrapper>
      </MobileOnly>

      <GridList gridItems={gridItems} />
    </div>
  )
}

export default HomePageComponent;
