import React from 'react';
import axios from 'axios';

import { getIntQueryStringByKey, withScrollToTop } from '../../../utils';
import config from '../../../config';
import ProductOptionPageComponent from './ProductOptionPageComponent';
import { withCart } from '../../../context';

class ProductSeriesListPageContainer extends React.Component {
  state = {
    seriesItems: {},
  }

  componentDidMount() {
    this.loadPageData();
  }

  loadPageData() {
    const { location } = this.props;
    const optionId = getIntQueryStringByKey(location.search, 'id', 1);

    const fetchUrl = `${config.serverEndPoint}/productOptionPage?id=${optionId}`;

    axios.get(fetchUrl)
      .then(result => result.data)
      .then(result => {
        const { seriesItems } = result ? result : { seriesItems: {} };

        this.setState({ seriesItems });
      })
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    const { seriesItems } = this.state;
    const { location, cart } = this.props;
    const optionId = getIntQueryStringByKey(location.search, 'id', 1);

    return (
      <ProductOptionPageComponent
        optionId={optionId}
        seriesItems={seriesItems}
        addItemToCart={cart.addItemToCart} />
    )
  }
};

export default withScrollToTop(withCart(ProductSeriesListPageContainer), ['id']);

