import React from 'react';
import QRcode from 'qrcode.react';
import styled from 'styled-components';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import Countdown from 'react-countdown-now';

import { withPopup } from '../../context';
import config from '../../config';
import { PageContainer } from '../Common';
import { getQueryStringByKey, checkIsMobile } from '../../utils';
import { ORDER_EXPIRE_TIME } from '../../constants';

const Wrapper = styled.div`
  background-color: #fff;
  text-align: center;
`;

const TextRow = styled.div`
  color: #000;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Button = styled.button`
  background-color: #000;
  color: #fff;
  width: 200px;
  height: 25px;
  margin-top: 10px;
`;

const WechatPayPage = ({ location, history, togglePopup }) => {
  const [data, setData] = React.useState({});

  const id = getQueryStringByKey(location.search, 'id');
  const fetchUrl = `${config.serverEndPoint}/orderDetailPage?id=${id}`;
  const isMobile = checkIsMobile();

  if (!id) {
    history.push('/');
  }

  const onFinishClick = React.useCallback(async () => {
    const result = await axios.get(fetchUrl)
      .then(result => result.data[0]);
    
    if (!result) {
      togglePopup({
        type: 'info',
        message: '找不到对应的订单',
      })

      history.push('/');
      return;
    } else if (result.status === 'EXPIRED') {
      togglePopup({
        type: 'info',
        message: '已过期的订单',
      });
      history.push('/');
      return;
    } else if (result.status !== 'PENDING') {
      history.push(`/cart/success?id=${id}`)
      return;
    } else {
      togglePopup({
        type: 'info',
        message: '尚未收到微信支付确认, 请确认您已付款',
      });
    }
  }, [id])

  React.useEffect(() => {
    const fetchUrl = `${config.serverEndPoint}/orderDetailPage?id=${id}`;

    const getData = async () => {
      const result = await axios.get(fetchUrl)
        .then(result => result.data[0]);
      
      if (!result) {
        togglePopup({
          type: 'info',
          message: '找不到对应的订单',
        })

        history.push('/');
        return;
      } else if (result.status === 'EXPIRED') {
        togglePopup({
          type: 'info',
          message: '已过期的订单',
        });
        history.push('/');
        return;
      } else if (result.status !== 'PENDING') {
        history.push(`/cart/success?id=${id}`)
        return;
      }

      setData(result);
    }

    try {
      getData();

      // Update status every 10s
      if (!isMobile) {
        const id = setInterval(() => getData(), 10 * 1000);
  
        return () => clearInterval(id);
      } else {
        return;
      }
    } catch (e) {
      history.push('/');
    }
  }, [isMobile]);


  return (
    <PageContainer title="微信支付">
      <Wrapper>
        <TextRow>订单号码: {id}</TextRow>
        <TextRow>订单总额: {data && data.totalPrice ? `￥${data.totalPrice}` : ''}</TextRow>
        { isMobile ? (
          <React.Fragment>
            <a href={data.token}>
              <Button>进行支付</Button>
            </a>
            <div>
              <Button onClick={onFinishClick}>完成支付</Button>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <TextRow>
              二维码剩余有效时间: 
              { data && data.createDate && <Countdown
                date={new Date(data.createDate).getTime() + ORDER_EXPIRE_TIME}
                renderer={({ hours, minutes, seconds }) => <span> {hours}:{minutes}:{seconds}</span>} />
              }
            </TextRow>
            <TextRow>请开启微信扫码并支付</TextRow>

            { data && data.token && <QRcode value={data.token} size={200} /> }
          </React.Fragment>
        )}
      </Wrapper>
    </PageContainer>
  )
};

export default withRouter(
  withPopup(WechatPayPage)
);