import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
background-color: #fff;
padding : 50px;
`;

const Content = styled.div`
  padding : 5px 0px 15px 0px;
  text-align: left;
`;

 
const Title = styled.div`
  font-size: 16px;
  color: #7030a0;
  padding: 5px 0px 15px 0px;
  text-align: center;
  font-weight: bold;
`;

const Version = styled.span`
  color : #777777;
`;

const Subtitle = styled.div`
  text-align: left;
  color : #7030A0;
  padding: 5px 0px;
  font-weight: bold;
`;

const RedText = styled.span`
  color: red;
`;

class DisclaimerComponent extends React.Component {
  render() {

    return (
      <Wrapper>  
        <Title>使用条件<Version><RedText>（上海傲竹文化传播有限公司法律部2013年5月版）</RedText></Version> </Title>
        <Content>若您不同意本使用条件，请立即停止浏览并退出本网站；若你继续在本网站停留，则表明您已同意本使用条件的全部内容。</Content>
        <Subtitle>版权</Subtitle>
        <Content>本网站的版权（包括文字、图片等各类作品）归<RedText>上海傲竹文化传播有限公司</RedText>（以下简称“上海傲竹”）及其关联公司所有。在未取得<RedText>上海傲竹</RedText>或其关联公司事前书面许可之前，任何主体均不得以任何方式（包括但不限于复制、存储、网络传播等）使用本网站内的任何内容。</Content>
        <Subtitle>商标</Subtitle>
        <Content>LAMY®和凌美®均为注册商标，注册人均为C. Josef Lamy GmbH。<RedText>上海傲竹</RedText>及其关联公司，作为LAMY®和凌美®商标在中国的独占被许可人（the exclusive licensee），依法享有在中国独家使用LAMY®和凌美®商标的权利。在未取得<RedText>上海傲竹</RedText>或其关联公司事前书面许可之前，任何主体均不得以任何方式使用LAMY®和凌美®商标。</Content>
        <Subtitle>账户、密码及安全</Subtitle>
        <Content>本网站的某些功能或服务可能要求您开立账户(主要包括设置用户名和密码)。对您的用户名和密码的保密性，您承担全部责任，除非是<RedText>上海傲竹</RedText>的原因导致账户信息泄露，否则您承担账户信息泄露的全部责任。
未经账户所有人的明确允许和同意，任何时候您都不得以任何方式使用任何其他人的账户信息。</Content>
        <Subtitle>消费者知情权</Subtitle>
        <Content><RedText>上海傲竹</RedText>尊重您的知情权，并建议您在下单前仔细了解商品的全部信息。若您对任何商品有任何疑问，可在工作时间内随时致电 [ 400 8212 799 ]，我们的专业人员将竭诚为您解答。您的下单行为将被视作已行使您的知情权，并同意此后不以任何与知情权相关的理由主张您的权益。</Content>
        <Subtitle>免责条款和责任限制</Subtitle>
        <Content><RedText>上海傲竹</RedText>不对本网站的功能或服务等的持续性或不中断作任何保证，您理解该等中断的可能性。<RedText>上海傲竹</RedText>亦不对何时恢复或修复该中断作任何保证。您同意在该等情形发生时，<RedText>上海傲竹</RedText>可以免责，对任何实际损失、逾期利益等等不作任何赔偿。
在任何情况下，<RedText>上海傲竹</RedText>对您因浏览、使用本网站而造成的损失不承担任何赔偿责任。</Content>
        <Subtitle>适用法律和管辖权</Subtitle>
        <Content>本使用条件适用中华人民共和国法律。与本使用条件有关和因本使用条件而起的任何争议，应先友好协商，协商不成，应提交上海国际仲裁中心仲裁解决。</Content>
        <Subtitle>解释权和修改权</Subtitle>
        <Content>本使用条件的解释权归<RedText>上海傲竹</RedText>所有。<RedText>上海傲竹</RedText>可以在任何时点修改、更新本使用条件。为维护您的合法权益，请细阅本使用条件并在必要的时候保存对您有约束力的版本。</Content>
        <Subtitle>隐私政策</Subtitle>
        <Content>您在本网站注册和购买商品的过程中提交的信息，均为完成下单、付款、送货等所必需的信息。您填写该等信息的行为已表明您同意向<RedText>上海傲竹</RedText>提交该等信息且<RedText>上海傲竹</RedText>已遵循合法、正当、必要的原则，明示了收集、使用该等信息的目的、方式和范围。
<RedText>上海傲竹</RedText>将严格保密你的该等信息并将采取措施，尽力确保信息安全和防止该等信息泄露、毁损、丢失。您承认并同意网络传输从不完全保密或安全，即使是加密的支付信息。
除非征得您事前同意或收到您的请求，<RedText>上海傲竹</RedText>不会向您的固定电话、移动电话或者个人电子邮箱发送任何商业性电子信息。</Content>
        <Subtitle>联系我们</Subtitle>
        <Content>
          <RedText>上海傲竹</RedText>建议您仔细审阅本使用条件，有任何疑问，请致函下列地址：
        </Content>
        <Content>
          [ <RedText>上海市黄浦区泰兴路 445 弄 26 号</RedText> ]
        </Content>
        <Content>
          您也可以发送电子邮件于：[ lamy_admin@163.com ]
        </Content>
      </Wrapper>
    );
  }
}


export default DisclaimerComponent;
