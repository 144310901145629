import React from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';

import { PageContainer } from '../Common';
import { media } from '../../styles/breakpoints';

const Wrapper = styled.div`
  background-color: #fff;
`;

const LogoWrapper = styled.div`
  margin-bottom: 50px;
`;

const MainContainerWrapper = styled.div`
  background-color: #f2f2f2;
`;

const MainContainer = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f2f2f2;
  width: 70%;
  padding: 100px 100px 150px;

  ${media.phone`
    padding: 20px;
    width: 100%;
  `};
`;


const Header = styled.div`
  width: 100%;
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;

  p {
    margin-bottom: 5px;
  }
`;

const SubHeader = styled.div`
  width: 100%;
  font-size: 15px;
  margin-bottom: 20px;
  color: #808080;

  p {
    margin-bottom: 5px;
  }
`;


const InputHeader = styled.div`
  width: 100%;
  margin-bottom: 5px;
  text-align: left;
  color: #808080;
`;

const Input = styled.input`
  margin-bottom: 15px;
  border-radius: 0px !important;
`;

const PopupWrapper = styled.div`
  padding: 100px 200px 120px;

  ${media.phone`
    padding: 80px 25px;
  `};
`;

const PopupHeader = styled.div`
  display: flex;
  align-items: center;
  font-size: 30px;
  font-weight: bold;
  justify-content: center;
  font-style: italic;

  span {
    padding: 20px 20px;
  }
  
  ${media.phone`
    font-size: 20px;
    
    span {
      padding: 10px;
    }
  `};
`;

const PopupContent = styled.div`
  margin-top: 10px;

  ${media.phone`
    font-size: 14px;
  `};
`;

const Button = styled.div`
  width: 100%;
  margin-top: 20px;
  padding: 8px;
  background: #000;
  color: #fff;
  cursor: pointer;
  text-align: center;
`;

const Line = styled.div`
  width: 50px;
  border-bottom: solid 1px #000;
`;

const EnhancedDialog = styled(Dialog)`
  & > div > div {
    margin: 5px;
  }
`;

const ForceUpdateUserDataPageComponent = ({ onSubmit }) => {
  const [showModel, setShowModel] = React.useState(true);
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [password2, setPassword2] = React.useState('');
  const [addressReceiver, setAddressReceiver] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [addressPhone, setAddressPhone] = React.useState('');

  const onFirstNameChange = React.useCallback((event) => {
    setFirstName(event.target.value);
  }, [setFirstName]);

  const onLastNameChange = React.useCallback((event) => {
    setLastName(event.target.value);
  }, [setLastName]);

  const onPasswordChange = React.useCallback((event) => {
    setPassword(event.target.value);
  }, [setPassword])

  const onPassword2Change = React.useCallback((event) => {
    setPassword2(event.target.value);
  }, [setPassword2])

  const onAddressReceiverChange = React.useCallback((event) => {
    setAddressReceiver(event.target.value);
  }, [setAddressReceiver])

  const onAddressChange = React.useCallback((event) => {
    setAddress(event.target.value);
  }, [setAddress])

  const onAddressPhoneChange = React.useCallback((event) => {
    setAddressPhone(event.target.value);
  }, [setAddressPhone])


  const onModelClose = React.useCallback(() => {
    setShowModel(false);
  }, [setShowModel]);

  const onSumbitBtnClick = React.useCallback(() => {
    onSubmit({ firstName, lastName, password, password2, addressReceiver, address, addressPhone });
  }, [firstName, lastName, password, password2, addressReceiver, address, addressPhone])

  return (
    <PageContainer>
      <Wrapper>
        <LogoWrapper>
          <img src="/images/product/desktop_banner_logo.png" alt="" />
        </LogoWrapper>

        <MainContainerWrapper>
          <MainContainer>
            <Header>填寫資料</Header>
            <SubHeader>请填寫以下資料</SubHeader>
            <InputHeader>姓</InputHeader>
            <Input className="form-control" name="lastName" value={lastName} onChange={onLastNameChange} />
            <InputHeader>名</InputHeader>
            <Input className="form-control" name="firstName" value={firstName} onChange={onFirstNameChange} />
            <InputHeader>密码</InputHeader>
            <Input className="form-control" type="password" name="password" value={password} onChange={onPasswordChange} />
            <InputHeader>再次输入密码</InputHeader>
            <Input className="form-control" type="password" name="password2" value={password2} onChange={onPassword2Change} />
            <InputHeader>常用送货地址</InputHeader>
            <Input className="form-control" name="address" value={address} onChange={onAddressChange} />
            <InputHeader>常用送货地址收货人 (收件人必须为实名, 否则不会发货)</InputHeader>
            <Input className="form-control" name="addressReceiver" value={addressReceiver} onChange={onAddressReceiverChange} />
            <InputHeader>常用送货地址收货人电话号码</InputHeader>
            <Input className="form-control" name="addressPhone" value={addressPhone} onChange={onAddressPhoneChange} />

            <Button onClick={onSumbitBtnClick}>提交</Button>
          </MainContainer>
        </MainContainerWrapper>
      </Wrapper>

      <EnhancedDialog open={showModel} maxWidth="xl">
        <PopupWrapper>
          <PopupHeader>
            <Line />
            <span>欢迎来到LAMY</span>
            <Line />
          </PopupHeader>
          <PopupContent>初次登入新网站的用户需要重新输入个人资料，</PopupContent>
          <PopupContent>以确保用户资料的完整性。</PopupContent>

          <Button onClick={onModelClose}>
            填寫資料
          </Button>
        </PopupWrapper>
      </EnhancedDialog>
    </PageContainer>
  )
};

export default ForceUpdateUserDataPageComponent;