import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import { PageContainer } from '../Common';
import { media } from '../../styles/breakpoints';
import { withPopup } from '../../context';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 50px;
  background-color: #f2f2f2;

  ${media.phone`
    padding: 30px 10px;
  `};

  input[type="file"] {
    display: none;
  }
`;

const InputHeader = styled.div`
  width: 100%;
  margin-top: 30px;
  margin-bottom: 5px;
  text-align: left;
  color: #808080;
`;

const Select = styled.select`
  width: 300px !important;
  margin-bottom: 15px;
  border-radius: 0px !important;
`;

const Input = styled.input`
  width: 300px !important;
  margin-bottom: 15px;
  border-radius: 0px !important;
`;

const InputArea = styled.textarea`
  margin-bottom: 15px;
  border-radius: 0px !important;
  min-height: 100px;
`;

const Button = styled.button`
  margin-top: 10px;
  width: 70px;
  background-color: #000;
  padding: 7px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
`;

const UploadButton = styled.label`
  width: 100%;
  margin-top: 10px;

  div {
    background-color: #000;
    padding: 7px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  img {
    padding: 5px;
    max-height: 300px;
    max-width: 100%;
  }
`;

const types = ['image/png', 'image/jpeg', 'image/gif'];
const getDataURLFromFile = (inputFile) => {
  const temporaryFileReader = new FileReader();

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    temporaryFileReader.onload = () => {
      resolve(temporaryFileReader.result);
    };
    temporaryFileReader.readAsDataURL(inputFile);
  });
};

const OrderReturnRequest = React.memo(({ orderId, courierNo, sendReturnRequest, togglePopup }) => {
  const [type, setType] = React.useState('return');
  const [comment, setComment] = React.useState('');
  const [images, setImages] = React.useState([]);

  const onCommentChange = React.useCallback((e) => {
    setComment(e.target.value);
  }, [setComment]);

  const onTypeChange = React.useCallback((e) => {
    setType(e.target.value);
  }, [setType]);

  const onAddImage = React.useCallback(async (e) => {
    try {
      if (e.target.files) {
        const files = Array.from(e.target.files);

        // Validation
        if (files.length > 5) {
          throw new Error('最多只能上传5张图片');
        }

        files.forEach((file) => {
          console.log(file.size);
          if (file.size > 5 * 1024 * 1024) {
            throw new Error('单个上传档案不能大于5MB');
          }

          // Catching wrong file types on the client
          if (types.every(type => file.type !== type)) {
            throw new Error('只接受png, jpg 或gif档案');
          }
        });

        const pFiles = files.map((file) => getDataURLFromFile(file));
        const uploadImages = await Promise.all(pFiles);

        setImages(uploadImages);
      }
    } catch (e) {
      togglePopup({
        type: 'info',
        message: e.message
      });
    }
  }, [images, setImages, togglePopup]);

  const onFormSubmit = React.useCallback(() => {
    sendReturnRequest({ type, comment, images });
  }, [type, comment, sendReturnRequest, images]);

  return (
    <PageContainer title="申请退货/换货" subTitle="出货10天内才能申请退/换货" backBtnLink={`/orderDetail?id=${orderId}`} disableMargin>
      <Wrapper>
        <InputHeader>申请服务</InputHeader>
        <Select className="form-control" value={type} onChange={onTypeChange}>
          <option value="RETURN">退货</option>
          <option value="EXCHANGE">换货</option>
        </Select>
        <InputHeader>出问题的订单号码</InputHeader>
        <Input className="form-control" value={orderId} disabled />
        <InputHeader>出问题的送货编号</InputHeader>
        <Input className="form-control" value={courierNo} disabled />
        <InputHeader>上载出现问题的图片 (至少上载一幅图片)</InputHeader>
        <UploadButton htmlFor="multi">
          <div>添加图片(请确保图片清晰可见)</div>
        </UploadButton>
        <ImageContainer>
          { images && images.length > 0 && images.map((image, idx) => (
            <img key={idx} src={image} alt="" />
          ))}
        </ImageContainer>

        <input type="file" id="multi" multiple onChange={onAddImage} />
        <InputHeader>您的留言</InputHeader>
        <InputArea className="form-control" value={comment} onChange={onCommentChange} />

        <Button onClick={onFormSubmit}>发送</Button>
      </Wrapper>
    </PageContainer>
  )
});

export default withRouter(
  withPopup(
    OrderReturnRequest
  )
);