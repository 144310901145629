import React from 'react';
import styled from 'styled-components';
import { Link, withRouter } from 'react-router-dom';
import Collapse from '@material-ui/core/Collapse';
import MenuIcon from '@material-ui/icons/Menu';

import HeaderSearch from './HeaderSearch';
import { MobileMenu } from '../';
import { withAuth } from '../../../context';
import { media, TabletAndDesktop, MobileOnly } from '../../../styles/breakpoints';

const navBarRoute = [
  {
    'route': '/',
    'text': '首页'
  },
  {
    'route': '/category',
    'text': '购买'
  },
  {
    'route': '/company',
    'text': '关于我们'
  },
  {
    'route': '/map',
    'text': '零售商'
  },
  {
    'route': '/community',
    'text': '社区'
  },
];

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 30px;

  display: flex;
  flex-direction: column;

  ${media.phone`
    margin-bottom: 0px;
  `}
`;

const TopWrapper = styled.div`
`;

const Logo = styled.div`
  float: left;
  margin: 1.25em 0 1em 0;
  min-height: 45px;

  ${media.phone`
    margin-left: 20px;
  `}

  img {
    width: 180px;
  }
`;

const NavMeta = styled.div`
  float: right;
  font-size: 0.875em;
  font-size: 0.875rem;
  margin: 0.25em 0;
`;

const NavMetaItem = styled.div`
  margin: 0;
  display: inline;
  padding: 0.125em 0.75em;
  position: relative;
  cursor: pointer;

  :hover {
    background: rgba(0, 0, 0, 0.4);
    color: #fff;

    a, span {
      color: #fff;
    }
  }
`;

const NavBar = styled.div`
  position: relative;
  height: 45px;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.33);
  background-image: linear-gradient(180deg, rgb(232, 232, 232) 0%, rgb(206, 206, 206) 100%);
  padding-right: 45px;
  overflow: hidden;
  z-index: 999;
`;

const NavBarList = styled.ul`
  width: 100%;
  display: table;
  border-right: 1px solid #fff;
`;

const NavBarItem = styled.li`
  display: table-cell;
  width: 18%;
  text-align: center;

  a {
    color: #333;
    line-height: 45px;
    display: block;
    border-right: 1px solid rgba(255, 255, 255, .67);
  }

  :hover {
    background: rgba(0, 0, 0, 0.5);

    a {
      color: #FFF;
    }
  }
`;

const MobileMenuWrapper = styled.div`
  color: #555;
  position: relative;
  float: right;
  padding: 10px 10px;
  margin: 15px;

  svg {
    font-size: 30px;
  }
`;

const Header = ({ auth, clearCartItem, cartItemCount, history, location }) => {
  const [expanded, setExpanded] = React.useState(false);

  const onMenuIconClick = React.useCallback(() => {
    setExpanded(!expanded);
  }, [expanded, setExpanded]);

  const onMobileMenuRouteChange = React.useCallback(() => {
    setExpanded(false);
  }, [setExpanded]);

  const onLogoutClicked = React.useCallback(() => {
    auth.logout();
    clearCartItem();

    history.push('/');
  }, [auth, history, clearCartItem]);

  return (
    <React.Fragment>
      <Wrapper>
        <TopWrapper>
          <Logo>
            <Link to="/">
              <img src="/images/header/img_lamylogo.png" alt="Lamy Logo"/>
            </Link>
          </Logo>
          <TabletAndDesktop>
            <NavMeta>
              <ul>
                <Link to="/contact-us">
                  <NavMetaItem>联络我们</NavMetaItem>
                </Link>
                { auth.isAuthed() && cartItemCount > 0 && (
                  <Link to="/cart">
                    <NavMetaItem>{`我的购物车(${cartItemCount})`}</NavMetaItem>
                  </Link>
                )}
                { auth.isAuthed() ? (
                  <React.Fragment>
                    <Link to="/user">
                      <NavMetaItem>用户资料</NavMetaItem>
                    </Link>
                    <Link to="/order">
                      <NavMetaItem>我的订单</NavMetaItem>
                    </Link>
                    <NavMetaItem onClick={onLogoutClicked}>
                      <span>登出</span>
                    </NavMetaItem>
                  </React.Fragment>
                ) : (
                  <Link to="/login">
                    <NavMetaItem>注册/登录</NavMetaItem>
                  </Link>
                )}
              </ul>
            </NavMeta>
          </TabletAndDesktop>
          <MobileOnly>
            { location.pathname !== '/' &&
              <MobileMenuWrapper>
                <MenuIcon onClick={onMenuIconClick} />
              </MobileMenuWrapper>
            }
          </MobileOnly>
        </TopWrapper>
        <TabletAndDesktop>
          <NavBar>
            <NavBarList>
              {
                navBarRoute.map(({ route, text }) => (
                  <NavBarItem key={route}>
                    <Link to={route}>{text}</Link>
                  </NavBarItem>
                ))
              }
            </NavBarList>
            <HeaderSearch />
          </NavBar>
        </TabletAndDesktop>
      </Wrapper>
      <MobileOnly>
        { location.pathname !== '/' &&
          <Collapse in={expanded}>
            <MobileMenu onRouteChange={onMobileMenuRouteChange} />
          </Collapse>
        }
      </MobileOnly>
    </React.Fragment>
  );
}

export default withRouter(
  withAuth(
    React.memo(Header)
  )
)