import React from 'react';
import styled from 'styled-components';

import { CaptchaValidateForm, PageContainer } from '../Common';

const MainContaineWrapper = styled.div`
  background-color: #f2f2f2;
`;

const MainContainer = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f2f2f2;
`;

const InputHeader = styled.div`
  width: 100%;
  margin-bottom: 5px;
  text-align: left;
  color: #808080;
`;

const Input = styled.input`
  margin-bottom: 15px;
  border-radius: 0px !important;
`;

const Button = styled.button`
  width: 100%;
  margin-top: 50px;
  font-weight: 600;
  border: solid 1px #dadada;
  padding: 10px;
  background-color: #ebecf0;
  cursor: pointer;
`;

const RegisterPageComponent = ({ forgetPassword }) => {
  const [pw, setPw] = React.useState('');
  const [pw2, setPw2] = React.useState('');

  const onPwChange = React.useCallback((event) => {
    setPw(event.target.value);
  }, [setPw]);

  const onPw2Change = React.useCallback((event) => {
    setPw2(event.target.value);
  }, [setPw2])

  const onSubmit = React.useCallback(async (e, phone, pin) => {
    e.preventDefault();

    forgetPassword({
      pw, pw2, phone, pin
    })
  }, [forgetPassword, pw, pw2]);

  return (
    <PageContainer title="忘记密码" backBtnLink="/login">
      <MainContaineWrapper>
        <CaptchaValidateForm enablePhoneInput authHeader="手机获取验证" formHeader="修改密码" validatePhoneRoutePrefix="forget-password">
          {({ phone, pin }) => (
            <MainContainer>
              <InputHeader>輸入新密碼</InputHeader>
              <Input className="form-control" type="password" name="password" value={pw} onChange={onPwChange} />
              <InputHeader>再次輸入新密碼</InputHeader>
              <Input className="form-control" type="password" name="password2" value={pw2} onChange={onPw2Change} />

              <Button onClick={(e) => onSubmit(e, phone, pin)}>添加</Button>
            </MainContainer>
          )}
        </CaptchaValidateForm>
      </MainContaineWrapper>
    </PageContainer>
  )
};

export default RegisterPageComponent;