import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import lodashGet from 'lodash/get';

import { withAuth, withPopup } from '../../../context'
import config from '../../../config';
import EditPhonePageComponent from './EditPhonePageComponent';
import { SimpleModal } from '../../Common';

class EditPhonePageContainer extends React.Component {
  state = {
    openSuccessModal: false,
  }

  validatePassword = async (password) => {
    let isValid = false;
    try {
      const validateUrl = `${config.serverEndPoint}/validate-password`;

      const result = await axios.post(validateUrl, { password });

      isValid = result && result.data && result.data.isValid;
    } catch (e) {
      console.log(e);
    }

    return isValid;
  }

  editPhone = async (phone, pin) => {
    const { togglePopup, auth } = this.props;

    try {
      const createUrl = `${config.serverEndPoint}/userPhone`;

      await axios.put(createUrl, { phone, pin });

      auth.logout();
      this.setState({ openSuccessModal: true })
    } catch (err) {
      console.log(err);
      const responseMsg = lodashGet(err, 'response.data.message', '更新失败');
      togglePopup({
        type: 'info',
        message: responseMsg,
      })
    }
  }

  closeSuccessModal = () => {
    const { history } = this.props;

    this.setState({ openSuccessModal: false });

    history.push('/');
  }

  render() {
    const { openSuccessModal } = this.state;

    return (
      <React.Fragment>
        <EditPhonePageComponent editPhone={this.editPhone} validatePassword={this.validatePassword} />

        <SimpleModal
          confirmMsg="更改电话号码成功，请重新登入"
          visible={openSuccessModal}
          confirmCallback={this.closeSuccessModal} />
      </React.Fragment>
    )
  }
};

export default withRouter(
  withPopup(
    withAuth(EditPhonePageContainer)
  )
);
