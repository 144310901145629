import React from 'react';
import styled from 'styled-components';

import { ProductFooter, ProductShowcase } from '../../Common';
import { ProductItem } from '../Components';
import { media } from '../../../styles/breakpoints';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  align-items: center;
`;

const ContentWrapper = styled.div`
  width: 100%;
  padding: 20px 80px;

  ${media.tablet`
    padding: 50px;
  `};

  ${media.phone`
    padding: 20px;
  `};
`;

const Header = styled.div`
  text-align: left;
  font-size: 24pt;
  margin-bottom: 1em;
  color: gray;

  ${media.phone`
    font-size: 16pt;
  `};
`;

const Description = styled.div`
  padding-bottom: 50px;
  border-bottom: solid 1px #ccc;
  margin-bottom: 20px;

  p {
    text-align: left;
    font-size: 16px;
    line-height: 200%;
    font-weight: normal;
    color: #999;
  }
`;

const ProductSeriesListPageComponent = ({ seriesId, seriesLogo, pageTitle, pageDesc, bannerItems, productItems, addItemToCart }) => {
  return (
    <Wrapper>
      <ProductShowcase
        seriesId={seriesId}
        seriesImage={seriesLogo}
        productImages={bannerItems}
        imagePrefix="productseries" />

      <ContentWrapper>
        <Header>{pageTitle}</Header>
        <Description>
          <p>
            {pageDesc}
          </p>
        </Description>

        { productItems && productItems.map((product) => (
          <ProductItem key={product.id} product={product} addItemToCart={addItemToCart} hideSeriesImage />
        ))}

        <ProductFooter />
      </ContentWrapper>
    </Wrapper>
  )
};

export default React.memo(ProductSeriesListPageComponent);