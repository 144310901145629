import React from 'react';
import styled from 'styled-components';
import Modal from '@material-ui/core/Modal';
import { media } from '../../styles/breakpoints';

function getModalStyle() {
    const top = 0;
    const left = 25;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(${top}%, ${left}%)`,
        display: 'inline-block',
    }

}

const Images = styled.div`
display :flex;
flex-wrap: wrap
justify-content: space-evenly;
`
const Img = styled.img`
width:100%
`

const Content = styled.div`
text-align:justify;
margin:5% 0 10% 0;
color: gray;
${media.tablet`
padding:20px
`};

${media.phone`
padding:20px
`}; 
`
const Title = styled.h2`
font-size: 20pt;
`
const Body = styled.p`
font-size: 12pt;
line-height: 20pt;
`

class Germany extends React.Component {
    state = {
        open1: false,
        open2: false,
        open3: false,
        open4: false,
    }
    handleOpen1 = () => {
        this.setState({ open1: true });
    };
    handleOpen2 = () => {
        this.setState({ open2: true });
    };
    handleOpen3 = () => {
        this.setState({ open3: true });
    };
    handleOpen4 = () => {
        this.setState({ open4: true });
    };
    handleClose1 = () => {
        this.setState({ open1: false });
    };
    handleClose2 = () => {
        this.setState({ open2: false });
    };
    handleClose3 = () => {
        this.setState({ open3: false });
    };
    handleClose4 = () => {
        this.setState({ open4: false });
    };
    render() {
        return (
            <div>
                <Images>
                    <div><img  onClick={this.handleOpen1} id="artImage1" src="./images/brand/heidelberg_b1a_ger.jpg" alt="" /></div>
                    <div><img  onClick={this.handleOpen2} id="artImage1" src="./images/brand/heidelberg_b2a_ger.jpg" alt="" /></div>
                    <div><img  onClick={this.handleOpen3} id="artImage1" src="./images/brand/heidelberg_b3a_ger.jpg" alt="" /></div>
                </Images>
            <div className="row">
                <div className="col-xs-1 col-lg-1 col-md-1"></div>
                <div className="col-xs-10 col-lg-10 col-md-10">
                    <Modal open={this.state.open1} onClose={this.handleClose1}>
                        <div style={getModalStyle()}>
                            <Img className="modalImage" src="./images/brand/heidelberg_b1_ger.jpg" alt="" />
                        </div>
                    </Modal>
                    <Modal open={this.state.open2} onClose={this.handleClose2}>
                        <div style={getModalStyle()}>
                            <Img className="modalImage" src="./images/brand/heidelberg_b2_ger.jpg" alt="" />
                        </div>
                    </Modal>
                    <Modal open={this.state.open3} onClose={this.handleClose3}>
                        <div style={getModalStyle()}>
                            <Img className="modalImage" src="./images/brand/heidelberg_b3_ger.jpg" alt="" />
                        </div>
                    </Modal>
                    
                    
                    <br />
                    <Content>
                        
                        <div >
                            <Title>百分百德国制造</Title>
                            <br />
                            <Body>
                                LAMY书写工具深受世界各地人士喜爱，时而见于纽约证劵交易所里、东京大小会议上、或是阿根廷的某某大学演讲厅内；但其实每件LAMY的书写工具，都是在海德堡诚意献制。这里座拥德国最古老的大学，举世著名的古堡、旧城及纳卡河，从这里驾车只需数分钟，便至LAMY的基地，研制及生产大小墨水笔、宝珠笔、原子笔和铅芯笔，自1996年来默默耕耘，不断创造出美轮美奂的现代设计产品。
                            <br />
                                <br />
                                全球化成为大趋势，纵使如此，我们仍然会在未来秉持这个传统，皆因LAMY已于海德堡扎下深厚的根基，在市内及市外范围拥有400多名员工；文化方面，它既是忠烈的守护者，亦是热心的赞助人。再者，LAMY作为一间家族承继的公司，对其根据地的优厚特质最了解不过，LAMY的优势与信誉，多多少少要归功于这座几百年来汇集科学、研究精神与都会魅力于一身的活力之都。
                            <br />
                                <br />
                            </Body>
                        </div>
                    </Content>
                </div>
                <div className="col-xs-1 col-lg-1 col-md-1"></div>
            </div>
                
            </div>
        )
    }

}

export default Germany