import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';

import config from '../../config';
import SearchPageComponent from './SearchPageComponent';
import { getQueryStringByKey } from '../../utils';

class SearchPageContainer extends React.Component {
  constructor(props) {
    super(props);

    const { location } = props;
    const searchText = getQueryStringByKey(location.search, 't', '');

    this.state = {
      searchText: searchText,
      searchResult: [],
    }
  }

  componentDidMount() {
    this.fetchSearchResult();
  }

  getPageData() {
    const { searchText } = this.state;

    const fetchUrl = `${config.serverEndPoint}/searchPage?t=${searchText}`;

    return axios.get(fetchUrl)
      .then(result => result.data)
      .catch(err => {
        console.log(err);
      });
  }

  fetchSearchResult = async () => {
    const result = await this.getPageData();

    const { searchResult } = result ? result : { searchResult: [] };

    this.setState({ searchResult });
  }

  onSearchTextChange = (text) => {
    const { history } = this.props;

    history.push(`/search?t=${text}`)

    this.setState({
      searchText: text
    })
  }

  render() {
    const { searchText, searchResult } = this.state;

    return (
      <SearchPageComponent
        searchText={searchText}
        onSearchTextChange={this.onSearchTextChange}
        fetchSearchResult={this.fetchSearchResult}
        searchResult={searchResult} />
    )
  }
};

export default withRouter(SearchPageContainer);

