export default function countedThrottle(func, timeout, options = {}) {
  const maxCount = options.maxCount || 1;
  const onThrottled = options.onThrottled || (() => {});

  let history = [];

  const throttled = (...args) => {
    // lazy clean up
    const now = Date.now();
    history = history.filter(x => (now - x.time) <= timeout)

    if (history.length >= maxCount) {
      onThrottled(now, args)
      return
    }

    func(...args)
    history.push({ time: now, args })
  }

  return throttled;
}