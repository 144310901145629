import React from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";

import { withAuth } from '../../../context';
import { Dialog } from '@material-ui/core';

const Wrapper = styled.ul`
  position: fixed;
  bottom: 0;
  display: flex;
  width: 100vw;
  z-index: 999;

  background-color: rgb(255, 255, 255);
`;

const ListItem = styled.li`
  flex: 1;
  padding: 6px 4px;
  border-right: solid 1px #dfdfdf;

  img {
    padding: 5px;
    width: 100%;
    border-bottom: solid 1px #dfdfdf;
  }
`;

const MobileNavBar = ({ auth }) => {
  const [isWechatQrCodeOpen, setIsWechatQrCodeOpen] = React.useState();

  const onWechatQrCodeClick = React.useCallback(() => {
    setIsWechatQrCodeOpen(true);
  }, [setIsWechatQrCodeOpen]);

  const onWechatQrCodeClose = React.useCallback(() => {
    setIsWechatQrCodeOpen(false);
  }, [setIsWechatQrCodeOpen]);

  return (
    <Wrapper>
      <ListItem>
        <Link to={auth.isAuthed() ? '/user' : '/login'}>
          <img src="/images/footer/footer_user.png" alt="" />
        </Link>
      </ListItem>
      <ListItem>
        <Link to="/cart">
          <img src="/images/footer/footer_shopping.png" alt="" />
        </Link>
      </ListItem>
      <ListItem onClick={onWechatQrCodeClick}>
        <img src="/images/footer/footer_wechat.png" alt="" />
      </ListItem>
      <ListItem>
        <a href="http://weibo.com/lamychinahk" target="_blank" rel="noopener noreferrer">
          <img src="/images/footer/footer_weibo.png" alt="" />
        </a>
      </ListItem>
      <ListItem>
        <a href="https://lamy.tmall.com" target="_blank" rel="noopener noreferrer">
          <img src="/images/footer/footer_tmall.png" alt="" />
        </a>
      </ListItem>
      <ListItem>
        <a href="mailto:cs@lamy.com.hk">
          <img src="/images/footer/footer_email.png" alt="" />
        </a>
      </ListItem>
      <ListItem>
        <Link to="/contact-us">
          <img src="/images/footer/footer_enquiry.png" alt="" />
        </Link>
      </ListItem>

      <Dialog open={isWechatQrCodeOpen} onClose={onWechatQrCodeClose}>
        <img src="/images/footer/lamy_wechatQR.png" alt="" />
      </Dialog>
    </Wrapper>
  );
}

export default React.memo(
  withAuth(
    MobileNavBar
  )
);