import React from 'react';
import styled from 'styled-components';

import { media } from '../../../../styles/breakpoints';

const SwipeWrapper = styled.div`
  width: 485px;
  height: 40px;
  line-height: 40px;
  background-color: #fff;
  position: relative;
  margin: 0 auto 30px;

  ${media.phone`
    width: 270px;
  `};
`;

const BackgroundContainer = styled.div.attrs(({ offsetX, swiping }) => ({
  style: {
    width: offsetX ? `${offsetX}px` : 0,
    transition: swiping ? '' : 'width 1s ease'
  }
}))`
  height: 100%;
  position: absolute;
  background-color: #7ac23c;
`

const TextContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  user-select: none;

  color: ${props => props.success ? '#fff' : '#000'};
`;

const Button = styled.div.attrs(({ offsetX, swiping }) => ({
  style: {
    left: offsetX ? `${offsetX}px` : 0,
    transition: swiping ? '' : 'left 1s ease'
  }
}))`
  width: 40px;
  height: 40px;
  position: absolute;
  border: 1px solid #ccc;
  cursor: move;
  text-align: center;
  background-color: #fff;
  user-select: none;
  color: #666;
`;

class Swipe extends React.Component {
  state = {
    success: false,
    offsetX: 0,
    swiping: false
  }

  onMobileSwipe = (e) => {
    const { onSuccess } = this.props;

    const distance = 270 - 40;
    const downX = e.touches[0].clientX;

    this.setState({ swiping: true });

    document.ontouchmove = (e) => {
      const moveX = e.touches && e.touches[0] ? e.touches[0].clientX : 0;
      let offsetX = moveX - downX;

      if (offsetX > distance) {
        offsetX = distance;
      } else if (offsetX < 0) {
        offsetX = 0;
      }

      this.setState({ offsetX });

      if (offsetX === distance) {
        document.ontouchmove = null;

        this.setState({ success: true });
        if (onSuccess) {
          onSuccess();
        }
      }
    }

    document.ontouchend = (e) => {
      if (this.state.success) {
        return;
      } else {
        this.setState({ offsetX: 0, isSwiping: false });
      }

      document.ontouchmove = null;
      document.ontouchend = null;
    }

  }

  onSwipe = (e) => {
    const { onSuccess } = this.props;

    const distance = 485 - 40;
    e = e || window.event;
    const downX = e.clientX;

    this.setState({ swiping: true });

    document.onmousemove = (e) => {
      e = e || window.event;
      const moveX = e.clientX;
      let offsetX = moveX - downX;

      if (offsetX > distance) {
        offsetX = distance;
      } else if (offsetX < 0) {
        offsetX = 0;
      }

      this.setState({ offsetX });

      if (offsetX === distance) {
        document.onmousemove = null;

        this.setState({ success: true });
        if (onSuccess) {
          onSuccess();
        }
      }
    }

    document.onmouseup = (e) => {
      if (this.state.success) {
        return;
      } else {
        this.setState({ offsetX: 0, swiping: false });
      }

      document.onmousemove = null;
      document.onmouseup = null;
    }
  }

  onSelect = () => {
    return false;
  }

  render() {
    const { offsetX, success, swiping } = this.state;
    return (
      <SwipeWrapper>
        <BackgroundContainer offsetX={offsetX} swiping={swiping}></BackgroundContainer>
        <TextContainer onSelect={this.onSelect} success={success}>{ success ? '验证通过' : '向右滑动解锁' }</TextContainer>
        <Button
          onSelect={this.onSelect}
          onMouseDown={success ? null : this.onSwipe}
          onTouchStart={success ? null : this.onMobileSwipe}
          offsetX={offsetX}
          swiping={swiping}
        >
          &gt;
        </Button>
      </SwipeWrapper>
    );
  }
}

export default Swipe;