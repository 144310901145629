import React from 'react';
import axios from 'axios';
import config from '../../config';
import styled from 'styled-components';

import { media } from '../../styles/breakpoints';

const SCROLL_DISTANCE = 120;

const TabWrapper = styled.div`
  display: flex;
  overflow: hidden;
  padding-bottom: 2px;
  border-bottom: 1px dotted #d9d9d9;

  ${media.phone`
  `};
`;

const TabList = styled.div`
  display: flex;
  transition: transform 0.4s ease 0s;

  transform: ${props => `translate3d(${props.tabShiftPos}px, 0px, 0px)`};
`;

const Button = styled.div`
  background-color: 'fff';
`

const Tab = styled.div`
  text-align: center;
  font-size: 18px;
  line-height: 40px;
  cursor: pointer;
  
  background-color: 'fff';
  
  :hover {
    color: #fff;
  }

  img {
    width: 120px;
  }

  ${media.tablet`
    width: 150px;
    height: 40px;
  `};

  ${media.phone`
    width: 60px;
    height: 30px;
    line-height: 30px;
    font-size: 16px;
  `};
`;

const Wrapper = styled.div`
  text-align: left;
  margin: 0 auto 10%;

  p {
    font-size: 14px;
    line-height: 200%;
    color: gray;
    white-space: pre-line;
  }
`;

const Title = styled.h2`
  margin-bottom: 20px;
  font-size: 20pt;
  color: gray;
`;

const ArrowContainer = styled.div`
	display: flex;
	justify-content: space-between;
  margin-top: 50px;
  margin-bottom: -30px;
`;

const NextArrow = styled.span`
	cursor: pointer;
	margin: 0px 10px;
	background: url('/images/about/btn_next.gif') no-repeat scroll 0 0;
	width: 30px;
	height: 30px;

	&:hover {
		background-position: 0 -30px;
	}
`;

const PrevArrow = styled.span`
	cursor: pointer;
	margin: 0 15px;
	background: url('/images/about/btn_prev.gif') no-repeat scroll 0 0;
	width: 30px;
	height: 30px;

	&:hover {
		background-position: 0 -30px;
	}
`;

class Highlight extends React.Component {
  state = {
    value: 0,
    list: [{
      date1: "2012",
      description1: `设计：Sieger Design
      ↵
      ↵对比产生张力，张力意味激情。LAMY天阶系列带来动人的书写体验，因为其与众不同的吸引力来自于强烈的对比。
      ↵
      ↵典型的Lamy风格令人印象深刻：一方面，这是由于其纯粹的功能设计和高精密的手工艺，例如藏于精钢笔身之下的精致笔夹装置；
      ↵
      ↵另一方面，该系列不仅启发智力，更让您一见倾心。例如，哑光黑色笔身与高光镀铬材质的两端和笔夹形成迷人的对比。同时其重量出人意料地适手，这体现了产品的高品质并令使用者乐于书写。
      ↵
      ↵最重要的是，LAMY天阶系列将为所有热衷于现代高品质工艺并乐于展现生活中美好事物的人士带来绝佳的书写体验。`,
      description2: "",
      description3: "",
      description4: "",
      id: 12,
      image1: "rgFjtyNOpjHN.gif",
      image2: "sBbEvaIQ4A3a.jpg",
      seqno: 0,
      title: "LAMY天阶系列 - 工艺永无止境",
    }],
    tabShiftPos: 0
  };

  async componentDidMount() {
    const result = await this.getPageData();

    this.setState({ list: result });
  }

  handleClick(target){
    this.setState({value:target})
  }

  getPageData = async () => {
    const fetchUrl = `${config.serverEndPoint}/highlightPage`

    try {
      const result = await axios.get(fetchUrl);

      return result.data;
    }
    catch (err) {
      console.log(err);
    }
  }

  handleChange = (value) => {
    this.setState({ value });
  };

  calcMaxScollablePos(list) {
		// 120px per item
		return (list.length - 1) * 120;
	}

	onPrevClick = () => {
    const { list, tabShiftPos } = this.state;

		if (!list || list.length === 0) {
			return;
		}

		if ((tabShiftPos + SCROLL_DISTANCE) < 0) {
			this.setState({ tabShiftPos: this.state.tabShiftPos + SCROLL_DISTANCE });
		} else {
			this.setState({ tabShiftPos: 0 });
		}
	}

	onNextClick = () => {
    const { list, tabShiftPos } = this.state;
		if (!list || list.length === 0) {
			return;
    }
    const maxScrollPos = this.calcMaxScollablePos(list);

		if (Math.abs(tabShiftPos - SCROLL_DISTANCE) < maxScrollPos) {
			this.setState({ tabShiftPos: this.state.tabShiftPos - SCROLL_DISTANCE });
		} else {
			this.setState({ tabShiftPos: -maxScrollPos });
		}
	}

  render() {
    const { value, list, tabShiftPos } = this.state;
    const selectedList = list && list[value];

    return (
      <div className="row">
        <div className="col-xs-12 col-lg-12 col-md-12">
          <TabWrapper>
            <TabList tabShiftPos={tabShiftPos}>
              { this.state.list.map((data, idx) => (
                <Button key={idx}>
                  <Tab selected={idx} onClick={()=>this.handleClick(idx)}>
                    <img src={`${config.imagePrefix}/designhighlights/${data.image1}`} alt="" />
                  </Tab>
                </Button>
              ))}
            </TabList>
          </TabWrapper>
          <ArrowContainer>
						<PrevArrow onClick={this.onPrevClick} />
						<NextArrow onClick={this.onNextClick} />
					</ArrowContainer>
          <Wrapper className="col-9">
            <div>
              <Title>{selectedList.date1} : {selectedList.title}</Title>
              <br />
              <img src={`${config.imagePrefix}/designhighlights/${selectedList.image2}`} alt=""></img>
              <br />
              <p>{selectedList.description1}</p>
            </div>
          </Wrapper>
        </div>
      </div>
    )
  }
}

export default Highlight