import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import lodashUniqBy from 'lodash/uniqBy';

import config from '../../config';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

const Header = styled.div`
  font-size: 18pt;
  color: gray;
  margin: 2.5% 5%;
  font-weight: bold;
`;

const YearRow = styled.div`
  font-size: 18px;
  background: #999;
  color: white;
  width: 100%;
  display: block;
  padding-left: 2.5%;
`;

const HistoryItem = styled.div`
	display: flex;
  align-items: flex-start;
	font-size: 12px;
	border-left: 1px solid #D9D9D9;
  color: gray;
`;

const HistoryImage = styled.img`
  margin: 5%;
  margin-right: 0;
`;

const HistoryDesc = styled.div`
  margin: 5%;
`;

class History extends React.Component {
  state = {
    list: [],
  }

	async componentDidMount() {
		const list = await this.getPageData();

		this.setState({ list });
	}

	getPageData = async () => {
		const fetchUrl = `${config.serverEndPoint}/historyPage`;

		try {
			const result = await axios.get(fetchUrl);

			return result.data;
		} catch (err) {
			console.log(err);
		}
	}

	render () {
		const { list } = this.state;

		return (
			<Wrapper>
        <Header>公司历史</Header>
        { list && list.map((item, idx) => {
          const shdRenderYear = idx === 0 || list[idx - 1].date1 !== item.date1;
          return (
            <React.Fragment>
              { shdRenderYear && <YearRow>{item.date1}</YearRow>}
              <HistoryItem>
                { item.image1 && <HistoryImage src={`${config.imagePrefix}/abouthistory/${item.image1}`} alt="" /> }
                <HistoryDesc>{item.description1}</HistoryDesc>
              </HistoryItem>
            </React.Fragment>
          )
        })}
			</Wrapper>
		);
	}
}
export default History;