import React from 'react';
import styled from 'styled-components';
import format from 'date-fns/format';
import xss from 'xss';

const StyledContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  text-align: left;
  margin-top: .25rem;
`;

const NameContainer = styled.div`
  font-weight: bold;
  flex: 1 1 100%;
  text-align: end;
`;

const MessageRow = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex: 1 1 100%;
`;

const Message = styled.div`
  background-color: #DDDDDD;
  padding: 0.5rem;
  max-width: 250px;
  overflow-wrap: break-word;
  border-radius: 4px;
`;

const TimeDisplay = styled.div`
  color: rgba(0, 0, 0, 0.3);
  padding: 0 0.5rem;
`;

const Img = styled.img`
  max-width: 220px;
`

const insertTextAtIndices = (text, obj) => {
  return text.replace(/./g, function(character, index) {
    return obj[index] ? obj[index] + character : character;
  });
};

const filter = new xss.FilterXSS({})

const UserMessage = ({ name, message, time, type }) => {
  message = filter.process(message);
  const urlMatches = message.match(/\b(http|https)?:\/\/\S+/gi) || [];

  if (type === 'TEXT') {
    urlMatches.forEach(link => {
      const startIndex = message.indexOf(link);
      const endIndex = startIndex + link.length;
      message = insertTextAtIndices(message, {
        [startIndex]: `<a href="${link}" target="_blank" rel="noopener noreferrer" class="message-link">`,
        [endIndex]: "</a>"
      });
    });
  }

  return (
    <StyledContainer>
      <NameContainer>{name}</NameContainer>
      <MessageRow>
        <TimeDisplay>{format(new Date(time * 1000), 'HH:mm')}</TimeDisplay>
        <Message>
          { type === 'TEXT'
            ? <span dangerouslySetInnerHTML={{ __html: message }} />
            : (<Img src={message} alt="product" />)
          }
        </Message>
      </MessageRow>
    </StyledContainer>
  );
}

export default UserMessage;
