import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import lodashGet from 'lodash/get';

import config from '../../../config';
import { PageContainer, InputWithButton } from '../../Common';
import { media } from '../../../styles/breakpoints';
import { withPopup } from '../../../context';

const Wrapper = styled.div`
  background-color: #f2f2f2;
`;

const MainContaineWrapper = styled.div`
  padding: 150px 100px;
  width: 70%;
  margin: auto;
  
  ${media.tablet`
    padding: 100px 30px;
  `};

  ${media.phone`
    width: 100%;
    padding: 30px 10px;
  `};
`;

const Header = styled.h2`
  text-align: center;
  font-weight: 600;
  font-size: 26px;
  margin-bottom: 30px;
`;

const InputHeaderRow = styled.div`
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
`;

const InputHeader = styled.div`
  width: 100%;
  margin-bottom: 5px;
  text-align: left;
  color: #808080;
`;

const InputHeaderWithError = styled.div`
  margin-bottom: 5px;
  color: #808080;
`;

const Error = styled.div`
  color: red;
`;

const Input = styled.input`
  margin-bottom: 15px;
  border-radius: 0px !important;
`;

const Button = styled.button`
  width: 100%;
  margin-top: 50px;
  font-weight: 600;
  border: solid 1px #dadada;
  padding: 10px;
  background-color: #ebecf0;
  cursor: pointer;
`;

const MainContainer = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f2f2f2;
`;

const EditPhonePageComponent = ({ editPhone, validatePassword, togglePopup }) => {
  const [phone, setPhone] = React.useState('');
  const [captcha, setCaptcha] = React.useState('');
  const [isValidated, setIsValidated] = React.useState(null);
  const [password, setPassword] = React.useState('');

  const onPasswordChange = React.useCallback((event) => {
    setPassword(event.target.value);
    setIsValidated(null);
  }, [setPassword, setIsValidated]);

  const onNextStepClick = React.useCallback(async () => {
    const isValid = await validatePassword(password);

    setIsValidated(isValid);
  }, [password, setIsValidated]);

  const onPhoneChange = React.useCallback((event) => {
    setPhone(event.target.value);
  }, [setPhone]);

  const onCaptchaChange = React.useCallback((event) => {
    setCaptcha(event.target.value);
  }, [setCaptcha])

  const onGetPin = React.useCallback(async () => {
    try {
      const generatePinUrl = `${config.serverEndPoint}/register/validate-phone`;
      const result = await axios.put(generatePinUrl, { phone }).then(result => result.data);

      if (result.error) {
        console.log(result.error);
        if (result.error === "REGISTERED") {
          result.message = "此电话号码已注册";
        }
        togglePopup({
          type: 'info',
          message: result.message,
        })
      }
    } catch (err) {
      console.log(err);
      const responseMsg = lodashGet(err, 'response.data.error', '更新失败');
      togglePopup({
        type: 'info',
        message: responseMsg,
      })
    }
  }, [phone]);

  const onSubmit = React.useCallback(async (e) => {
    e.preventDefault();

    editPhone(phone, captcha);
  }, [editPhone, phone, captcha])

  return (
    <PageContainer title="个人资料" backBtnLink="/user">
      <Wrapper>
        <MainContaineWrapper>
          <Header>更改电话号码</Header>
          { isValidated ? (
            <MainContainer>
              <InputHeader>输入新的手机号码</InputHeader>
              <InputWithButton
                name="phone"
                value={phone}
                onChange={onPhoneChange}
                btnText="获取验证码"
                onBtnClicked={onGetPin} />
              <InputHeader>输入验证码</InputHeader>
              <Input className="form-control" name="captcha" type="password" value={captcha} onChange={onCaptchaChange} />

              <Button onClick={onSubmit}>添加</Button>
            </MainContainer>
          ) : (
            <React.Fragment>
              <InputHeaderRow>
                <InputHeaderWithError>输入登录密码</InputHeaderWithError>
                { isValidated === false && <Error>密码错误</Error> }
              </InputHeaderRow>
              <Input className="form-control" name="password" type="password" value={password} onChange={onPasswordChange} />

              <Button onClick={onNextStepClick}>下一步</Button>
            </React.Fragment>
          )}
        </MainContaineWrapper>
      </Wrapper>
    </PageContainer>
  )
};

export default withPopup(EditPhonePageComponent);