import React from 'react';
import { withRouter } from 'react-router-dom';

import LoginComponent from './LoginComponent';
import { withAuth, withCart } from '../../context';

class LoginContainer extends React.PureComponent {
  onPhoneLogin = async (phone, password, isSwiped) => {
    const { auth } = this.props;

    const result = await auth.login(phone, password, isSwiped);

    if (result && result.success) {
      this.onLoginSuccess();
    }

    return result;
  }

  onLoginSuccess = () => {
    const { cart, history } = this.props;

    history.push('/');
    cart.updateCartItem();
  }


  render() {
    return (
      <LoginComponent onPhoneLogin={this.onPhoneLogin} />
    )
  }
}

export default withRouter(
  withCart(
    withAuth(LoginContainer)
  )
);