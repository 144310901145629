export default class SimpleSubscription {
  listeners = [];

  subscribe = (cb) => {
    this.listeners.push(cb);
    return () => {
      const idx = this.listeners.indexOf(cb);
      if(idx > -1) this.listeners.splice(idx, 1)
    }
  }

  notify = () => {
    this.listeners.forEach(cb => {
      cb();
    });
  }
}