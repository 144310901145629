import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { CompanyFooter } from '../Common';

import config from '../../config';
import { media,MobileOnly, TabletAndDesktop } from '../../styles/breakpoints';

const Wrapper = styled.div`
  background-color: #fff;
  .row{
    margin:0px
  }
`
const Img = styled.img`
  width: 100%;
`
const Header = styled.div`
  padding: 50px;
  width: 25%;
  display: block;
  margin: auto;
  ${media.tablet`
  box-sizing: content-box;
  width: 50%;
  `};

  ${media.phone`
  box-sizing: content-box;
  width: 50%;
  `};
`

const Year = styled.div`
  width: 100%; 
  text-align: center; 
  border-bottom: 1px solid #000; 
  line-height: 0.1em;
  margin: 10px 0 20px;
`
const Span = styled.span`
  background:#fff; 
  padding:0 10px; 
`
const Logo = styled.div`
`
const Title = styled.div`
  padding: 30px;
`

const Images = styled.div`
  display: flex;
  justify-content: center;

  div {
    margin: 0 30px;

    img {
      width: 100%;
    }
  }
`

const Content = styled.div`
  text-align: justify;
  padding: 30px;
`

class NewsFeedDetail extends React.Component{
  state= {
    data:[{
      date1: "",
      description1: `2018年8月22日
      ↵LAMY全新口袋系列-PICO Neo
      ↵
      ↵有着伊甸绿那般的吸引力，不由总想偷偷将它收入口袋
      ↵
      ↵现已上市，全国店铺，线上均有销售，售价540元`,
      description2: "",
      id: 196,
      image1: "kwNw5bTrUNE6.jpg",
      image2: "vE7sBV0UQyvP.jpg",
      image3: "xHHrRIpLq9wy.png",
      image4: "XwYJdmPjigZJ.png",
      seqno: 1,
      title: "偷尝禁果"
    }]
  }

  async componentDidMount() {
    const { match } = this.props;
    const data = await this.getPageData(match.params.id)
    this.setState({data:data})
  }

  getPageData = async (id) => {
    const fetchUrl = `${config.serverEndPoint}/newsPage/${id}`
    try {
      const result = await axios.get(fetchUrl);
      return result.data[0];
    } catch (err) {
      console.log(err);
    }
  }

  render() {
      const data = this.state.data
      return (
        <Wrapper>
          <MobileOnly>
            <Img src={`${config.imagePrefix}/news/${data.image4}`} alt=""></Img>
          </MobileOnly>
          <TabletAndDesktop>
            <Img src={`${config.imagePrefix}/news/${data.image1}`} alt=""></Img>
          </TabletAndDesktop>
          <div className="row">
            <div className="col-xs-1 col-lg-1 col-md-1"></div>
            <div className="col-xs-10 col-lg-10 col-md-10">
              <Wrapper>
                <Header>
                  <Year><Span>{data.date1}</Span></Year>
                </Header>
                <Logo><img src="/images/product/desktop_banner_logo.png" alt=""></img></Logo>
                <Title>{data.title}</Title>
                <Images>
                  <MobileOnly>
                    <div><img src={`${config.imagePrefix}/news/${data.image6}`} alt=""></img></div>
                  </MobileOnly>
                  <TabletAndDesktop>
                    <div><img src={`${config.imagePrefix}/news/${data.image3}`} alt=""></img></div>
                  </TabletAndDesktop>
                </Images>
            
                <Content>
                  { data.description2 && data.description2.split('\n').map((content, idx) => (
                      <div key={idx}>
                        <label className="inputlabel">{content}</label>
                      </div>
                  ))}
                </Content>

                <CompanyFooter />
              </Wrapper>
            </div>
            <div className="col-xs-1 col-lg-1 col-md-1"></div>
          </div>
        </Wrapper>
      )
  }
}

export default NewsFeedDetail