import React from 'react';
import styled from 'styled-components';
import { ProductFooter, Carousel } from '../Common';
import config from '../../config';
import axios from 'axios';

const Wrapper = styled.section`
  background-color: #fff;
  text-align: left;
  color: gray;
`;

const Body = styled.div`
  /* padding: 50px; */
`;

const Img = styled.img`
  width: 100%;
`;

const Content = styled.div`
  padding: 5px 0px 15px 0px;
  text-align: left;
`;


const Title = styled.div`
  font-size: 24pt;
  padding: 5px 0px 15px 0px;
`;

class Lettering extends React.Component {
  state = {
    pageData: {},
    bannerItems: [],
  }

  async componentDidMount() {
    const result = await this.getPageData();

    if (result.banner) {
      result.banner.forEach(banner => {
        banner.image = `${config.imagePrefix}/lettering/${banner.image}`;
      });
    }

    this.setState({ pageData: result, bannerItems: result.banner });
  }

  async getPageData() {
    const fetchUrl = `${config.serverEndPoint}/letteringPage`;

		try {
			const result = await axios.get(fetchUrl).then(result => result.data);

      return result;
		} catch (err) {
			console.log(err);
		}
  }

  render() {
    const { pageData, bannerItems } = this.state;

      if (pageData) {
        return (
          <Wrapper>
            <Carousel items={bannerItems} />

            <Img src={`${config.imagePrefix}/lettering/${pageData.image1}`}  alt=""/>
            <div className="row">
              <div className="col-xs-1 col-lg-1 col-md-1"></div>
              <div className="col-xs-10 col-lg-10 col-md-10">
                <Body>
                  <Title>刻字服务</Title>
                  <hr />
                  <Content>
                    {pageData.description1}
                  </Content>
                  
                </Body>
                <ProductFooter/>
              </div>
              <div className="col-xs-1 col-lg-1 col-md-1"></div>
            </div>
        
          </Wrapper>
        )
      }else{
        return(<div></div>)
      }
    
  }
}

export default Lettering;
