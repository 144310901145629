import React, { Component } from 'react';
import { BrowserRouter } from "react-router-dom";
import { LastLocationProvider } from 'react-router-last-location';

import { Header, Footer, MobileNavBar, NavBar, NetworkOverlay } from '../Common';
import { Router, routes, privateRoutes } from '../../router';
import { ScrollToTop } from './components';
import { sizes, MobileOnly, TabletAndDesktop } from '../../styles/breakpoints';
import { PopupContext } from '../../context';
import Chat from '../Chat';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <LastLocationProvider>
          <PopupContext>
            <ScrollToTop>
              <div className="page-wrapper">
                <Header />

                <MobileOnly>
                  <MobileNavBar />
                </MobileOnly>
                <TabletAndDesktop>
                  <NavBar />
                </TabletAndDesktop>

                <Router routes={routes} privateRoutes={privateRoutes} />

                <Footer />
              </div>
            </ScrollToTop>
            <NetworkOverlay />
          </PopupContext>
          <Chat />
        </LastLocationProvider>
      </BrowserRouter>
    );
  }
}

export default App;