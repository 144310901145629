import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import lodashGet from 'lodash/get';

import config from '../../../config';
import { InputWithButton } from '../';
import { media } from '../../../styles/breakpoints';
import { withAuth } from '../../../context';
import { PHONE_REGEX } from '../../../constants';

const Wrapper = styled.div`
  margin: auto;
  width: 70%;
  padding: 150px 100px;

  ${media.tablet`
    padding: 100px 30px;
  `};

  ${media.phone`
    width: 100%;
    padding: 30px 10px;
  `};
`;

const Header = styled.h2`
  text-align: center;
  font-weight: 600;
  font-size: 26px;
  margin-bottom: 30px;
`;

const InputHeaderRow = styled.div`
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
`;

const InputHeaderWithError = styled.div`
  margin-bottom: 5px;
  color: #808080;
`;

const Error = styled.div`
  color: red;
`;

const InputHeader = styled.div`
  width: 100%;
  margin-bottom: 5px;
  text-align: left;
  color: #808080;
`;

const Input = styled.input`
  margin-bottom: 15px;
  border-radius: 0px !important;
`;

const Button = styled.button`
  width: 100%;
  margin-top: 50px;
  font-weight: 600;
  border: solid 1px #dadada;
  padding: 10px;
  background-color: #ebecf0;
  cursor: pointer;
`;

const CaptchaValidateForm = ({ authHeader, formHeader, children, enablePhoneInput, auth, validatePhoneRoutePrefix }) => {
  const [isValidated, setIsValidated] = React.useState(null);
  const [validateError, setValidateError] = React.useState('');
  const [phone, setPhone] = React.useState(enablePhoneInput ? '' : auth.getPhoneNumber());
  const [pin, setPin] = React.useState('');

  const routePrefix = validatePhoneRoutePrefix ? `${validatePhoneRoutePrefix}/` : '';

  const onPhoneChange = React.useCallback((event) => {
    setPhone(event.target.value);
    setIsValidated(null);
  }, [setPhone, setIsValidated]);

  const onPinChange = React.useCallback((event) => {
    setPin(event.target.value);
    setIsValidated(null);
    }, [setPin, setIsValidated]);

  const onGetPin = React.useCallback(async () => {
    setIsValidated(null);

    const generatePinUrl = `${config.serverEndPoint}/${routePrefix}validate-phone`;
    
    try {
      const getPinResult = await axios
        .put(generatePinUrl, { phone })
        .then(result => result.data);

      if (getPinResult.error) {
        setIsValidated(false);
        setValidateError(getPinResult.message);
      }
    } catch (err) {
      console.log(err)
      const responseMsg = lodashGet(err, 'response.data.message', '获取失败, 请稍后再试');

      setIsValidated(false);
      setValidateError(responseMsg);
    }
  }, [setIsValidated, setValidateError, enablePhoneInput, phone]);

  const onNextStepClick = React.useCallback(async () => {
    if (phone === '' || pin === '') {
      setIsValidated(false);
      setValidateError('请输入所需资料');
    } else if(!PHONE_REGEX.test(phone)) {
      setIsValidated(false);
      setValidateError('电话号码不正确');
    } else {
      try {
        const validatePinUrl = `${config.serverEndPoint}/${routePrefix}validate-phone`;
        const { isValid } = await axios
          .post(validatePinUrl, { phone, pin })
          .then(result => result.data);

        setIsValidated(isValid);
        setValidateError('验证失败');
      } catch (err) {
        console.log(err)
        const responseMsg = lodashGet(err, 'response.data.message', '验证失败');
  
        setIsValidated(false);
        setValidateError(responseMsg);
      }
    }
  }, [setIsValidated, setValidateError, phone, pin]);

  return (
    <Wrapper>
      <Header>{isValidated ? formHeader : authHeader}</Header>
      { isValidated ? children({ phone, pin }) : (
        <React.Fragment>
          <InputHeaderRow>
            <InputHeaderWithError>
              { enablePhoneInput ? '输入你的手机号码' : '手机号码' }
            </InputHeaderWithError>
            { isValidated === false && <Error>{validateError}</Error> }
          </InputHeaderRow>
          <InputWithButton
              name="phone"
              value={phone}
              onBtnClicked={onGetPin}
              onChange={onPhoneChange}
              btnText="获取验证码"
              disabled={!enablePhoneInput} />
          <InputHeader>输入验证码</InputHeader>
          <Input className="form-control" name="pin" type="password" value={pin} onChange={onPinChange} />

          <Button onClick={onNextStepClick}>下一步</Button>
        </React.Fragment>
      )}
    </Wrapper>
  );
};

export default withAuth(CaptchaValidateForm);