import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';

import config from '../../../config';
import RemoveSocialPageComponent from './RemoveSocialPageComponent';
import { SimpleModal } from '../../Common';

class RemoveSocialPageContainer extends React.Component {
  state = {
    openSuccessModal: false,
  }

  removeSocialBinding = async () => {
    const { match } = this.props;

    try {
      const deleteUrl = `${config.serverEndPoint}/third-party-login/${match.params.type}`;

      await axios.delete(deleteUrl);

      this.setState({ openSuccessModal: true });
    } catch (e) {
      console.log(e);
    }
  }

  closeSuccessModal = () => {
    const { history } = this.props;

    this.setState({ openSuccessModal: false });

    history.push('/user');
  }

  render() {
    const { openSuccessModal } = this.state;
    const { match, history } = this.props;

    return (
      <React.Fragment>
        <RemoveSocialPageComponent
          removeSocialBinding={this.removeSocialBinding}
          type={match.params.type}
          history={history} />

        <SimpleModal
          confirmMsg="解除綁定成功"
          visible={openSuccessModal}
          confirmCallback={this.closeSuccessModal} />
      </React.Fragment>
    )
  }
};

export default withRouter(RemoveSocialPageContainer);
