import React from 'react';
import styled from 'styled-components';
import { withRouter, Link } from 'react-router-dom';

import { PageContainer } from '../Common';
import { media } from '../../styles/breakpoints';
import { ProductItem } from './components';
import { COURIER_ID_MAP } from '../../constants';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${media.phone`
    padding: 30px 10px;
  `};
`;

const LogoWrapper = styled.div`
  margin-bottom: 50px;
`;

const SuccessMsgWrapper = styled.div`
  background-color: #f2f2f2;
  padding: 40px;
  text-align: center;
  margin-bottom: 20px;

  ${media.phone`
    background-color: #fff;
    font-size: 14px;
  `};
`;

const SuccessMsgHeader = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const SuccessMsgContent = styled.div`
  margin-bottom: 5px;
  color: #7f7f7f;
`;

const SuccessMsgOrderId = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 50px;

  span {
    color: #7f7f7f;
    margin-left: 10px;
    font-weight: normal;
  }
`;

const SectionContainer = styled.div`
  margin-bottom: 30px;

  ${media.phone`
    margin-bottom: 20px;
  `};
`;

const OrderWrapper = styled.div`
  ${media.phone`
    background-color: #fff;
  `};
`;

const OrderNum = styled.div`
  padding-bottom: 8px;
  border-bottom: solid 3px #000;

  span {
    margin-left: 20px;
  }

  ${media.phone`
    padding: 20px;
    border-bottom: solid 1px #e2e2e2;
  `};
`;

const ProductHeader = styled.ul`
  display: flex;
  align-items: center;
  padding: 20px 0;
  border: solid 1px #e2e2e2;

  ${media.phone`
    display: none;
  `};
`;

const ProductHeaderItem = styled.li`
  flex: 1;
  text-align: center;

  padding: ${props => props.padding ? props.padding : 'none'};
`;

const OrderDetailWrapper = styled.div`
  padding: 20px 35px;
  border: solid 1px #e2e2e2;
  border-top: none;
  
  ${media.phone`
    border: none;
    padding: 0px 20px;
  `};
`;

const OrderDetailItem = styled.div`
  margin: 10px 0;
  display: flex;
  font-size: 14px;
  
  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const OrderDetailTitle = styled.span`
  flex: 0 0 150px;

  ${media.phone`
    flex: 0 0 120px;
  `};
`;

const ButtonRowWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

const Button = styled.button`
  margin: 0 15px;
  padding: 5px 60px;
  border: solid 1px #e2e2e2;
  background-color: #000;
  color: #fff;
  
  ${media.phone`
    margin: 0 10px;
    padding: 5px 0;
    width: 170px;
  `};
`;

const OrderDetailComponent = ({ orderDetail, history }) => {
  const {
    id, products, firstName, phone,
    address, courierId, courierNo,
    paymentType, transactionId
  } = orderDetail;

  const onBackBtnClick = React.useCallback(() => {
    history.push('/');
  });

  const onOrderDetailBtnClick = React.useCallback(() => {
    history.push(`/orderDetail?id=${id}`);
  });

  return (
    <PageContainer hasMobileBgColor>
      <Wrapper>
        <LogoWrapper>
          <img src="/images/product/desktop_banner_logo.png" alt="" />
        </LogoWrapper>

        <SectionContainer>
          <SuccessMsgWrapper>
            <SuccessMsgHeader>付款成功</SuccessMsgHeader>
            <SuccessMsgOrderId>
              订单编号:
              <span>{id}</span>
            </SuccessMsgOrderId>
            <SuccessMsgContent>感谢你的购买。</SuccessMsgContent>
            <SuccessMsgContent>您可以到 <Link to={`/orderDetail?id=${id}`}>我的订单</Link> 页面检视这次交易的状况。</SuccessMsgContent>
            <SuccessMsgContent>物流状态更新了，您将会收到短信通知。</SuccessMsgContent>
            <SuccessMsgContent>如果您有任何问题，请随时询问我们的客服。</SuccessMsgContent>
          </SuccessMsgWrapper>
        </SectionContainer>

        <OrderWrapper>
          <OrderNum>
            订单号
            <span>{id}</span>
          </OrderNum>
          <ProductHeader>
            <ProductHeaderItem padding="0 30px">商品名称</ProductHeaderItem>
            <ProductHeaderItem padding="0 30px">商品</ProductHeaderItem>
            <ProductHeaderItem>数量</ProductHeaderItem>
            <ProductHeaderItem>订单金额</ProductHeaderItem>
          </ProductHeader>

          { orderDetail && products && products.map((product, idx) => (
            <ProductItem key={idx} product={product} />
          ))}

          <SectionContainer>
            <OrderDetailWrapper>
              <OrderDetailItem>
                <OrderDetailTitle>付款选项</OrderDetailTitle>
                <span>{ paymentType === 'WXPAY' || paymentType === 'WXPAYH5' ? '微信' : '支付宝' }</span>
              </OrderDetailItem>
              <OrderDetailItem>
                <OrderDetailTitle>交易编号</OrderDetailTitle>
                <span>{transactionId}</span>
              </OrderDetailItem>
              <OrderDetailItem>
                <OrderDetailTitle>收件人姓名</OrderDetailTitle>
                <span>{firstName}</span>
              </OrderDetailItem>
              <OrderDetailItem>
                <OrderDetailTitle>收件人联系电话</OrderDetailTitle>
                <span>{phone}</span>
              </OrderDetailItem>
              <OrderDetailItem>
                <OrderDetailTitle>收货人地址</OrderDetailTitle>
                <span>{address}</span>
              </OrderDetailItem>
              <OrderDetailItem>
                <OrderDetailTitle>物流</OrderDetailTitle>
                <span>{courierId && courierId !== '0' ? COURIER_ID_MAP[courierId] : '未知'}</span>
              </OrderDetailItem>
              <OrderDetailItem>
                <OrderDetailTitle>运单号</OrderDetailTitle>
                <span>{courierNo ? courierNo : '未知'}</span>
              </OrderDetailItem>
            </OrderDetailWrapper>
          </SectionContainer>
        </OrderWrapper>

        <ButtonRowWrapper>
          <Button onClick={onBackBtnClick}>继续浏览</Button>
          <Button onClick={onOrderDetailBtnClick}>您的订单</Button>
        </ButtonRowWrapper>
      </Wrapper>
    </PageContainer>
  )
};

export default withRouter(React.memo(OrderDetailComponent));