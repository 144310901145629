import React from 'react';
import styled from 'styled-components';
import Modal from 'react-bootstrap4-modal';
import { withRouter } from 'react-router-dom';

import { TabletAndDesktop } from '../../../../styles/breakpoints';

const qqUrl = `https://graph.qq.com/oauth2.0/show?which=Login&display=pc&client_id=101494932&response_type=code&state=27cdf080d450c4c0b3fb83b2c3999459&scope=get_user_info`;
const wechatUrl = `https://open.weixin.qq.com/connect/qrconnect?appid=wxbd13eaf888a070fb&scope=snsapi_login&state=lamy&self_redirect=default&style=white&href=https://csdnimg.cn/release/passport/history/css/replace-wx-style.css`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 100px;

  .modal-content {
    box-shadow: 0 5px 15px rgba(0,0,0,.5);
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-around;

  div {
    cursor: pointer;
  }
`;

const AppImage = styled.img`
  display: block;
  width: 80px;
  height: 80px;
  margin: 100px 60px 10px;
`;

const ModalHeader = styled.h5`
  font-size: 14px;
`;

const ModalBodyHeader = styled.h4`
  font-size: 18px;
`;



const QRCodeLogin = ({ location }) => {
  const [openWeChat, setOpenWeChat] = React.useState(false);

  const onWeChatLogin = React.useCallback(() => {
    setOpenWeChat(true);  
  }, [setOpenWeChat]);

  const onCloseWeChatModal = React.useCallback(() => {
    setOpenWeChat(false);  
  }, [setOpenWeChat])

  const redirectUrl = `${window.location.origin}/third-party-login`;

  return (
    <Wrapper>
      第三方登录入口

      <Row>
        <TabletAndDesktop>
          <div onClick={onWeChatLogin}>
            <AppImage src="images/weixin.png" />
            WeChat
          </div>
        </TabletAndDesktop>
        {/* <div>
          <a href={`${qqUrl}&redirect_uri=${redirectUrl}/qq`}>
            <AppImage src="images/QQ.png" />
            QQ
          </a>
        </div> */}
      </Row>

      <Modal visible={openWeChat} onClickBackdrop={onCloseWeChatModal}>
        <div className="modal-header">
          <ModalHeader className="modal-title">微信扫码登录</ModalHeader>
        </div>
        <div className="modal-body">
          <ModalBodyHeader>未曾绑定微信帐号的用户请先登入旧帐号再进行绑定!</ModalBodyHeader>
          <div className="code-content code-login">
            <div className="code-pic">
              <iframe
                title="WeChatLogin"
                src={`${wechatUrl}&redirect_uri=${redirectUrl}/wechat`}
                frameBorder="0"
                scrolling="no"
                width="100%"
                height="300px" />
            </div>
          </div>
        </div>
      </Modal>
    </Wrapper>
  )
};

export default withRouter(QRCodeLogin);