import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import config from '../../../../config';
import CartItem from '../CartItems';
import { media } from '../../../../styles/breakpoints';
import { formatPrice } from '../../../../utils';
import { withAuth } from '../../../../context';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;

const MobileSelectedItems = styled.div`
  display: none;
  margin-bottom: 10px;
  justify-content: flex-end;

  ${media.phone`
    display: flex;
  `};
`;

const CartItemWrapper = styled.div`

`;

const CartItemHead = styled.div`
  display: flex;
  margin-bottom: 10px;
  padding: 14px 35px 14px 80px;

  background-color: #efefef;

  ${media.tablet`
    padding: 10px 10px 10px 60px;
  `};

  ${media.phone`
    display: none;
  `};
`;

const ColumnHeader = styled.div`
  margin: 0 10px;
  flex: 1;
`;

const AmountRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  ${media.phone`
    margin-top: 40px;
    margin-right: auto;
    margin-left: auto;
  `}
`;

const SelectedItems = styled.div`
  ${media.phone`
    display: none;
  `};
`

const ConfirmBtn = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    background-color: #000;
    padding: 7px 35px;
    color: #fff;
    font-size: 14px;
  }

  ${media.phone`
    justify-content: center;
  `}
`;

const Header = styled.div`
  font-weight: 600;
  font-size: 26px;
  margin-bottom: 30px;
  text-align: left;
`;

const AccessoryWrapper = styled.div`
  margin-top: 30px;
`;

const AccessoryList = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;

const AccessoryItem = styled.li`
  margin-bottom: 50px;
  width: 33.333333333%;

  ${media.phone`
    margin-bottom: 30px;
    width: 50%;
  `}
`;

const AccessoryName = styled.div`
  margin-top: 30px;
  padding: 0px 5px;
`;

const AccessoryImg = styled.img`
  width: 100%;
`;

const validateSelectedProducts = (shoppingCartData, selectedProducts) =>  {
  if (selectedProducts.length === 0 ) {
    return false;
  }

  const isProductValidate = shoppingCartData
    .filter((_, idx) => selectedProducts.includes(idx))
    .reduce((acc, product) => {
      return acc && /^[0-9]{1,2}$/.test(product.quantity) && product.quantity !== "0";
    }, true);

  if (!isProductValidate) {
    return false;
  }

  return true;
}

const getOutOfStockProducts = (shoppingCartData, selectedProducts) =>  {
  const selectedProductData = shoppingCartData.filter((_, idx) => selectedProducts.includes(idx));

  const outOfStockProducts = selectedProductData.reduce((acc, product) => {
    if (product.quantity > product.stockUnit) {
      acc.push(product);
    }

    return acc;
  }, [])

  return outOfStockProducts;
}

const getExistPurchaseLimitProducts = (shoppingCartData, selectedProducts) =>  {
  const selectedProductData = shoppingCartData.filter((_, idx) => selectedProducts.includes(idx));

  const existPurchaseLimit = selectedProductData.reduce((acc, product) => {
    if (product.purchaseLimit !== 0 && product.quantity > product.purchaseLimit) {
      acc.push(product);
    }

    return acc;
  }, [])

  return existPurchaseLimit;
}

const SelectProductPage = ({
  shoppingCartData, selectedProducts, onProductSelect, onNextStep, accessories,
  onQuantityChange, onRemoveProduct, togglePopup, auth
}) => {
  const onSelect = React.useCallback((id, selected) => {
    onProductSelect(id, selected);
  }, [onProductSelect]);

  const onConfirmClicked = React.useCallback(() => {
    if (!validateSelectedProducts(shoppingCartData, selectedProducts)) {
      togglePopup({
        type: 'info',
        message: '请先选择产品'
      });

      return;
    }
    
    const outOfStockProducts = getOutOfStockProducts(shoppingCartData, selectedProducts);
    if (outOfStockProducts.length > 0) {
      togglePopup({
        type: 'info',
        message: '产品库存不足: ' + outOfStockProducts.map(product => `${product.productName} (库存: ${product.stockUnit})`).join(', ')
      });

      return;
    }

    const existPurchaseLimitProducts = getExistPurchaseLimitProducts(shoppingCartData, selectedProducts);
    if (existPurchaseLimitProducts.length > 0) {
      togglePopup({
        type: 'info',
        message: '产品超出每人购买上限: ' + existPurchaseLimitProducts.map(product => `${product.productName} (每人购买上限: ${product.purchaseLimit})`).join(', ')
      });

      return;
    }

    onNextStep();
  }, [shoppingCartData, selectedProducts, onNextStep]);

  const selectedPrice = shoppingCartData && shoppingCartData.length > 0 ? shoppingCartData.reduce((acc, item, idx) => {
    const productPrice = auth.isVip ? item.vipPrice : item.price;
    return selectedProducts.includes(idx) ? acc + productPrice * item.quantity : acc;
  }, 0) : 0;

  return (
    <Wrapper>
      <MobileSelectedItems>  
        {`共${shoppingCartData && shoppingCartData.length ? shoppingCartData.length : '0'}件商品, 已选择${selectedProducts.length}件`}
      </MobileSelectedItems>

      <CartItemWrapper>
        <CartItemHead>
          <ColumnHeader>商品名称</ColumnHeader>
          <ColumnHeader>价格</ColumnHeader>
          <ColumnHeader>数量</ColumnHeader>
          <ColumnHeader>小计</ColumnHeader>
        </CartItemHead>

        { shoppingCartData && shoppingCartData.length > 0 && shoppingCartData.map((item, idx) => (
          <CartItem
            key={item.id}
            item={item}
            itemIdx={idx}
            onSelect={onSelect}
            onQuantityChange={onQuantityChange}
            selected={selectedProducts.includes(idx)}
            onRemoveProduct={onRemoveProduct} />
        ))}
      </CartItemWrapper>

      <AmountRow>
        <SelectedItems>{`共${shoppingCartData.length}件商品, 已选择${selectedProducts.length}件`}</SelectedItems>
        <div>{`${selectedProducts.length}商品, 总金额  ￥${formatPrice(selectedPrice)}`}</div>
      </AmountRow>

      <ConfirmBtn>
        <button onClick={onConfirmClicked}>确认并下单</button>
      </ConfirmBtn>

      <AccessoryWrapper>
        <Header>相关配件</Header>
        <AccessoryList>
          { accessories && accessories.map((accessory) => (
            <AccessoryItem key={accessory.id}>
              <Link to={`/product?id=${accessory.id}&colorId=${accessory.colorId}`}>
                <AccessoryImg src={`${config.imagePrefix}/product/${accessory.image}`} alt="accessory" />

                <AccessoryName>{accessory.name}</AccessoryName>
              </Link>
            </AccessoryItem>
          ))}
        </AccessoryList>
      </AccessoryWrapper>
    </Wrapper>
  )
}

export default React.memo(
  withAuth(SelectProductPage)
);