import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import lodashGet from 'lodash/get';

import config from '../../../config';
import AddAddressPageComponent from './AddAddressPageComponent';
import { SimpleModal } from '../../Common';
import { withPopup } from '../../../context';

class AddAddressPageContainer extends React.Component {
  state = {
    openSuccessModal: false,
  }

  createAddress = async (addressData) => {
    const { togglePopup } = this.props;

    try {
      const createUrl = `${config.serverEndPoint}/userAddress`;

      await axios.post(createUrl, addressData);

      this.setState({ openSuccessModal: true })
    } catch (err) {
      console.log(err);
      const responseMsg = lodashGet(err, 'response.data.message', '更新失败');
      togglePopup({
        type: 'info',
        message: responseMsg,
      })
    }
  }

  closeSuccessModal = () => {
    const { history } = this.props;

    this.setState({ openSuccessModal: false });

    history.push('/user');
  }

  render() {
    const { openSuccessModal } = this.state;

    return (
      <React.Fragment>
        <AddAddressPageComponent createAddress={this.createAddress} />

        <SimpleModal
          confirmMsg="添加常用地址成功"
          visible={openSuccessModal}
          confirmCallback={this.closeSuccessModal} />
      </React.Fragment>
    )
  }
};

export default withRouter(
  withPopup(AddAddressPageContainer)
);
