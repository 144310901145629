import { takeEvery, select, put } from "utils/pseudoSaga";
import countedThrottle from "utils/countedThrottle";
import socketInstance from "./socketInstance";

export const initState = {
  conversationHistory: [],
  self: { name: '用户' },
  conversations: {},
  serverOnline: false,
  widgetShow: false,
  hasUnreadMessage: false,
};

// actions
const RESET = 'RESET';
const FETCH_SELF_SUCCESS = 'FETCH_SELF_SUCCESS';
const FETCH_CONVERSATION_DETAIL_SUCCESS = 'FETCH_CONVERSATION_DETAIL_SUCCESS';
// const FETCH_ADMINS_SUCCESS = 'FETCH_ADMINS_SUCCESS';

const UPDATE_IDENTITY = 'UPDATE_IDENTITY';
const SEND_MESSAGE = 'SEND_MESSAGE';
const LEAVE_CHAT = 'LEAVE_CHAT';
const RECEIVE_MESSAGE = 'RECEIVE_MESSAGE';
const UPDATE_SERVER_STATUS = 'UPDATE_SERVER_STATUS';
const OPEN_WIDGET = 'OPEN_WIDGET';
const CLOSE_WIDGET = 'CLOSE_WIDGET';
const SET_UNREAD_FLAG = 'SET_UNREAD_FLAG';

export const reset = () => ({ type: RESET });
export const fetchSelfSuccess = (user) => ({
  type: FETCH_SELF_SUCCESS,
  user,
});
export const fetchConversationDetailSuccess = conversation => ({
  type: FETCH_CONVERSATION_DETAIL_SUCCESS,
  conversation,
});
export const updateIdentity = ({ id, name }) => ({
  type: UPDATE_IDENTITY,
  id,
  name,
});
export const sendMessage = ({ type, content }) => ({
  type: SEND_MESSAGE,
  message: { type, content },
});
export const leaveChat = () => ({ type: LEAVE_CHAT })
export const receiveMessage = (id, message) => ({
  type: RECEIVE_MESSAGE,
  id,
  message,
});
export const updateServerStatus = (status) => ({
  type: UPDATE_SERVER_STATUS,
  status,
});
export const openWidget = () => ({ type: OPEN_WIDGET });
export const closeWidget = () => ({ type: CLOSE_WIDGET });
export const setUnreadFlag = (flag) => ({ type: SET_UNREAD_FLAG, flag })

// saga
async function handleUpdateIdentity({ id, name }) {
  const meta = id ? { id } : {}
  await socketInstance.instance().emit('update:identity', { ...meta, name })
}
async function handleSendMessage({ message }) {
  await socketInstance.instance().emit('send:message', message);
}
async function handleLeaveChat() {
  await socketInstance.instance().emit('leave:chat')
}
async function handleReceiveMessage() {
  const widgetOpen = await select(state => state.widgetShow);
  if (!widgetOpen) {
    await put(setUnreadFlag(true));
    const ding = new Audio('assets/ding-sound-effect_2.wav');
    ding.play();
  }
}
export function saga() {
  takeEvery(UPDATE_IDENTITY, handleUpdateIdentity);
  takeEvery(SEND_MESSAGE, countedThrottle(handleSendMessage, 2000, { maxCount: 3, onThrottled: () => window.alert('侦测到频密发言，请稍候尝试。') }));
  takeEvery(LEAVE_CHAT, handleLeaveChat);
  takeEvery(RECEIVE_MESSAGE, handleReceiveMessage)
}

// reducer
export default function reducer(state = initState, action) {
  switch(action.type) {
    case RESET:
      return initState;
    case FETCH_SELF_SUCCESS:
      return {
        ...state,
        self: action.user
      };
    case FETCH_CONVERSATION_DETAIL_SUCCESS: {
      return {
        ...state,
        // Assume received conversation is always the last conversation (current conversation)
        conversationHistory: [
          ...state.conversationHistory.filter(x => x !== action.conversation.id),
          action.conversation.id
        ],
        conversations: {
          ...state.conversations,
          [action.conversation.id]: action.conversation,
        }
      }
    }
    case UPDATE_IDENTITY:
      return {
        ...state,
        self: {
          ...state.user,
          name: action.name,
        }
      }
    case RECEIVE_MESSAGE:
      return {
        ...state,
        conversations: {
          ...state.conversations,
          [action.id]: {
            ...state.conversations[action.id],
            history: [...state.conversations[action.id].history, action.message],
          },
        }
      }
    case UPDATE_SERVER_STATUS:
      return {
        ...state,
        serverOnline: action.status === 'AVAILABLE',
      }
    case OPEN_WIDGET:
      return {
        ...state,
        hasUnreadMessage: false,
        widgetShow: true,
      };
    case CLOSE_WIDGET:
      return {
        ...state,
        widgetShow: false,
      };
    case SET_UNREAD_FLAG:
      return {
        ...state,
        hasUnreadMessage: action.flag,
      };
    default:
      return state;
  }
}