const hostname = window.location.hostname;

export default {
  serverEndPoint: `http://${hostname}:3000`,
  // serverEndPoint: 'http://103.243.164.253:3000',
  
  chatServerEndpoint: `http://${hostname}:8080`,
  // chatServerEndpoint: 'http://localhost:8080',
  
  imagePrefix: `http://${hostname}:3000/files`,
  // imagePrefix: 'http://103.243.164.253:3000/files',
}