import React from 'react';
import styled from 'styled-components';

import CartItem from '../CartItems'
import { media, MobileOnly, TabletAndDesktop } from '../../../../styles/breakpoints';
import { formatPrice } from '../../../../utils';
import { withAuth } from '../../../../context';

const CartItemWrapper = styled.div`
  margin-bottom: 40px;
`;

const CartItemHead = styled.div`
  display: flex;
  margin-bottom: 10px;
  padding: 14px 35px 14px 80px;

  background-color: #efefef;

  ${media.phone`
    display: none;
  `};
`;

const ColumnHeader = styled.div`
  margin: 0 10px;
  flex: 1;
`;

const AmountRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  ${media.phone`
    justify-content: center;
    margin-bottom: 0;
  `};
`;

const PaymentRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 15px;

  ${media.phone`
    flex-direction: column;

    justify-content: center;
  `};
`;

const PaymentBtn = styled.button`
  width: 150px;
  height: 34px;
  margin-left: 20px;
  background-color: unset;

  img {
    height: 100%;
    padding: 7px;
  }

  border: ${props => props.selected ? 'solid 1px #446de9' : 'solid 1px #ccc'};

  ${media.phone`
    width: 100%;
    margin: 0;
  `};
`;

const NavTabs = styled.ul`
  display: flex;
  border-bottom: solid 1px #ccc;
`;

const NavTab = styled.li`
  flex: 1;
  padding: 15px 10px;

  cursor: pointer;
  font-weight: 600;

  background-color: ${props => props.selected ? '#f2f2f2' : '#d3d3d3'};
`;

const NavContainer = styled.div`
  background-color: #f2f2f2;
  padding: 30px 50px;
  text-align: left;

  ${media.phone`
    padding: 20px 10px;
  `};
`;

const SectionContainer = styled.div`
  margin-bottom: 30px;

  ${media.phone`
    margin-bottom: 20px;;
  `};
`;

const AddressWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  min-height: 272px;
`;

const AddressGrid = styled.li`
  position: relative;
  flex: 0 0 calc(33.33333% - 4px);
  max-width: calc(33.33333% - 4px);
  height: 150px;
  padding: 15px;
  margin-bottom: 6px;

  cursor: pointer;
  background: #fff;
  border: ${props => `solid 1px ${props.selected ? '#446de9' : 'transparent'}`};

  &:nth-child(3n + 2) {
    margin-left: 6px;
    margin-right: 6px;
  }

  ${media.phone`
    flex: 0 0 calc(50% - 6px);
    max-width: calc(50% - 6px);
    margin-left: 0px !important;
    margin-right: 0px !important;

    &:nth-child(2n) {
      margin-left: 10px !important;
    }
  `};
`;

const AddressReceiver = styled.div`
  height: 25px;
  margin-bottom: 15px;
  font-size: 18px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Address = styled.div`
  height: 40px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
`;

const Phone = styled.div`
  margin-bottom: 10px;
  font-size: 14px;
`;

const DefaultAddress = styled.div`
  position: absolute;
  bottom: 2px;
  right: 2px;
  font-size: 12px;
  padding: 2px 10px;
  color: #fff;
  background-color: ${props => props.selected ? '#446de9' : '#777'};
`;

const RemarkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;
  padding: 30px 50px;
  text-align: left;

  ${media.phone`
    padding: 20px 10px;
  `};
`;

const RemarkInput = styled.textarea`
  height: 80px;
  padding: 10px;
  margin-top: 15px;
  border-radius: 0px !important;
  color: #676767;
`;

const BackButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  font-size: 15px;

  div {
    cursor: pointer;
  }
`;

const ConfirmBtn = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    background-color: #000;
    padding: 7px 60px;
    color: #fff;
    font-size: 14px;
  }

  ${media.phone`
    justify-content: center;
  `};
`;

const InputHeader = styled.div`
  width: 100%;
  margin-bottom: 5px;
  text-align: left;
  color: #808080;
`;

const Input = styled.input`
  margin-bottom: 15px;
  border-radius: 0px !important;
`;

const InputArea = styled.textarea`
  margin-bottom: 15px;
  border-radius: 0px !important;
`;

const AddressInputConatiner = styled.div`
  margin: 20px auto;
  width: 50%;

  ${media.phone`
    width: 100%;
  `};
`;

const AddressInputBtn = styled.div`
  margin-top: 30px;

  button {
    width: 100%;
    background-color: #000;
    padding: 7px 60px;
    color: #fff;
    font-size: 14px;
  }
`;

const ConfirmedCustomAddressContainer = styled(SectionContainer)`
  display: flex;
  text-align: left;

  flex-direction: column;
`;

const OrderDetailWrapper = styled.div`
  padding: 20px 35px;
  border: solid 1px #ccc;
  
  ${media.phone`
    border: none;
    padding: 0 0 0 20px;
  `};
`;

const OrderDetailItem = styled.div`
  margin: 10px 0;
  display: flex;
  font-size: 14px;
`;

const OrderDetailTitle = styled.span`
  flex: 0 0 150px;

  ${media.phone`
    flex: 0 0 120px;
  `};
`;

const Remark = styled.div`
  text-align: right;

  ${media.phone`
    text-align: center;
  `};
`;

const renderSelectAddressSection = (userData, selectedAddress, setSelectedAddress) => {
  return (
    <NavContainer>
      <label>收货地址</label>
      <AddressWrapper>
        { userData.addresses && userData.addresses.map(({ name, address, phone, id }) => {
          const selected = id === selectedAddress;
            return (
              <AddressGrid key={id} selected={selected} onClick={() => {setSelectedAddress(id)}}>
                <AddressReceiver>{name}</AddressReceiver>
                <Phone>{phone}</Phone>
                <Address>{address}</Address>

                { userData.defaultAddressId === id && <DefaultAddress selected={selected}>
                    默认地址
                  </DefaultAddress>
                }
              </AddressGrid>
          )})
        }
      </AddressWrapper>
    </NavContainer>
  )
};

const renderAddAddressSection = (
  customAddress, onNameChange, onPhoneChange, onAddressChange,
  onConfirmCustomAddress, onConfirmAndAddCustomAddress
) => {
  return (
    <NavContainer>
      <AddressInputConatiner>
        <InputHeader>收货人 (收件人必须为实名, 否则不会发货)</InputHeader>
        <Input className="form-control" name="name" value={customAddress.name} onChange={onNameChange} />
        <InputHeader>收货人联系号码</InputHeader>
        <Input className="form-control" name="phone" value={customAddress.phone} onChange={onPhoneChange} />
        <InputHeader>收货地址</InputHeader>
        <InputArea className="form-control" name="address" value={customAddress.address} onChange={onAddressChange} />

        <AddressInputBtn>
          <button onClick={onConfirmCustomAddress}>确定</button>
        </AddressInputBtn>
        <AddressInputBtn>
          <button onClick={onConfirmAndAddCustomAddress}>确定并加入到常用收货地址</button>
        </AddressInputBtn>
      </AddressInputConatiner>
    </NavContainer>
  )
};

const CheckoutPage = ({
  shoppingCartData, selectedProducts, userData,
  selectedAddress, setSelectedAddress, onNextStep, onPrevStep,
  selectedPayment, setSelectedPayment,
  customAddress, onCustomAddressChange, isCustomAddressConfirmed,
  onConfirmCustomAddress, onCancelCustomAddress, onConfirmAndAddCustomAddress,
  togglePopup,
  remark, onRemarkChange, auth, transportFee
}) => {
  const [selectedTab, setSelectedTab] = React.useState(0);

  const onNameChange = React.useCallback((e) => {
    onCustomAddressChange({
      name: e.target.value
    });
  }, [onCustomAddressChange]);

  const onPhoneChange = React.useCallback((e) => {
    onCustomAddressChange({
      phone: e.target.value
    });
  }, [onCustomAddressChange]);

  const onAddressChange = React.useCallback((e) => {
    onCustomAddressChange({
      address: e.target.value
    });
  }, [onCustomAddressChange]);

  const _onRemarkChange = React.useCallback((e) => {
    onRemarkChange(e.target.value);
  }, [onRemarkChange]);

  const onSubmit = React.useCallback(() => {
    if (selectedTab === 1 && !isCustomAddressConfirmed) {
      togglePopup({
        type: 'info',
        message: '請確認你的地址'
      });

      return;
    }

    onNextStep();
  }, [selectedTab, isCustomAddressConfirmed, togglePopup, onNextStep]);

  let selectedPrice = shoppingCartData && shoppingCartData.length > 0 ? shoppingCartData.reduce((acc, item, idx) => {
    const productPrice = auth.isVip ? item.vipPrice : item.price;
    return selectedProducts.includes(idx) ? acc + productPrice * item.quantity : acc;
  }, 0) : 0;

  const hasTransportFee = transportFee && transportFee.threshold && transportFee.value
    ? selectedPrice < transportFee.threshold
      ? true
      : false
    : false;

  selectedPrice += hasTransportFee ? transportFee.value : 0;

  return (
    <React.Fragment>
      { isCustomAddressConfirmed && (
        <ConfirmedCustomAddressContainer>
          <OrderDetailWrapper>
            <OrderDetailItem>
              <OrderDetailTitle>收件人姓名</OrderDetailTitle>
              <span>{customAddress.name}</span>
            </OrderDetailItem>
            <OrderDetailItem>
              <OrderDetailTitle>收件人联系电话</OrderDetailTitle>
              <span>{customAddress.phone}</span>
            </OrderDetailItem>
            <OrderDetailItem>
              <OrderDetailTitle>收货人地址</OrderDetailTitle>
              <span>{customAddress.address}</span>
            </OrderDetailItem>
            <ConfirmBtn>
              <button onClick={onCancelCustomAddress}>重新选择地址</button>
            </ConfirmBtn>
          </OrderDetailWrapper>
        </ConfirmedCustomAddressContainer>
      )}
      { !isCustomAddressConfirmed && (
        <SectionContainer>
          <NavTabs>
            <NavTab selected={selectedTab === 0} onClick={() => setSelectedTab(0)}>常用收货地址</NavTab>
            <NavTab selected={selectedTab === 1} onClick={() => setSelectedTab(1)}>马上输入收货地址</NavTab>
          </NavTabs>
          
          { selectedTab === 0 && renderSelectAddressSection(userData, selectedAddress, setSelectedAddress) }
          { selectedTab === 1 &&
            renderAddAddressSection(
              customAddress, onNameChange, onPhoneChange, onAddressChange,
              onConfirmCustomAddress, onConfirmAndAddCustomAddress
            )
          }
        </SectionContainer>
      )}

      <SectionContainer>
        <RemarkWrapper>
          留言
          <RemarkInput value={remark} onChange={_onRemarkChange}/>
        </RemarkWrapper>
      </SectionContainer>

      <SectionContainer>
        <BackButton>
          <div onClick={onPrevStep}>返回购物车修改 ></div>
        </BackButton>
        <CartItemWrapper>
          <CartItemHead>
            <ColumnHeader>商品名称</ColumnHeader>
            <ColumnHeader>价格</ColumnHeader>
            <ColumnHeader>数量</ColumnHeader>
            <ColumnHeader>小计</ColumnHeader>
          </CartItemHead>

          { shoppingCartData
            && shoppingCartData.length > 0
            && shoppingCartData.filter((item, idx) => selectedProducts.includes(idx))
              .map((item, idx) => (
              <CartItem key={item.id} item={item} />
            ))
          }
          {
            hasTransportFee &&
            <CartItem item={{
              productName: `运费 (買滿￥${transportFee.threshold}可免运费)`,
              price: transportFee.value,
              vipPrice: transportFee.value,
              quantity: 1
            }} />
          }
        </CartItemWrapper>
      </SectionContainer>

      <SectionContainer>
        <TabletAndDesktop>
          <AmountRow>
            <div>{`共${selectedProducts.length}件商品`}</div>
            <div>{`总金额  ￥${formatPrice(selectedPrice)}`}</div>
          </AmountRow>
          <PaymentRow>
            <span>选择付款方式</span>
            <PaymentBtn selected={selectedPayment === 0} onClick={() => setSelectedPayment(0)}>
              <img src="/images/cart/img_cart_option_wxpay.png" alt="" />
            </PaymentBtn>
            <PaymentBtn selected={selectedPayment === 1} onClick={() => setSelectedPayment(1)}>
              <img src="/images/cart/img_cart_alipay.png" alt="" />
            </PaymentBtn>
          </PaymentRow>
        </TabletAndDesktop>
        <MobileOnly>
          <PaymentRow>
            <PaymentBtn selected={selectedPayment === 0} onClick={() => setSelectedPayment(0)}>
              <img src="/images/cart/img_cart_option_wxpay.png" alt="" />
            </PaymentBtn>
            <PaymentBtn selected={selectedPayment === 1} onClick={() => setSelectedPayment(1)}>
              <img src="/images/cart/img_cart_alipay.png" alt="" />
            </PaymentBtn>
          </PaymentRow>
          <AmountRow>
            <div>{`${selectedProducts.length}商品-总金额  ￥${formatPrice(selectedPrice)}`}</div>
          </AmountRow>
        </MobileOnly>
      </SectionContainer>

      <SectionContainer>
        <Remark>
          订单生成后, 可随时在我的订单页进行付款
        </Remark>
      </SectionContainer>

      <ConfirmBtn>
        <button onClick={onSubmit}>结算</button>
      </ConfirmBtn>
    </React.Fragment>
  )
}

export default React.memo(
  withAuth(CheckoutPage)
);