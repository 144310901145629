import React, { Component } from 'react';
import axios from 'axios';

import config from '../../config';
import HomePageComponent from './HomePageComponent';

class HomePageContainer extends Component {
  state = {
    bannerItems: [],
    gridItems: []
  }

  async componentDidMount() {
    const result = await this.fetchData();
    let { bannerItems, gridItems } = result ? result : { bannerItems: [], gridItems: [] };

    if (bannerItems) {
      bannerItems.forEach(banner => {
        banner.image = `${config.imagePrefix}/newproduct/${banner.image}`;
      });
    }

    if (gridItems) {
      gridItems.forEach(grid => {
        grid.image = `${config.imagePrefix}/index/${grid.image}`;
      });

      // Insert the weibo component to the second last idx 
      gridItems = gridItems.reduce((acc, item, idx) => {
        idx === gridItems.length - 1 ? acc.push('weibo', item) : acc.push(item);
        return acc;
      }, [])
    }

    this.setState({ bannerItems, gridItems });
  }

  fetchData() {
    const fetchUrl = `${config.serverEndPoint}/index`;

    return axios.get(fetchUrl)
      .then(result => result.data)
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    const { bannerItems, gridItems } = this.state;

    return (
      <HomePageComponent bannerItems={bannerItems} gridItems={gridItems} />
    );
  }
}

export default HomePageContainer;
