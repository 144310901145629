import React from 'react';
import styled from 'styled-components';
import {
  Paper,
  Typography,
  IconButton,
  DialogContent,
  TextField,
  Button,
  Divider,
} from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import HeadsetMic from '@material-ui/icons/HeadsetMic';
import format from 'date-fns/format';

import { connect, bindActionCreator } from 'context/chat/chatContext';
import {
  sendMessage,
  leaveChat,
  closeWidget,
} from 'context/chat/chatRedux';
import { sizes, MobileOnly, TabletAndDesktop } from 'styles/breakpoints';

import LogMessage from './components/LogMessage';
import UserMessage from './components/UserMessage';
import AdminMessage from './components/AdminMessage';

// z-index greater than drawer < modal
const Widget = styled.div`
  z-index: 1250;
  position: fixed;
  width: 350px;
  right: 5vw;
  height: 550px;
  bottom: 0;
  border-radius: 4px;
  display: ${({open}) => open ? 'block' : 'none'};
  
  @media (max-width: ${sizes.phone}px) {
    & {
      width: 100%;
      top: 0;
      left:0;
      height: 100%;
      bottom: auto;
    }
  }
`

const StyledPaper = styled(Paper)`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  height: 100%;
`

const StyledHeader = styled.div`
  padding: 0.5rem 1rem;
  background-color: #ddd;
  background: linear-gradient(to bottom, #f0f0f0, #aaa);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px 8px 0 0;
`;

const StyledTitle = styled(Typography)`
  flex: 1;
`;

const QQIcon = styled.img`
  width: 40px;
`;

const SmallIconButton = styled(IconButton)`
  padding: 3px !important;
  outline: none !important;
`;

const StyledContent = styled(DialogContent)`
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ConversationContainer = styled.div`
  height: 87%;
  background-color: white;
  border-radius: 4px;
  overflow-y: scroll;
  padding: 0.5rem;
  
  @media (max-width: ${sizes.phone}px) {
    & {
      flex: 1;
    }
  }
`;

const ConversationBanner = styled.div`
  color: #DDD;
  padding: 1rem;
`;

const ActionForm = styled.form`
  flex: 1;
  display: flex;
  margin: 1rem 0.5rem;
  border: 1px solid #F0F0F0;
  border-radius: 4px;

  @media (max-width: ${sizes.phone}px) {
    & {
      margin: 0;
      padding: 0.5rem;
      border: none;
      background-color: #DDDDDD;
      background: linear-gradient(to bottom, #f0f0f0, #DDD);
      line-height: 1;
      flex: 0;
      min-height: 48px;
    }
  }
`;

const StyledTextField = styled(TextField)`
  background-color: white;
  flex: 1;
  padding: 0 0 0 0.5rem !important;
  border-radius: 4px;
`;

const ButtonContainer = styled.div`
  outline: none;
  @media (max-width: ${sizes.phone}px) {
    & {
      margin-left: 0.5rem;
    }
  }
`;

const SubmitButton = styled(Button)`
  flex: 1;
  outline: none;

  ${ButtonContainer} & {
    box-shadow: none;
    padding: 4px;
    min-width: 0;
    background-color: #DDDDDD;
    color: white;
  }

  @media (max-width: ${sizes.phone}px) {
    ${ButtonContainer} & {
      background-color: #AAAAAA;
      padding: 4px 1rem;
    }
  }
`;

const OfflineForm = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;
  height: 100%;
`;

const OfflineContent = styled.p`
  flex: 1;
  font-size: 1.2rem;
`;

const OfflineButton = styled(Button)`
  width: 100%;
`

class ChatWidget extends React.Component {
  static defaultProps = {
    user: {},
  };

  static inputProps = {
    disableUnderline: true,
  };

  state = {
    message: '',
  };
  messagesEndRef = React.createRef();

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate(prevProps) {
    const { conversations, widgetShow } = this.props;
    if (widgetShow && !prevProps.widgetShow) {
      this.scrollToBottom();
    }
    if (widgetShow && Boolean(conversations.length)) {
      const prevLength = (prevProps.conversations[prevProps.conversations.length - 1] || { history: [] }).history.length;
      if (conversations[conversations.length - 1].history.length !== prevLength) {
        this.scrollToBottom();
      }
    }
  }

  submitForm = () => {
    const { message } = this.state;
    const { sendMessage } = this.props;
    if(message) sendMessage({ content: message, type: 'TEXT'});
    this.setState({ message: '' });
  }

  scrollToBottom = () => {
    this.messagesEndRef.current && this.messagesEndRef.current.scrollIntoView()
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.submitForm();
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.submitForm();
    }
  }

  handleChange = (e) => {
    this.setState({ message: e.currentTarget.value });
  }

  handleClose = () => {
    const { closeWidget } = this.props;
    closeWidget();
  }

  handleOpenQQChat = () => {
    const { leaveChat } = this.props;
    leaveChat();
  }

  renderMessage = (message, idx) => {
    if (message.type === 'LOG') {
      const templateMap = {
        CHAT_END: '对话于{{time}}结束',
        ADMIN_JOIN: '{{user}}于{{time}}加入对话',
        USER_LEAVE: '{{user}}于{{time}}离开对话',
        ADMIN_LEAVE: '{{user}}于{{time}}离开对话',
      }
      const user = message.who.startsWith('user:') ? '您' : '客服';
      return <LogMessage
        key={idx}
        message={templateMap[message.content]
          .replace('{{time}}', format(new Date(message.createdAt * 1000), 'HH:mm'))
          .replace('{{user}}', user)
        }
      />;
    }

    const isUser = message.who.startsWith('user:');
    if (isUser) {
      const { user } = this.props;
      return <UserMessage
        key={idx}
        name={user.name === '用户' ? '您' : user.name}
        type={message.type}
        message={message.content}
        time={message.createdAt}
      />
    }
    const [_, adminId, adminName] = message.who.split(':');

    return <AdminMessage
      key={idx}
      name={adminName || "客服"}
      message={message.content}
      time={message.createdAt}
    />;
  }

  render() {
    const { conversations, widgetShow, serverOnline } = this.props;
    const { message } = this.state;

    return (
      <Widget open={widgetShow}>
        <StyledPaper>
          <StyledHeader disableTypography>
            {serverOnline && (
              <a href="http://wpa.qq.com/msgrd?v=3&uin=2101803859&site=qq&menu=yes" target="_blank" rel="noopener noreferrer">
                <QQIcon src="/images/QQ_chat.png" alt="" />
              </a>
            )}
            <StyledTitle variant="subtitle1">
              <HeadsetMic/> 在线客服
            </StyledTitle>
            <SmallIconButton aria-label="Minimize" size="small" onClick={this.handleClose}>
              <ArrowDropDown />
            </SmallIconButton>
          </StyledHeader>
          <StyledContent>
            {
              serverOnline ?
              (
                <React.Fragment>
                  <ConversationContainer>
                    <ConversationBanner>您好！欢迎咨询LAMY官方客服，如无应答请点击左上角QQ服务，谢谢！</ConversationBanner>
                    <Divider />
                    <br />
                    {(conversations.reduce((memo, x) => [...memo, ...x.history],[])).map(this.renderMessage)}
                    <div ref={this.messagesEndRef} />
                  </ConversationContainer>
                  <ActionForm onSubmit={this.handleSubmit}>
                    <StyledTextField
                      name="message"
                      value={message}
                      placeholder="点击输入你的问题"
                      onKeyDown={this.handleKeyDown}
                      onChange={this.handleChange}
                      InputProps={ChatWidget.inputProps}
                    />
                    <ButtonContainer>
                      <SubmitButton variant="contained" type="submit">
                        <TabletAndDesktop><ArrowDropUp /></TabletAndDesktop>
                        <MobileOnly>提交</MobileOnly>
                      </SubmitButton>
                    </ButtonContainer>
                  </ActionForm>
                </React.Fragment>
              ) :
              ( <OfflineForm>
                  <OfflineContent>
                    抱歉，现时没有客服在线上。如有任何查询可选择QQ联络客服,或点击网站右上的联络我们留下讯息。
                  </OfflineContent>
                  <ButtonContainer>
                    <a href="http://wpa.qq.com/msgrd?v=3&uin=2101803859&site=qq&menu=yes" target="_blank" rel="noopener noreferrer">
                      <OfflineButton variant="contained" type="button">
                        QQ联络客服
                      </OfflineButton>
                    </a>
                  </ButtonContainer>
                </OfflineForm> )
            }
            
          </StyledContent>
        </StyledPaper>
      </Widget>
    );
  }
}

const mapStateToProps = state => {
  const conversations = state.conversationHistory.map(x => state.conversations[x]);
  return {
    conversations,
    user: state.self,
    serverOnline: state.serverOnline,
    widgetShow: state.widgetShow,
  };
};

const mapDispatchToProps = dispatch => bindActionCreator({
  sendMessage,
  leaveChat,
  closeWidget,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChatWidget);
