import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';

import config from '../../config';
import OrderDetailComponent from './OrderDetailComponent';

class OrderDetailContainer extends React.Component {
  state = {
    orderDetail: {}
  }

  async componentDidMount() {
    const orderDetail = await this.getPageData() || {};

    this.setState({ orderDetail });
  }

  getPageData() {
    const { location } = this.props;
    const parsed = qs.parse(location.search) || {};

    const fetchUrl = `${config.serverEndPoint}/orderDetailPage?id=${parsed.id}`;

    return axios.get(fetchUrl)
      .then(result => result.data[0])
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    const { orderDetail } = this.state;

    return (
      <OrderDetailComponent
        orderDetail={orderDetail} />
    )
  }
};

export default withRouter(OrderDetailContainer);

