import React from 'react';
import styled from 'styled-components';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { ProductFooter } from '../Common';

const Wrapper = styled.section`
background-color: #fff;
padding : 50px;
text-align: left;
color: gray;
`;

const Title = styled.div`
  font-size: 24pt;
  padding: 5px 0px 15px 0px;
`;


const Subtitle = styled.div`
  text-align: left;
  padding: 5px 0px;
  font-weight: bold;
  color: gray;
`;




const Question = ({ getItemStyle,handleQ1Click,handleQ2Click,handleQ3Click, state }) => {

  return (<Wrapper>
    <Title>常见问题</Title>
    <List
      component="nav"
      className={'classes.root'}
    >
      <hr />
      <ListItem button  onClick={() => handleQ1Click()}>
        <ListItemText inset primary="购物须知" style={getItemStyle()}/>
        {state.Q1 ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={state.Q1} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem className={'classes.nested'}>
            <ListItemText >
              本公司销售的钢笔配有不同型号的笔尖（JOY系列和学生ABC系列除外），笔尖分为：EF、F、M，其中粗细依次：0.5mm、0.7mm、0.9mm，否则本公司默认发F笔尖； JOY系列笔尖型号为1.1、1.5、1.9，粗细依次为1.1mm、1.5mm、1.9mm，购买时请在订单备注栏写清需要的笔尖型号；ABC系列笔尖型号为A，粗细0.8mm。
              <br />
              <br />
              每一支全新的钢笔笔尖部分会有蓝白色痕迹，初次使用也会有少许蓝黑色墨水痕迹，这些都是出厂前试笔时候用的蓝色墨水留下的，绝非二手或经人使用过的笔。
              <br />
              <br />
            </ListItemText>
          </ListItem>
        </List>
      </Collapse>
      <hr />
      <ListItem button onClick={() => handleQ2Click()}>
        <ListItemText inset primary="货品验收与退换货" style={getItemStyle()}/>
        {state.Q2 ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={state.Q2} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem className={'classes.nested'}>
            <ListItemText>
              除了客户在收到商品3日内通过电话、邮件等方式提出异议，且在提出异议3日内收到本公司书面确认外，视为该商品无任何质量问题且客户已完成商品验收。
              <br />
              <br />
              退换货原因必须为商品不能使用、损坏(非经顾客人为破坏)和出具国家质监部门检测的质量问题报告等，其他理由如顾客购买错误或不懂使用或不适合使用等均不得退换货。
              <br />
              <br />
              本公司所提供配件产品如水芯、墨水等消耗性商品一经使用后将无法提供退换货。
              <br />
              <br />
              特价商品、特殊促销商品以及销售网页上特别注明的商品，将无法接受退换货，请客户申购前仔细评量。
              <br />
              <br />
              退换货商品必须是全新状态未被使用、涂污、非由顾客人为破坏等等，且完整包装（保持商品、附件、包装、保修卡、原装纸箱及所有附随文件或资料之完整)与期间内执行，否则恕不接受退订或换货，除将无法返还客户所支付价款且订单视同继续有效并返还给客户所订购商品。
              <br />
              <br />
              在退换货时，请选用最可靠的运输方法，假如货品于退换货途中遗失，本公司恕不负责。顾客退货需负责退货之邮寄费用。
              <br />
              <br />
              收到退回商品确认无误后，7个工作日内退款给原付方，并与退款当日电话或邮件等方式通知客户。
            </ListItemText>
          </ListItem>
        </List>
      </Collapse>
      <hr />
      <ListItem button onClick={() => handleQ3Click()}>
        <ListItemText inset primary="发货须知" style={getItemStyle()}/>
        {state.Q3 ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={state.Q3} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem className={'classes.nested'}>
            <ListItemText>
              <Subtitle>商品运送</Subtitle>
              本公司将于确认付款后配送所订购商品至客户指定地点，一张订单仅提供同一个地址的运送。
              <br />
              <br />
              对快递过程无法预期、不可控的因素导致送货延迟或无法交货原因来自客户错误讯息其所造成增加费用支出，将由客户自行承担。
              <br />
              <br />
              如果客户未提供完成交货必须的明确交货指示、文件，本公司得以通知商户商品将被另行保管，但相关的产品风险将转移给客户。
              <br />
              <br />
              <Subtitle>所有权归属及损坏风险的承担</Subtitle>
              商品的所有权在商品依客户订单指示送达后及归客户所有，相关的损毁或遗失的风险由客户承担。
              <br />
              <br />
            </ListItemText>
          </ListItem>
        </List>
      </Collapse>
    </List>
    <ProductFooter/>
  </Wrapper>)
}

export default Question;
