import React from 'react';
import styled from 'styled-components';
import { media } from '../../styles/breakpoints';

const Wrapper = styled.div`
text-align: justify;
color: gray;
white-space: pre-line;
padding:1em

p{
    font-size:12pt;
    line-height:200%;
}
${media.tablet`
padding:20px
`};

${media.phone`
padding:20px
`}; 
`
const ImgContainer =styled.div`
padding:2% 0 0 0;
display: flex;
justify-content: space-evenly;
flex-wrap: wrap
`

const Title = styled.h3`
margin-top: 20px;
margin-bottom: 10px;
padding:2% 0 0 0;
font-size: 20pt;
`

const Awards = () => (
    <Wrapper>
        <ImgContainer >
            <img src="./images/design/award1_ger.jpg" alt=""/>
            <img src="./images/design/award2_ger.jpg" alt=""/>
            <img src="./images/design/award3_ger.jpg" alt=""/>
        </ImgContainer>

        <div className="row">
            <div className="col-xs-1 col-lg-1 col-md-1"></div>
            <div className="col-xs-10 col-lg-10 col-md-10">
            <div>
                <Title>凌美产品与荣获设计奖项</Title>
                        <br /><br />
                        <b>2013年</b><br /><br />
                        <p>LAMY 2000 系列：此系列的墨水笔获评为 Brand of the Century （世纪品牌）</p><br /><br />
                        <b>2012年</b><br /><br />
                        <p>C. Josef LAMY GmbH公司荣获Deutscher Werkbund Baden-Württemberg巴登符腾堡德国工艺联盟WERKBUND LABEL大奖 </p><br /><br />
                        <b>2011年</b><br /><br />
                        <p>LAMY 画笔系列（3+、水彩）：iF Design Award, Hannover, Design Center Stuttgart Award, Stuttgart (Germany)<br />
                            LAMY 画笔系列（3+）：reddot Design Award, Essen (Germany) <br /> </p><br /><br />
                        <b>2010年</b><br /><br />
                        <p>LAMY dialog 3焦点三 系列：iF Design Award, Hannover, reddot Design Award Zentrum Nordrhein Westfalen<br />
                            LAMY noto 系列：Designpreis der Bundesrepubik Deutschland (German Design Award) <br /></p><br /><br />
                        <b>2009年</b><br /><br />
                        <p>LAMY dialog 3焦点三 系列：Good Design Award<br />
                            LAMY noto 系列：reddot Design Award Zentrum Nordrhein Westfalen <br /></p><br /><br />
                        <b>2008年</b><br /><br />
                        <p>LAMY pur 系列：Designpreis der Bundesrepubik Deutschland (German Design Award) <br />
                            LAMY noto 系列：iF Design Award, China and Hannover, BIO 21, Good Design Award – the Chicago Athenaeum, Design Center Stuttgart Award, Stuttgart (Germany)<br /> </p><br /><br />
                        <b>2007年</b><br /><br />
                        <p>LAMY pur 系列：Good Design Award – the Chicago Athenaeum, KS/DS <br />
                            LAMY noto 系列：iF Design Award, China and Hannover, Designpreis der Bundesrepubik Deutschland (German Design Award)<br /> </p><br /><br />
                        <b>2006年</b><br /><br />
                        <p>LAMY dialog 2 焦点2 系列：Chicago Athenaeum Good Design Award, iF Design Award, Hannover, Frankfurt am Main, Germany / design Guild Award <br />
                            LAMY 2000 系列：Tokyo JIDA Design Museum Selection <br />
                            LAMY studio 演艺 系列：iF Design Award, Hannover, Design Center Stuttgart Award, Stuttgart (Germany)<br /> </p><br /><br />
                        <b>2005年</b><br /><br />
                        <p>LAMY pickup 流动 系列：reddot Design Award, Essen (Germany), Design Center Stuttgart Award, Stuttgart (Germany) <br />
                            LAMY studio 演艺 系列：Chicago Athenaeum Good Design Award, iF Design Award, Hannover <br />
                            LAMY pickup 流动 系列：reddot Design Award, Essen (Germany)<br /> </p><br /><br />
                        <b>2004年</b><br /><br />
                        <p>LAMY pico 口袋 系列：Designpreis der Bundesrepubik Deutschland (German Design Award) <br /></p><br /><br />
                        <b>2003年</b><br /><br />
                        <p>LAMY vista 自信 系列：iF Design Award, Hannover <br />
                            LAMY dialog 1焦点1 系列：Chicago Athenaeum Good Design Award<br /></p><br /><br />
                        <b>2002年</b><br /><br />
                        <p>LAMY scribble 艺术家 系列：iF Design Award, Hannover <br />
                            LAMY pico 口袋 系列：iF Design Award, Hannover, Design Centre Nordrhein-Westfalen, Design Plus Award, Frankfurt/Main<br /></p><br /><br />
                        <b>2001年</b><br /><br />
                        <p>LAMY scribble 艺术家 系列：Design Plus Award, Frankfurt/Main<br /></p><br /><br />
                        <b>2000年</b><br /><br />
                        <p>LAMY accent 优雅 系列：iF Design Award, Hannover <br />
                            LAMY tipo 指标 系列：Design Center Stuttgart Award, Stuttgart (Germany)<br /></p><br /><br />
                        <b>1999年</b><br /><br />
                        <p>LAMY accent 优雅 系列：Chicago Athenaeum Good Design Award <br /><br />
                            LAMY AL-star 恒星 系列：iF Design Award, Hannover <br />
                            LAMY lady 淑女 系列：Design Centre Nordrhein-Westfalen <br />
                            LAMY accent 优雅 系列：Design Centre Nordrhein-Westfalen, Design Center Stuttgart Germany<br /></p><br /><br />
                        <b>1998年</b><br /><br />
                        <p>LAMY AL-star 恒星 系列：iF Design Award, Hannover<br /></p><br /><br />
                        <b>1997年</b><br /><br />
                        <p>LAMY twin pen 两用笔：Design Plus Award, Frankfurt/Main<br /></p><br /><br />
                        <b>1996年</b><br /><br />
                        <p>LAMY abc 系列、black pen黑笔：Design Centre Nordrhein-Westfalen <br />
                            LAMY spirit 心灵 系列：Designpreis der Bundesrepubik Deutschland (German Design Award)<br /> </p><br /><br />
                        <b>1995年</b><br /><br />
                        <p>LAMY spirit 心灵 系列：Design Plus Award, Frankfurt/Main, German Award for communication design, iF Design Award, Hannover<br /></p><br /><br />
                        <b>1994年</b><br /><br />
                        <p>LAMY joy 喜悦 系列：Design Centre Nordrhein-Westfalen <br />
                            LAMY safari 狩猎 系列：iF Design Award, Hannover <br />
                            LAMY spirit 心灵 系列：Design Centre Nordrhein-Westfalen <br /></p><br /><br />
                        <b>1993年</b><br /><br />
                        <p>LAMY persona 个性 系列：Design Centre Nordrhein-Westfalen<br /></p><br /><br />
                        <b>1992年</b><br /><br />
                        <p>LAMY swift 速动 系列：iF Design Award, Hannover<br /></p><br /><br />
                        <b>1991年</b><br /><br />
                        <p>LAMY swift 速动 系列：Design Plus Award, Frankfurt/Main<br /></p><br /><br />
                        <b>1990年</b><br /><br />
                        <p>LAMY swift 速动 系列：Design Centre Nordrhein-Westfalen<br /></p><br /><br />
                        <b>1989</b><br /><br />
                        <p>LAMY abc 系列、hot 系列：iF Design Award, Hannover <br />
                            LAMY unic 独特 系列：iF Design Award, Hannover, Design Centre Nordrhein-Westfalen<br /></p><br /><br />
                        <b>1988年</b><br /><br />
                        <p>LAMY abc 系列：Design Centre Nordrhein-Westfalen, Design Plus Award, Frankfurt/Main <br />
                            LAMY cp 1系列：iF Design Award, Hannover<br /></p><br /><br />
                </div>
    
            </div>
            <div className="col-xs-1 col-lg-1 col-md-1"></div>
          </div>

        </Wrapper>
)

export default Awards