import React from 'react';

import { withAuth } from 'context/auth';
import { connect, bindActionCreator } from 'context/chat/chatContext';
import { updateIdentity } from 'context/chat/chatRedux';

import ChatBubble from '../ChatBubble';
import ChatWidget from '../ChatWidget';

class ChatContainer extends React.Component{
  state = {
    lastAuth: this.props.auth.isAuthed(),
  }

  componentDidMount() {
    const { auth, updateIdentity } = this.props;
    if (auth.isAuthed()) {
      const user = auth.getUser();
      updateIdentity({ id: user.uid, name: user.name || '用户' })
    }
  }

  componentDidUpdate(prevProp) {
    const { auth } = this.props;
    const { lastAuth } = this.state;
    if ( lastAuth !== auth.isAuthed() ) {
      this.setState({ lastAuth: auth.isAuthed() });
      if (auth.isAuthed()) {
        const user = auth.getUser();
        updateIdentity({ id: user.uid, name: user.name || '用户' })
      } else {
        // CAUTION: we use userId 0 to represent annoymous in database
        updateIdentity({ id: 0, name: '用户' })
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <ChatBubble />
        {/* <ChatWidget /> */}
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = dispatch => bindActionCreator({
  updateIdentity
}, dispatch)

export default withAuth(
  connect(undefined, mapDispatchToProps)(ChatContainer));
