import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import axios from 'axios';
import config from '../../config';
import { getIntQueryStringByKey } from '../../utils';

const ItemText = styled.span`
  margin-left: 5px;
  font-size: 10pt;

  a {
    color: #336699;
  }
`;
const Content = styled.div`
  text-align:justify
  padding:1em
`;

const Wrapper = styled.div`
text-align:justify;
p{
    font-size:14px;
    line-height:200%;
color: gray;
white-space: pre-line;

}
`
const Body = styled.div`
justify-content: space-around;
`

const Title = styled.h2`
padding:1em
font-size: 20pt;
color:gray;
margin-top:50px;
`
const Date = styled.h2`
padding:1em;
font-size: 18px;
color:gray;
`
const Subtitle = styled.div`
font-size: 16px;
color:gray;
`

const ContentBody = styled.div`
font-size: 14px;
color:gray;
`

class News extends React.Component {

    state = {
        list: [],
        detail:1
    }

    loadPageData(){

        const detail = this.state.detail;

        if(detail !== 1){
            const fetchUrl = `${config.serverEndPoint}/pressPage/${this.props.detail}`
            return axios.get(fetchUrl)
            .then(result => result.data)
            .then(result =>{
                this.setState({list : result})
            })
            .catch(err => {
                console.log(err);
            });
        }else{
            const fetchUrl = `${config.serverEndPoint}/pressPage`
            return axios.get(fetchUrl)
            .then(result => result.data)
            .then(result =>{

                result.sort((a, b) => {
                    return b.id - a.id
                })

                this.setState({list : result})
            })
            .catch(err => {
                console.log(err);
            });
        }
    }

    async componentDidMount() {
        this.loadPageData();
    }

    componentDidUpdate(prevProps) {
        
        const prevDetailId = getIntQueryStringByKey(this.state.detail);
        const currDetailId = getIntQueryStringByKey(this.props.detail);
    
          if (prevDetailId !==  currDetailId){
          this.setState({detail:currDetailId})
          this.loadPageData();
        }
      }

    render() {
        if (this.props.detail === 1) {
            return (
                <Wrapper className="row">
                    <div className="col-xs-1 col-lg-1 col-md-1"></div>
                    <div className="col-xs-10 col-lg-10 col-md-10">
                    <Title>新闻稿</Title>
                    {this.state.list.map((ele) => {
                        return ([
                            <div>
                                <Body className="row">
                                    <div className="col-lg-4 col-md-4">
                                    <Date>{ele.date1}</Date></div>
                                    <div className="row col-lg-8 col-md-8">
                                        <Content>
                                            <Subtitle>{ele.title}</Subtitle><br />
                                            <ContentBody>
                                                {ele.description1}
                                            </ContentBody>... >
                                            <ItemText>
                                                <Link to={`/about/press/${ele.id}`}>
                                                    {"更多"}
                                                </Link>
                                            </ItemText>
                                        </Content>
                                    </div>
                                </Body>
                                <hr />
                            </div>
                        ])
                    })}
                    </div>
                    <div className="col-xs-1 col-lg-1 col-md-1"></div>
                    
                </Wrapper>
            )
        } 

    }
}



export default News