import React from 'react';
import { withRouter } from 'react-router-dom';

import AddSocialBindingPageComponent from './AddSocialBindingPageComponent';

class AddAddressPageContainer extends React.Component {
  render() {
    const { match } = this.props;

    return (
      <React.Fragment>
        <AddSocialBindingPageComponent
          type={match.params.type}
          createAddress={this.createAddress} />
      </React.Fragment>
    )
  }
};

export default withRouter(AddAddressPageContainer);
