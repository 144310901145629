import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';

import config from '../../config';
import ProductPageComponent from './ProductPageComponent';
import { getIntQueryStringByKey, withScrollToTop } from '../../utils';
import { withCart } from '../../context';

class ProductPageContainer extends React.Component {
  state = {
    product: {
      quantity: 0,
      purchaseLimit: 0
    },
    accessories: [],
    relatedProducts: []
  }

  async componentDidMount() {
    this.loadPageData();
  }

  componentDidUpdate(prevProps) {
    const prevId = getIntQueryStringByKey(prevProps.location.search, 'id', 1);
    const currId = getIntQueryStringByKey(this.props.location.search, 'id', 1);
    const prevColorId = getIntQueryStringByKey(prevProps.location.search, 'colorId', 1);
    const currColorId = getIntQueryStringByKey(this.props.location.search, 'colorId', 1);

    if (prevId !== currId || prevColorId !== currColorId) {

      this.loadPageData();
    }
  }

  async loadPageData() {
    // Reset State
    this.setState({
      product: {
        quantity: 0,
        purchaseLimit: 0
      },
      accessories: [],
      relatedProducts: []
    });

    const result = await this.fetchPageData();

    const { product, accessories, relatedProducts } = result ? result : { product: {}, accessories: [], relatedProducts: [] };

    this.setState({ product, accessories, relatedProducts });
  }

  fetchPageData() {
    const { location } = this.props;
    const parsed = qs.parse(location.search) || {};

    const fetchUrl = `${config.serverEndPoint}/productPage?productId=${parsed.id}&colorId=${parsed.colorId}`;

    return axios.get(fetchUrl)
      .then(result => result.data)
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    const { product, accessories, relatedProducts } = this.state;
    const { history, location, cart } = this.props;
    const parsed = qs.parse(location.search) || {};

    return (
      <ProductPageComponent
        product={product}
        accessories={accessories}
        relatedProducts={relatedProducts}
        productId={parsed.id}
        colorId={parsed.colorId}
        history={history}
        addItemToCart={cart.addItemToCart} />
    )
  }
};

export default withScrollToTop(
  withRouter(
    withCart(
      ProductPageContainer
    )
  )
  , ['id', 'colorId']
);

