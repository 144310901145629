import React from 'react';
import { Carousel, ProductFooter } from '../Common';
import axios from 'axios';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { media, TabletAndDesktop, MobileOnly } from '../../styles/breakpoints';


import config from '../../config';

const Wrapper = styled.div`
color:gray;
background-color :#fff;
text-align:left;
img{
    max-width:100%
}
.row{
    margin:0px
}
`
const Title = styled.div`
display: flex;
padding-bottom: 30px;
flex-wrap: wrap;
`
const TitleHeader = styled.div`
padding: 10px;
margin-bottom: 3em;
`
const TitleContent = styled.div`
padding: 50px;

${media.tablet`
    margin: 0 0 10px 0;
    padding: 0 1em 1em 1em !important;
  `};

  ${media.phone`
  margin: 0 0 10px 0;
  padding: 0 1em 1em 1em !important;
  `};
`
const ModuleHeader = styled.h3`
padding: 20px;
`

const Module = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: space-around;
`

const Article = styled.div`
margin-bottom: 3em;
padding: 20px;
`
const ArticleHeader =styled.h3`
margin: 2em 0 1em 0;
`

const ArticleContent =styled.div`
margin: 0 0 10px;
`


class CommunityComponent extends React.Component{
    state={
        title:null,
        modules:[],
        article:[],
    }

    async componentDidMount(){
        const data = await this.getPageData()
        data.forEach(ele=>{
            if(ele.type==='TITLE'){
                ele.image= `${config.imagePrefix}/community/${ele.image}`
                this.setState({title : ele})
            }else if (ele.type ==='MODULE'){
                ele.image= `${config.imagePrefix}/community/${ele.image}`
                this.setState(state=>{
                    let tmp = state.modules;
                    tmp.push(ele);
                    return({modules:tmp})
                })
            }else if (ele.type ==='ARTICLE'){
                ele.image= `${config.imagePrefix}/community/${ele.image}`
                this.setState(state=>{
                    let tmp = state.article;
                    tmp.push(ele);
                    return({article:tmp})
                })
            }
        })
        this.setState({data:data})
    }

    getPageData = async () => {
        const fetchUrl = `${config.serverEndPoint}/communityPage`
        try {
            const result = await axios.get(fetchUrl);

            return result.data;
        }
        catch (err) {
            console.log(err);
        }

    }

    render(){
        const {title, modules,article} = this.state;
        if(title&&modules&&article){
            return(
                <Wrapper>
                    <Carousel items={this.state.article} />
                     <div className="row">
                        <div className="col-xs-1 col-lg-1 col-md-1 col-lg-1"></div>
                        <div className="col-xs-10 col-lg-10 col-md-10 col-lg-10">
                            <Title className="row">
                                <div className="col-md-6 ">
                                    <TitleHeader><h2>{title.desc1}</h2>
                                    <img src={title.image} alt=""/></TitleHeader>
                                </div>
                                <TitleContent className="col-md-6 ">{title.desc2}</TitleContent>
                            </Title>
                            <hr/>
                            <ModuleHeader>热门板块</ModuleHeader>
                            <Module>
                                {modules.map(ele=>{
                                    return([<Link to={ele.url}><img src={ele.image} alt=""/></Link>])
                                })}
                            </Module>
                            <hr/>
                            {article.map(ele=>{
                                    return([<div>
                                        <Article>
                                        <ArticleHeader>{ele.desc1}</ArticleHeader>
                                        <ArticleContent>{ele.desc2}</ArticleContent>
                                        <img src={ele.image} alt=""/></Article><hr/>
                                        </div>])
                                })}
                        </div>
                        <div className="col-xs-1 col-lg-1 col-md-1 col-lg-1"></div>
                    </div>
                </Wrapper> 
            )
        }
        return(
            <Wrapper>
            </Wrapper>
        )
    }
}

export default CommunityComponent