import React from 'react';
import { compose, equals } from 'lodash/fp';

import SimpleSubscription from 'utils/SimpleSubscription';
import { middleware as sagaMiddleWare } from 'utils/pseudoSaga';

import socketInstance from './socketInstance';
import reducer, {
  initState,
  saga,
  fetchSelfSuccess,
  receiveMessage,
  fetchConversationDetailSuccess,
  updateServerStatus,
} from './chatRedux';

const ChatContext = React.createContext({
  getState: () => {},
  dispatch: () => null,
  subscribe: () => () => null,
});

class ChatProvider extends React.Component {
  listeners = {};
  reducer = x => x;
  subscription = new SimpleSubscription();
  middleware = [sagaMiddleWare];

  constructor(props) {
    super();
    this.socket = socketInstance.init();
    this.store = props.initState || initState;
    this.reducer = props.reducer || reducer;
    sagaMiddleWare.run(saga);
  }

  componentDidMount() {
    this.socket.on('sync:session', ({ user, conversation }) => {
      socketInstance.saveSession(user.id);
      this.dispatch(fetchSelfSuccess(user));
      if (conversation) this.dispatch(fetchConversationDetailSuccess(conversation));
      // EXTEND: if user side need admin name (sync session should also provide admin information)
    });
    this.socket.on('client:error', console.log);
    this.socket.on('on:message', ({id, message}) => {
      this.dispatch(receiveMessage(id, message));
    });
    this.socket.on('on:leave:chat:admin', ({ conversation, admin }) => {
      this.dispatch(fetchConversationDetailSuccess(conversation));
      // this.dispatch(fetchAdminsSuccess([admin]));
    });
    this.socket.on('on:join:chat', ({ conversation, admin }) => {
      this.dispatch(fetchConversationDetailSuccess(conversation));
    });
    this.socket.on('on:chat:end', (conversation) => {
      this.dispatch(fetchConversationDetailSuccess(conversation));
    });
    // this.socket.on('on:admin', (admin) => { })
    this.socket.on('on:service:status', ({ status }) => {
      this.dispatch(updateServerStatus(status));
    });
    this.socket.on('on:conversation', (conversation) => {
      this.dispatch(fetchConversationDetailSuccess(conversation));
    })
    this.socket.open();
  }

  getState = () => {
    return this.store;
  }

  finalDispatch = (action) => {
    // TODO: batch update (queue)
    this.store = this.reducer(this.getState(), action);
    this.subscription.notify();
  }

  dispatch = compose(...this.middleware.map(m => m({
    getState: this.getState,
    dispatch: (...args) => this.dispatch(...args), // assign pointer
  })))(this.finalDispatch)

  subscribe = (cb) => this.subscription.subscribe(cb);

  render() {
    const { children } = this.props;
    return (
      <ChatContext.Provider value={{
        getState: this.getState,
        dispatch: this.dispatch,
        subscribe: this.subscribe,
      }}>
        {children}
      </ChatContext.Provider>
    )
  }
}

const dummyReturn = () => ({});
const connect = (mapStateToProps = dummyReturn, mapDispatchToProps = dummyReturn) => (WrappedComponent) => {
  class C extends React.Component {
    static contextType = ChatContext;
    unsubscribe = () => null;
    state = mapStateToProps(this.context.getState(), this.props);
    dispatcher = mapDispatchToProps(this.context.dispatch);
    lastState= this.state;
    
    componentDidMount() {
      this.unsubscribe = this.context.subscribe(() => {
        const newState = mapStateToProps(this.context.getState(), this.props);
        if (equals(this.lastState, newState)) return;
        this.setState(newState);
      });
    }

    componentWillUnmount() {
      this.unsubscribe();
    }

    render() {
      this.lastState = this.state;
      return <WrappedComponent
        {...this.state}
        {...this.dispatcher}
        {...this.props}
      />;
    }
  }
  C.displayName = `Connected(${WrappedComponent.displayName || WrappedComponent.name})`
  return C;
}

export const bindActionCreator = (mapping, dispatch) =>
  Object.entries(mapping)
    .reduce((memo, [k, v]) => ({
      ...memo,
      [k]: (...args) => dispatch(v(...args)),
    }), {})



export {
  ChatContext as default,
  ChatProvider as Provider,
  connect,
};
