import React from 'react';
import styled from 'styled-components';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

import { SelectProductPage, CheckoutPage } from './components';
import { PageContainer } from '../Common';
import { withPopup } from '../../context';
import { NAME_REGEX, PHONE_REGEX } from '../../constants';

const Wrapper = styled.div`
  background-color: #fff;
  text-align: center;
`;

const validateAddress = (customAddress) => {
  if (!NAME_REGEX.test(customAddress.name)) {
    return '姓名必需是中/英字, 不能有数字或其他符号';
  }
  if (!PHONE_REGEX.test(customAddress.phone)) {
    return '电话号码必需為11個字';
  }
  if (!customAddress.address) {
    return '地址不能为空';
  }

  return;
};

const validateCheckout = (
  shoppingCartData, selectedProducts,
  userData, selectedAddress, isCustomAddressConfirmed, 
) => {
  // Validate products
  if (shoppingCartData.length === 0 || selectedProducts.length === 0) {
    return false;
  }

  // Validate address
  if ( !isCustomAddressConfirmed) {
    if (!userData
      || !userData.addresses
      || userData.addresses.length === 0
      || selectedAddress === 0
    ) {
      return false;
    }
  }

  return true;
}

const CartComponent = ({
  shoppingCartData, onQuantityChange, userData,
  onCheckout, addAddress, onRemoveProduct, togglePopup, accessories, transportFee
}) => {
  const [currStep, setCurrStep] = React.useState(0);
  const [selectedProducts, setSelectedProducts] = React.useState([]);
  const [selectedPayment, setSelectedPayment] = React.useState(0);
  
  // Address related state
  const [selectedAddress, setSelectedAddress] = React.useState(0);
  const [customAddress, setCustomAddress] = React.useState({
    name: '',
    phone: '',
    address: '',
    isConfirmed: false
  });
  const [isCustomAddressConfirmed, setIsCustomAddressConfirmed] = React.useState(false);
  const [remark, onRemarkChange] = React.useState('');

  if (selectedAddress === 0 && userData.defaultAddressId) {
    setSelectedAddress(userData.defaultAddressId);
  }

  const onNextStep = React.useCallback(() => {
    if (currStep === 1) {
      if (!validateCheckout(shoppingCartData, selectedProducts, userData , selectedAddress, isCustomAddressConfirmed)) {
        return;
      }
      
      let deliveryAddress;
      if (isCustomAddressConfirmed) {
        deliveryAddress = customAddress;
      } else {
        deliveryAddress = userData.addresses.find(({id}) => id === selectedAddress);
      }

      onCheckout(
        deliveryAddress,
        shoppingCartData.filter((item, idx) => selectedProducts.includes(idx)),
        selectedPayment,
        remark
      );
    } else {
      setCurrStep(currStep + 1);
      window.scrollTo(0, 0);
    }
  }, [
    currStep, setCurrStep, onCheckout, shoppingCartData, selectedProducts,
    userData, selectedAddress, isCustomAddressConfirmed, selectedPayment, remark
  ]);

  const onPrevStep = React.useCallback(() => {
    setCurrStep(currStep - 1);
    window.scrollTo(0, 0);
  }, [currStep, setCurrStep]);

  const onProductSelect = React.useCallback((idx, selected) => {
    if (selected) {
      setSelectedProducts([...selectedProducts, idx]);
    } else {
      setSelectedProducts(selectedProducts.filter((itemIdx) => itemIdx !== idx))
    }
  }, [selectedProducts, setSelectedProducts]);

  const onCustomAddressChange = React.useCallback((changedValue) => {
    setCustomAddress({
      ...customAddress,
      ...changedValue
    })
  }, [customAddress, setCustomAddress]);

  const onConfirmCustomAddress = React.useCallback(() => {
    const error = validateAddress(customAddress);

    if (error) {
      togglePopup({
        type: 'info',
        message: error
      });
      return;
    }

    setIsCustomAddressConfirmed(true);
  }, [customAddress, setIsCustomAddressConfirmed]);

  const onConfirmAndAddCustomAddress = React.useCallback(async () => {
    const error = validateAddress(customAddress);

    if (error) {
      togglePopup({
        type: 'info',
        message: error
      });
      return;
    }

    await addAddress(customAddress);

    setIsCustomAddressConfirmed(true);
  }, [customAddress, addAddress, setIsCustomAddressConfirmed]);

  const onCancelCustomAddress = React.useCallback(() => {
    setIsCustomAddressConfirmed(false);
  }, [setIsCustomAddressConfirmed]);

  return (
    <PageContainer title="购物车">
      <Wrapper>
        <CSSTransitionGroup
          transitionName="product-animation"
          transitionEnterTimeout={300}
          transitionLeaveTimeout={300}>
          { currStep === 0 &&
            <SelectProductPage
              onQuantityChange={onQuantityChange}
              onProductSelect={onProductSelect}
              selectedProducts={selectedProducts}
              shoppingCartData={shoppingCartData}
              onNextStep={onNextStep}
              onRemoveProduct={onRemoveProduct}
              togglePopup={togglePopup}
              accessories={accessories} />
          }
          { currStep === 1 &&
            <CheckoutPage
              customAddress={customAddress}
              onCustomAddressChange={onCustomAddressChange}
              isCustomAddressConfirmed={isCustomAddressConfirmed}
              onConfirmCustomAddress={onConfirmCustomAddress}
              onCancelCustomAddress={onCancelCustomAddress}
              onConfirmAndAddCustomAddress={onConfirmAndAddCustomAddress}
              selectedPayment={selectedPayment}
              setSelectedPayment={setSelectedPayment}
              userData={userData}
              selectedAddress={selectedAddress}
              setSelectedAddress={setSelectedAddress}
              selectedProducts={selectedProducts}
              shoppingCartData={shoppingCartData}
              onNextStep={onNextStep}
              onPrevStep={onPrevStep}
              togglePopup={togglePopup}
              onRemarkChange={onRemarkChange}
              transportFee={transportFee} />
          }
        </CSSTransitionGroup>
      </Wrapper>
    </PageContainer>
  )
}

export default React.memo(
  withPopup(
    CartComponent
  )
);