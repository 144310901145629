import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import menuItems from './menuItems';
import { withAuth } from '../../../context';

const Wrapper = styled.ul`
  text-align: left;
  font-size: 13px;
`;

const MenuItemWrapper = styled.div`

`;

const MenuItem = styled.li`
  border-top: 1px solid #b2b2b2;
  border-bottom: 1px solid #666;
  padding: 6px 15px;

  background-color: ${props => props.expanded ? '#404040' : '' };
  background-image: ${props => props.expanded ? '' : 'linear-gradient(to bottom, #888888 0%, #A3A3A3 100%)'};
  color: ${props => props.expanded ? '#fff' : '#000'};
  padding-left: ${props => `${props.level * 15}px`};
`;

const ClosedIcon = styled(KeyboardArrowRightIcon)`
  margin: 0 20px;
`;

const ExpandedIcon = styled(KeyboardArrowDownIcon)`
  margin: 0 20px;
`;

const renderMenuItem = (menuItem, level, history, onRouteChange, auth) => {
  const [expanded, setExpanded] = React.useState(false);

  const onMenuItemClick = React.useCallback(() => {
    if (menuItem.url) {
      history.push(menuItem.url);

      if (onRouteChange) {
        onRouteChange();
      }
    } else {
      setExpanded(!expanded);
    }
  }, [history, menuItem, expanded, onRouteChange]);

  const shdDisplay = menuItem.requireLogin ? auth.isAuthed() : true; 

  return shdDisplay && (
    <MenuItemWrapper key={menuItem.name}>
      <MenuItem level={level} onClick={onMenuItemClick} expanded={expanded}>
        { expanded ? <ExpandedIcon /> : <ClosedIcon /> }
        { menuItem.name }
      </MenuItem>
      { menuItem.subItems &&
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          { menuItem.subItems.map((subItem) => renderMenuItem(subItem, level + 1, history, onRouteChange, auth)) }
        </Collapse>
      }
    </MenuItemWrapper>
  );
};

const MobileMenu = ({ history, onRouteChange, auth }) => {
  return (
    <Wrapper>
      { menuItems.map(menuItem => renderMenuItem(menuItem, 1, history, onRouteChange, auth)) }
    </Wrapper>
  )
};

export default React.memo(
  withRouter(
    withAuth(MobileMenu)
  )
);