import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import config from '../../../../config';
import { media, TabletAndDesktop, MobileOnly } from '../../../../styles/breakpoints';
import { formatPrice } from '../../../../utils';
import Checkbox from '../Checkbox';
import QuantityInput from '../QuantityInput';
import { withAuth } from '../../../../context';

const Wrapper = styled.div`
  background-color: #efefef;
  margin-bottom: 10px;

  /* ${media.phone`
    background-color: #fff;
  `}; */
`;

const HeaderRow = styled.div`
  display: flex;
  padding: 15px 15px 15px 80px;
  align-items: flex-start;
  border-bottom: solid 1px #e2e2e2;
  justify-content: space-between;

  ${media.tablet`
    padding: 10px 10px 10px 60px;
  `};

  ${media.phone`
    padding: 15px;
  `};
`;

const InfoSection = styled.div`
  img {
    display: block;
    padding-bottom: 5px;
  }

  ${media.phone`
    text-align: left;
    font-size: 12px;
    flex: 1 0;
  `};
`;

const ContentRow = styled.div`
  display: flex;
  padding: 50px 35px 50px 0;
  align-items: center;

  ${media.tablet`
    padding: 50px 10px 50px 0;
  `};

  ${media.phone`
    padding: 5px 20px;
  `};
`;

const ContentSection = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

const EmptyCheckBox = styled.div`
  width: 80px;

  ${media.tablet`
    width: 60px;
  `};
`;

const ProductImage = styled.div`
  flex: 1;
  margin: 0 10px;

  img {
    width: 100%;
  }

  ${media.phone`
    flex: 0 0 100px;
    margin: 0 20px 0 0;
    border: solid 1px #ccc;
    padding: 40px 0;
    border-radius: 7px;
  `};
`;

const Price = styled.div`
  flex: 1;
  margin: 0 10px;

  ${media.phone`
    flex: none;
  `};
`;

const QuantityInputWrapper = styled.div`
  position: relative;
  flex: 1;

  ${media.phone`
    margin-top: 10px;
    font-size: 14px;
  `};
`;

const QuantityAmount = styled.div`
  position: absolute;
  top: -35px;
  width: 100%;

  ${media.phone`
    position: relative;
    top: 0;
  `};
`;

const DeleteBtn = styled.button`
  background-color: unset;
  width: 18px;

  img {
    width: 100%;
    vertical-align: unset;
  }
`;

const PriceRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 15px;
  border-top: solid 1px #e2e2e2;
`;

const CheckoutQuantityWrapper = styled.div`
  margin-top: 10px;
`;

const CartItem = ({ auth, itemIdx, item, onQuantityChange, onSelect, selected, onRemoveProduct }) => {
  const { id, colorid, productid, price, vipPrice, quantity, productImage, colorName, seriesImage, productName, stockUnit } = item;

  const onCheckboxClick = React.useCallback((e) => {
    onSelect(itemIdx, e.target.checked);
  }, [itemIdx, onSelect]);

  const onRemoveProductClick = React.useCallback((e) => {
    onRemoveProduct(productid, colorid);
  }, [onRemoveProduct, productid, colorid]);

  const productPrice = auth.isVip ? vipPrice : price;

  return (
    <Wrapper>
      <TabletAndDesktop>
        <HeaderRow>
          <InfoSection>
            { seriesImage && <img src={`${config.imagePrefix}/productseries/${seriesImage}`} alt="" /> }
            { colorName } { productName }
          </InfoSection>
          { onRemoveProduct && <DeleteBtn onClick={onRemoveProductClick}>
            <img src="/images/cart/cancel.jpg" alt="" />
          </DeleteBtn> }
        </HeaderRow>

        <ContentRow>
          { onSelect ?
            <Checkbox checked={selected} onChange={onCheckboxClick} />
            : 
            <EmptyCheckBox />
          }
          <ContentSection>
            <ProductImage>
              { onSelect ? (
                <Link to={`/product?id=${productid}&colorId=${colorid}`}>
                  { productImage && <img src={`${config.imagePrefix}/productbanner/${productImage}`} alt="" /> }
                </Link>
              ) : 
                productImage && <img src={`${config.imagePrefix}/productbanner/${productImage}`} alt="" />
              }
            </ProductImage>
            <Price>{`￥${formatPrice(productPrice)}`}</Price>
            <QuantityInputWrapper>
              { onQuantityChange &&
                <QuantityAmount> { `库存: ${stockUnit}` } </QuantityAmount>
              }
              <QuantityInput
                min="1"
                max="99"
                maxlength="2"
                type="tel"
                id={id}
                value={quantity}
                onChange={onQuantityChange}
                disabled={!onQuantityChange} />
            </QuantityInputWrapper>
            <Price>{`￥${formatPrice(productPrice * quantity)}`}</Price>
          </ContentSection>
        </ContentRow>
      </TabletAndDesktop>
      <MobileOnly>
        <HeaderRow>
          { onSelect ?
            <Checkbox checked={selected} onChange={onCheckboxClick} />
            : 
            <EmptyCheckBox />
          }
          { onRemoveProduct && <DeleteBtn onClick={onRemoveProductClick}>
            <img src="./images/cart/cancel.jpg" alt="" />
          </DeleteBtn> }
        </HeaderRow>

        <ContentRow>
          <ProductImage>
            { productImage && <img src={`${config.imagePrefix}/productbanner/${productImage}`} alt="" /> }
          </ProductImage>
          <InfoSection>
            { seriesImage && <img src={`${config.imagePrefix}/productseries/${seriesImage}`} alt="" /> }
            { colorName } { productName }
            { onQuantityChange ? (
              <QuantityInputWrapper>
                <QuantityAmount> { `库存: ${stockUnit}` } </QuantityAmount>
                <QuantityInput
                  min="1"
                  max="99"
                  maxlength="2"
                  type="tel"
                  id={id}
                  value={quantity}
                  onChange={onQuantityChange}
                  disabled={!onQuantityChange} />
              </QuantityInputWrapper>
            ) : (
              <CheckoutQuantityWrapper>
                <div>{`單價: ￥${formatPrice(productPrice)}`}</div>
                <div>{`数量: ${quantity}`}</div>
              </CheckoutQuantityWrapper>
            )}
          </InfoSection>
        </ContentRow>
        <PriceRow>
          <Price>{`小计 ￥${formatPrice(productPrice * quantity)}`}</Price>
        </PriceRow>
      </MobileOnly>
    </Wrapper>
  )
}

export default React.memo(
  withAuth(CartItem)
);